import { getAuthToken } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { SideNavigationBar } from "../../molecules/SideNavigationBar";

export function AppContainer() {
  const authToken = getAuthToken();

  useEffect(() => {
    document.title = authToken
      ? "Broker Portal | Say Technologies"
      : "Sign In | Say Technologies";
  }, [authToken]);

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        m: authToken ? "none" : "0 auto",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      {authToken && <SideNavigationBar />}
      <Outlet />
    </Stack>
  );
}
