import { ProxyType } from "src/store/proxy/types";

export function hasMeetingInfo(type: ProxyType | undefined) {
  if (
    type === "summary_prospectus" ||
    type === "19_a_notice" ||
    type === "annual_report" ||
    type === "semi_annual_report" ||
    type === "information_statement"
  ) {
    return false;
  }
  return true;
}
