import { ColumnType, Row } from "@asayinc/component-library";

import {
  CORPORATE_ACTION_LABEL,
  ELECTION_STATUS_LABEL,
} from "src/constants/labels";
import { ListCorporateActionCommunication } from "src/store/corporateAction/types";
import { ListCommunication } from "src/store/types";

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
};

type ColumnData = {
  type: ColumnType;
  ticker: ColumnType;
  issuer: ColumnType;
  election: ColumnType;
  approvedAt: ColumnType;
};

export function getColumns() {
  return ["type", "ticker", "issuer", "approvedAt", "election"];
}

export function getColumnData({
  getSort,
  onOrderingChange,
}: ColumnDataHelpers): ColumnData {
  return {
    type: {
      sort: getSort("type"),
      sortFn: (data: unknown) => onOrderingChange(data, "type"),
      style: "text",
      title: "Type",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
    },
    ticker: {
      style: "text",
      title: "Ticker",
    },
    issuer: {
      style: "text",
      title: "Issuer",
    },
    approvedAt: {
      sort: getSort("corporate_action_event__approved_at"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "corporate_action_event__approved_at"),
      style: "text",
      title: "Approved at",
    },
    election: {
      style: "text",
      title: "Election",
      width: "120px",
    },
  };
}

export function getRowData(
  communications: ListCorporateActionCommunication[],
  onRowClick: (communication: ListCommunication) => void,
  selectedCommunication: ListCommunication | null | undefined
): Row[] {
  return communications.map((communication, idx) => {
    const handleRowClick = (data: unknown) => {
      const { communication } = data as {
        communication: ListCorporateActionCommunication;
        idx: string;
      };
      onRowClick(communication);
    };

    const {
      id,
      corporateActionEvent: { approvedAt, type },
      security: { issuerName, ticker },
      electionStatus,
    } = communication;
    const rowData = {
      type: {
        content: CORPORATE_ACTION_LABEL[type] || "-",
        clickHandler: handleRowClick,
      },
      ticker: {
        content: ticker || "-",
        clickHandler: handleRowClick,
      },
      issuer: {
        content: issuerName || "-",
        clickHandler: handleRowClick,
      },
      approvedAt: {
        content: approvedAt || "-",
        clickHandler: handleRowClick,
      },
      election: {
        content: ELECTION_STATUS_LABEL[electionStatus] || "-",
        clickHandler: handleRowClick,
      },
    };

    return {
      id: id,
      data: { communication, idx },
      rowData,
      highlighted: selectedCommunication?.id === communication.id,
    };
  });
}
