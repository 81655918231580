import "./NotificationAccordion.css";
import { Accordion, Box, SxProps } from "@mui/material";
import { MutableRefObject, useEffect, useRef, useState } from "react";

import {
  Communication,
  CommunicationNotification,
  Notification,
} from "src/store/types";
import { NotificationAccordionSummary } from "./NotificationAccordionSummary";
import { NotificationAccordionDetails } from "./NotificationAccordionDetails";

interface INotificationAccordionProps {
  communication: Communication;
  isExpanded?: boolean;
  notification: CommunicationNotification;
  onResend: (notification: Notification) => void;
  resendCountMap: { [notificationId: string]: number };
  // ScrollContainerRef is provided if the user wants to
  // automatically scroll to the expanded accordion
  // Currently it's used for when a user clicks on a row
  // in the notification table
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
  sx?: SxProps;
  targetNotificationId?: string;
}

export function NotificationAccordion({
  communication,
  isExpanded = false,
  notification,
  onResend,
  resendCountMap,
  scrollContainerRef,
  sx,
  targetNotificationId,
}: INotificationAccordionProps) {
  const accordionRef = useRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(isExpanded);

  useEffect(() => {
    const scrollContainer = scrollContainerRef?.current;
    const accordion = accordionRef?.current;

    // If targetNotificationId matches the current notification
    // we will automatically scroll to the target notification
    if (
      targetNotificationId === notification.id &&
      scrollContainer &&
      accordion
    ) {
      scrollContainer?.scrollTo({
        top: accordion.offsetTop - 200,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line
  }, [targetNotificationId]);

  const handleToggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box sx={sx} ref={accordionRef}>
      <Accordion
        style={{
          borderRadius: "8px",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
        }}
        data-testid={`notification-accordion-${notification.id}`}
        expanded={isOpen}
        onChange={handleToggleAccordion}
      >
        <NotificationAccordionSummary
          communication={communication}
          notification={notification}
          resendCountMap={resendCountMap}
          onResend={onResend}
        />
        <NotificationAccordionDetails
          communication={communication}
          isOpen={isOpen}
          notification={notification}
        />
      </Accordion>
    </Box>
  );
}
