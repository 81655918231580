import { ProposalType } from "src/store/proxy/types";

export const PROPOSAL_LABEL: {
  [key in ProposalType]: string;
} = {
  adjournment: "Adjournment",
  board_of_directors_nomination: "Board of Directors Nomination",
  executive_compensation: "Executive Compensation",
  management_proposal: "Management Proposal",
  other: "Other",
  plan_of_merger: "Plan of Merger",
  ratification_of_auditors: "Ratification of Auditors",
  say_on_pay: "Say on Pay",
  shareholder_proposal: "Shareholder Proposal",
};
