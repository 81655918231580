import { IDate } from "@asayinc/component-library";
import { Adjust, CheckCircle, Warning } from "@mui/icons-material";

import { NOTIFICATION_EVENT_LABEL } from "src/constants/labels";
import {
  NotificationChannelType,
  NotificationEvent,
  NotificationEventType,
} from "src/store/types";
import { formatDate } from "./formatDate";

export function getNotificationTimelineEvents(
  channel: NotificationChannelType,
  events: NotificationEvent[] | undefined
): IDate[] {
  if (!events || events.length === 0) {
    return [
      {
        title: channel === "email" ? "Sent" : "Created",
        date: "-",
        icon: <Adjust color="disabled" />,
      },
    ];
  }

  // Generate timeline events from notification events
  const timelineEvents = events.map((event) => {
    const { eventDatetime, type } = event;
    const title = NOTIFICATION_EVENT_LABEL[type];
    const icon = getIcon(type);
    return {
      date: eventDatetime ? formatDate(eventDatetime, true, false) : "-",
      icon,
      title,
    };
  });

  // Use latest event to generate the next event title and create
  // placeholder timeline event
  const lastType = events[events.length - 1].type;
  const nextTitle = getNextEventTitle(lastType);
  if (nextTitle) {
    timelineEvents.push({
      title: nextTitle,
      date: "-",
      icon: <Adjust color="disabled" />,
    });
  }

  return timelineEvents;
}

function getIcon(type: NotificationEventType) {
  if (
    type === "return_to_sender" ||
    type === "hard_bounce" ||
    type === "soft_bounce" ||
    type === "spam_complaint" ||
    type === "unsubscribe" ||
    type === "address_change" ||
    type === "dns_error" ||
    type === "spam_notification" ||
    type === "unknown" ||
    type === "virus_notification" ||
    type === "challenge_verification" ||
    type === "bad_email_address" ||
    type === "manually_deactivated" ||
    type === "unconfirmed" ||
    type === "blocked" ||
    type === "smtp_api_error" ||
    type === "inbound_error" ||
    type === "dmarc_policy" ||
    type === "template_rendering_failed" ||
    type === "deleted" ||
    type === "no_webhook_configuration" ||
    type === "invalid_response" ||
    type === "request_exception"
  ) {
    return <Warning color="error" />;
  }
  return <CheckCircle color="success" />;
}

function getNextEventTitle(type: NotificationEventType): string | null {
  switch (type) {
    // Postal Mail cases
    case "created":
      return "Mailed";
    case "mailed":
      return "In transit";
    case "in_transit":
      return "In local area";
    case "in_local_area":
      return "Processed for delivery";
    // Email cases
    // case "sent":
    //   return "Delivered";
    // case "queued":
    //   return "Sent";
    // case "deferred":
    //   return "Sent";
    case "delivered":
      return "Opened";
    case "opened":
      return "Clicked";
  }
  return null;
}
