import { ButtonV2, IconButton, Text } from "@asayinc/component-library";
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";

import { useResendCorporateActionNotificationMutation } from "src/store/corporateAction";
import { useResendProxyEventNotificationMutation } from "src/store/proxy";
import { Communication, Notification } from "src/store/types";
import { getOrdinalNumber } from "src/utils/getOrdinalNumber";
import { isErrorWithMessage } from "src/utils/isErrorWithMessage";

interface INotificationResendDialogProps {
  communication: Communication;
  isOpen: boolean;
  nextCount: number;
  notificationId: string;
  onClose: () => void;
  onResend: (notification: Notification) => void;
}

export function NotificationResendDialog({
  communication,
  isOpen,
  nextCount,
  notificationId,
  onClose,
  onResend,
}: INotificationResendDialogProps) {
  const [
    handleProxyNotificationResend,
    { error: proxyError, isError: isProxyError, reset: resetProxyError },
  ] = useResendProxyEventNotificationMutation();
  const [
    handleCorporateActionNotificationResend,
    {
      error: corporateActionError,
      isError: isCorporateActionError,
      reset: resetCorporateActionError,
    },
  ] = useResendCorporateActionNotificationMutation();

  useEffect(() => {
    if (isOpen) {
      resetProxyError();
      resetCorporateActionError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSend = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const { proxyEvent, corporateActionEvent } = communication;
    if (proxyEvent) {
      await handleProxyNotificationResend({
        proxyId: proxyEvent.id,
        notificationId,
      })
        .unwrap()
        .then((notification) => {
          onResend(notification);
          onClose();
        });
    } else if (corporateActionEvent) {
      await handleCorporateActionNotificationResend({
        corporateActionId: corporateActionEvent.id,
        notificationId,
      })
        .unwrap()
        .then((notification) => {
          onResend(notification);
          onClose();
        });
    }
  };

  const isError = isProxyError || isCorporateActionError;
  const errorMessage = isErrorWithMessage(proxyError)
    ? proxyError.data.detail
    : isErrorWithMessage(corporateActionError)
    ? corporateActionError.data.detail
    : "";

  return (
    <Dialog
      disableScrollLock
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "340px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 8 }}>
        <Text variant="subtitle1" sx={{ mb: 4 }}>
          {isError ? "Error" : "Re-send initial email"}
        </Text>
        <Text variant="body1">
          {isError
            ? errorMessage
            : `This is the ${getOrdinalNumber(nextCount)} re-send to ${
                communication.customer?.email || ""
              }`}
        </Text>
        {!isProxyError && !isCorporateActionError && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: 8,
            }}
          >
            <Box sx={{ display: "grid", width: "100%" }}>
              <ButtonV2 onClick={handleSend} variant="primary">
                Send now
              </ButtonV2>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
