export type CommunicationDetailsTabs = "meeting" | "notification" | "customer";
export const PROXY_COMMUNICATION_DETAILS_TABS = [
  {
    label: "Notification history",
    value: "notification",
  },
  {
    label: "This customer",
    value: "customer",
  },
  {
    label: "Meeting info",
    value: "meeting",
  },
];

export const CAE_COMMUNICATION_DETAILS_TABS = [
  {
    label: "Notification history",
    value: "notification",
  },
  {
    label: "This customer",
    value: "customer",
  },
  {
    label: "Offer info",
    value: "meeting",
  },
];

export type MailingTabs = "proxy" | "corporate-action";
export const MAILING_TABS = [
  {
    label: "Proxy",
    value: "proxy",
  },
  {
    label: "Voluntary corporate actions",
    value: "corporate-action",
  },
];

export type MailingDetailTabs =
  | "customers"
  | "notifications"
  | "insights"
  | "meeting-info";
export const PROXY_MAILING_DETAIL_TABS = [
  {
    label: "Meeting Info",
    value: "meeting-info",
  },
  {
    label: "Customers",
    value: "customers",
  },
  {
    label: "Notifications",
    value: "notifications",
  },
  {
    label: "Insights",
    value: "insights",
  },
];

export const CORPORATE_ACTION_MAILING_DETAIL_TABS = [
  {
    label: "Offer Info",
    value: "offer-info",
  },
  {
    label: "Customers",
    value: "customers",
  },
  {
    label: "Notifications",
    value: "notifications",
  },
  {
    label: "Insights",
    value: "insights",
  },
];

export type CustomerDetailsTabs = "mailings" | "notifications";
export const CUSTOMER_DETAILS_TABS = [
  {
    label: "Notifications",
    value: "notifications",
  },
  {
    label: "Mailings",
    value: "mailings",
  },
];

export type IssuerDetailsTabs = "mailings" | "notifications";
export const ISSUER_DETAILS_TABS = [
  {
    label: "Proxy",
    value: "proxy",
  },
  {
    label: "Voluntary corporate actions",
    value: "corporate-action",
  },
];
