import { Drawer as MUIDrawer } from "@mui/material";

interface IDrawerProps {
  anchor: "left" | "right" | "top" | "bottom";
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  testId: string;
  width?: string;
}

export function Drawer({
  anchor,
  children,
  onClose,
  open,
  testId,
  width = "600px",
}: IDrawerProps) {
  return (
    <MUIDrawer
      anchor={anchor}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: "transparent" },
        },
      }}
      variant="temporary"
      open={open}
      onClose={onClose}
      data-testid={testId}
      PaperProps={{
        elevation: 5,
        sx: {
          backgroundColor: "#F0F0F0",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
          maxWidth: width,
          minWidth: width,
        },
      }}
    >
      {children}
    </MUIDrawer>
  );
}
