import { CorporateActionNotificationType } from "src/store/corporateAction/types";

export const CORPORATE_ACTION_NOTIFICATION_LABEL: {
  [key in CorporateActionNotificationType]: string;
} = {
  initial_corporate_action_informational: "Document delivery",
  initial_corporate_action_informational_webhook: "Document delivery Webhook",
  resent_initial_corporate_action_informational: "Document delivery re-send",

  initial_corporate_action_election: "Election offer delivery",
  initial_corporate_action_election_webhook: "Election offer Webhook",
  resent_initial_corporate_action_election: "Election offer re-send",

  confirmation_corporate_action_election: "Election confirmation",
  confirmation_corporate_action_election_webhook:
    "Election confirmation Webhook",

  initial_corporate_action_informational_in_app: "Document delivery",
  initial_corporate_action_election_in_app: "Election offer delivery",
};
