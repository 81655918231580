import "./styles/reset.css";
import "./styles/index.css";

// Material
import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// Say
import { Provider as SayProvider } from "@asayinc/component-library";

import { App } from "./App";
import { store } from "./store/store";
import { MaintenanceMode } from "./components/organisms/MaintenanceMode";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
  root.render(<MaintenanceMode />);
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <SayProvider>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SayProvider>
      </Provider>
    </React.StrictMode>
  );
}
