import { COLORS } from "@asayinc/component-library";
import { CategoryType } from "@asayinc/component-library/dist/components/Atoms/Text/types";
import {
  CheckCircle,
  HourglassFull,
  Warning,
  Error,
} from "@mui/icons-material";
import { NOTIFICATION_STATUS_LABEL } from "src/constants/labels";
import { NotificationStatus } from "src/store/types";

type TagProps = {
  label: string;
  fontCategory: CategoryType;
  bgcolor: string;
  icon?: React.ReactElement;
};

export function getNotificationStatusTagData(
  status: NotificationStatus
): TagProps {
  const label = NOTIFICATION_STATUS_LABEL[status] || "-";
  const tag = {
    label,
    fontCategory: "onsurface" as CategoryType,
  };
  switch (label) {
    case "Delivered":
      return { bgcolor: COLORS.SATURATED_GREEN, icon: <CheckCircle />, ...tag };
    case "Processing":
      return {
        bgcolor: COLORS.SATURATED_GREY,
        icon: <HourglassFull />,
        ...tag,
      };
    case "Soft bounce":
      return { bgcolor: COLORS.SATURATED_RED, icon: <Warning />, ...tag };
    case "Hard bounce":
      return { bgcolor: COLORS.SATURATED_RED, icon: <Warning />, ...tag };
    case "Error":
      return { bgcolor: COLORS.SATURATED_RED, icon: <Error />, ...tag };
    default:
      return {
        bgcolor: COLORS.SATURATED_GREY,
        icon: <HourglassFull />,
        ...tag,
      };
  }
}
