import { CorporateActionType } from "src/store/corporateAction/types";

export const CORPORATE_ACTION_LABEL: {
  [key in CorporateActionType]: string;
} = {
  consent_solicitation: "Consent Solicitation",
  dividend_option: "Dividend Option",
  dividend_reinvestment_plan: "Dividend Reinvestment Plan",
  exchange_offer: "Exchange Offer",
  merger_consideration_election: "Merger with Election",
  odd_lot_offer: "Odd Lot Offer",
  buyback: "Buyback",
  buyback_auction: "Buyback Auction",
  offer_to_purchase: "Offer to Purchase",
  offer_to_purchase_auction: "Offer to Purchase",
  rights_offer: "Rights Offer",
  rights_offer_oversubscription: "Rights Offer",
  informational: "Informational",
};
