import { Tabs, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { Outlet, useMatch, useNavigate } from "react-router-dom";

import { PageContainer } from "src/components/containers/PageContainer/PageContainer";
import { MAILING_TABS } from "src/constants/tabs";

export type MailingView = "approved" | "upcoming";

export const MAILING_VIEW: { [key: string]: MailingView } = {
  APPROVED: "approved",
  TO_BE_APPROVED: "upcoming",
};

export function Mailings() {
  const navigate = useNavigate();
  const match = useMatch(`/mailings/:activeTab`) as {
    params: { activeTab: string };
  };

  const handleTabChange = (value: string) => {
    navigate(`/mailings/${value}?view=approved`);
  };

  return (
    <PageContainer>
      <Text variant="h4" sx={{ pb: 4, pt: 10, px: 10 }}>
        Mailings
      </Text>
      <Box sx={{ mx: 10 }}>
        <Tabs
          activeTab={match?.params?.activeTab || "proxy"}
          clickHandler={handleTabChange}
          tabs={MAILING_TABS}
        />
      </Box>
      <Outlet />
    </PageContainer>
  );
}
