// This function returns a string with the ordinal number suffix appended to the number.
// For example, 1 becomes 1st, 2 becomes 2nd, 3 becomes 3rd, 4 becomes 4th, etc.
// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export function getOrdinalNumber(n: number) {
  const j = n % 10,
    k = n % 100;
  if (j == 1 && k != 11) {
    return n + "st";
  }
  if (j == 2 && k != 12) {
    return n + "nd";
  }
  if (j == 3 && k != 13) {
    return n + "rd";
  }
  return n + "th";
}
