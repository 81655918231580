import { nFormatter } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { Card } from "src/components/atoms/Card";
import { ErrorState } from "src/components/atoms/ErrorState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { LabelField } from "src/components/molecules/LabelField";
import { LoadingCard } from "src/components/molecules/Loading/LoadingCard";
import { useCorporateActionEventQuery } from "src/store/corporateAction";
import { getPercentage } from "src/utils/getPercentage";

export function InsightsTab() {
  const { id } = useParams() as { id: string };
  const {
    data: corporateActionEvent,
    isLoading,
    error,
  } = useCorporateActionEventQuery({ id });

  if (error || (!isLoading && !corporateActionEvent)) {
    return <ErrorState />;
  }

  if (isLoading) {
    return (
      <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
        <Box sx={{ display: "flex" }}>
          <LoadingCard sx={{ flexGrow: 1, flexBasis: 0, mr: 20 }} />
          <LoadingCard sx={{ flexGrow: 1, flexBasis: 0 }} />
        </Box>
      </ScrollContainer>
    );
  }

  const { stats } = corporateActionEvent;
  const {
    electionCount,
    electionWithActionableChoiceCount,
    electionWithNoChoiceCount,
    totalShares,
  } = stats;

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1, flexBasis: 0, mr: 12 }}>
          <Card title="Position breakdown">
            <LabelField
              sx={{
                py: 5,
                px: 4,
                borderBottom: "1px solid #e0e0e0",
                fontWeight: 600,
              }}
              isLabelBold={true}
              label="Total shares owned across all customers"
              value={`${nFormatter(totalShares, true)} shares`}
              valueTooltip={`${totalShares.toLocaleString()} shares`}
            />
          </Card>
        </Box>
        <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
          <Card title="Election breakdown">
            <LabelField
              sx={{
                py: 5,
                px: 4,
                borderBottom: "1px solid #e0e0e0",
                fontWeight: 600,
              }}
              isLabelBold={true}
              label="Total number of elections submitted"
              value={`${electionCount.toLocaleString()} customers`}
            />
            <LabelField
              sx={{
                py: 5,
                pr: 4,
                pl: 4,
                ml: 9,
                borderBottom: "1px solid #e0e0e0",
              }}
              isValueBold={false}
              label="Number of actionable elections"
              tooltip="The number of customers who submitted that they wish to participate in the offer."
              value={`${electionWithActionableChoiceCount.toLocaleString()} (${getPercentage(
                electionWithActionableChoiceCount,
                electionCount
              )}%)`}
            />
            <LabelField
              sx={{
                py: 5,
                pr: 4,
                pl: 4,
                ml: 9,
                borderBottom: "1px solid #e0e0e0",
              }}
              isValueBold={false}
              label="Number of no elections"
              tooltip="The number of customers who submitted that they do not wish to participate in the event's offer."
              value={`${electionWithNoChoiceCount.toLocaleString()} (${getPercentage(
                electionWithNoChoiceCount,
                electionCount
              )}%)`}
            />
          </Card>
        </Box>
      </Box>
    </ScrollContainer>
  );
}
