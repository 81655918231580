import { Tabs } from "@asayinc/component-library";
import { Box } from "@mui/material";
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";

import { BackLink } from "src/components/atoms/BackLink";
import { ErrorState } from "src/components/atoms/ErrorState";
import { PageContainer } from "src/components/containers/PageContainer";
import { CustomerHeader } from "src/components/molecules/CustomerHeader";
import { CUSTOMER_DETAILS_TABS } from "src/constants/tabs";
import { useCustomerQuery } from "src/store/customer";

export type CustomerDetailView = "proxy" | "corporate-action";

export const CUSTOMER_DETAIL_VIEW: { [key: string]: CustomerDetailView } = {
  PROXY: "proxy",
  CORPORATE_ACTION: "corporate-action",
};

export function CustomerDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams() as { id: string };
  const match = useMatch(`/customers/${id}/:activeTab`) as {
    params: { activeTab: string };
  };
  const { data: customer, isLoading, error } = useCustomerQuery({ id });

  const handleTabChange = (value: string) => {
    navigate(`/customers/${id}/${value}`, { state: { ...location.state } });
  };

  if (error || (!isLoading && !customer)) {
    return (
      <PageContainer>
        <ErrorState />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <BackLink sx={{ position: "absolute", pl: 8, pt: 10 }} />
      <CustomerHeader customer={customer} sx={{ px: 10, pt: 10 }} />
      <Box sx={{ mx: 10, mt: 6 }}>
        <Tabs
          activeTab={match?.params?.activeTab || "mailings"}
          centered
          clickHandler={handleTabChange}
          tabs={CUSTOMER_DETAILS_TABS}
        />
      </Box>
      <Outlet />
    </PageContainer>
  );
}
