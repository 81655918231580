import { useSearchParams } from "react-router-dom";

export type DateFilterType = "date-on" | "date-before" | "date-after";

export function useDateQueryParams(key: string) {
  const [searchParams] = useSearchParams();
  const PARAM_KEYS = {
    AT: key,
    BEFORE: `${key}__lte`,
    AFTER: `${key}__gte`,
  };
  const at = searchParams.get(PARAM_KEYS.AT);
  const before = searchParams.get(PARAM_KEYS.BEFORE);
  const after = searchParams.get(PARAM_KEYS.AFTER);

  return {
    at,
    before,
    after,
  };
}
