import { SayIcon, SecondaryTabs } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Drawer } from "src/components/atoms/Drawer";
import { CorporateActionCommunicationDetails } from "src/components/organisms/CorporateActionCommunicationDetails";
import { ProxyCommunicationDetails } from "src/components/organisms/ProxyCommunicationDetails";
import { CustomerCorporateActionNotificationsTable } from "src/components/organisms/CustomerCorporateActionNotificationsTable";
import { CustomerProxyNotificationsTable } from "src/components/organisms/CustomerProxyNotificationsTable";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { ListNotification } from "src/store/types";
import { CUSTOMER_DETAIL_VIEW, CustomerDetailView } from "../CustomerDetail";
import { ScrollContainer } from "src/components/containers/ScrollContainer";

export function NotificationsTab() {
  const { id } = useParams() as { id: string };
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notification, setNotification] = useState<ListNotification | null>(
    null
  );
  const view =
    (searchParams.get(TABLE_SEARCH_PARAMS.VIEW) as CustomerDetailView) ||
    CUSTOMER_DETAIL_VIEW.PROXY;

  const handleRowClick = (notification: ListNotification) => {
    setNotification(notification);
    setIsDrawerOpen(true);
  };

  const handleCloseCommunicationDrawer = () => {
    setIsDrawerOpen(false);
    setNotification(null);
  };

  const handleSecondaryTabChange = (tab: string) => {
    const newParams = new URLSearchParams({
      view: tab,
    });
    setSearchParams(newParams);
  };

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Stack sx={{ pb: 8 }}>
        <SecondaryTabs
          sx={{ mx: "auto" }}
          tabs={[
            {
              label: "Proxy",
              value: CUSTOMER_DETAIL_VIEW.PROXY,
              icon:
                view === CUSTOMER_DETAIL_VIEW.PROXY ? (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="ProxyFill" color="#1968FF" />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="ProxyOutline" />
                  </Box>
                ),
            },
            {
              label: "Voluntary corporate actions",
              value: CUSTOMER_DETAIL_VIEW.CORPORATE_ACTION,
              icon:
                view === CUSTOMER_DETAIL_VIEW.CORPORATE_ACTION ? (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="VcaFill" color="#1968FF" />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="VcaOutline" />
                  </Box>
                ),
            },
          ]}
          activeTab={view}
          clickHandler={handleSecondaryTabChange}
        />
      </Stack>
      {!view || view === "proxy" ? (
        <CustomerProxyNotificationsTable
          customerId={id}
          onRowClick={handleRowClick}
          selectedNotification={notification}
        />
      ) : (
        <CustomerCorporateActionNotificationsTable
          customerId={id}
          onRowClick={handleRowClick}
          selectedNotification={notification}
        />
      )}
      <Drawer
        anchor="right"
        onClose={handleCloseCommunicationDrawer}
        open={isDrawerOpen}
        testId="customer-mailing-drawer"
      >
        {notification && notification.proxyEvent && (
          <ProxyCommunicationDetails
            activeTab="notification"
            communicationId={notification.communicationIds[0]}
            notificationId={notification.id}
            onClose={handleCloseCommunicationDrawer}
            proxyId={notification?.proxyEvent?.id}
          />
        )}
        {notification && notification.corporateActionEvent && (
          <CorporateActionCommunicationDetails
            activeTab="notification"
            communicationId={notification.communicationIds[0]}
            corporateActionId={notification?.corporateActionEvent?.id}
            notificationId={notification.id}
            onClose={handleCloseCommunicationDrawer}
          />
        )}
      </Drawer>
    </ScrollContainer>
  );
}
