import { AutoCompleteOption } from "@asayinc/component-library";

export const VOTE_STATUS_OPTIONS: AutoCompleteOption[] = [
  {
    name: "Not Viewed",
    id: "not_viewed",
  },
  {
    name: "Viewed",
    id: "viewed",
  },
  {
    name: "Withdrawn",
    id: "withdrawn",
  },
  {
    name: "Online",
    id: "online",
  },
  {
    name: "Legal Proxy",
    id: "legal_proxy",
  },
  {
    name: "Vote Transmission",
    id: "vote_transmission",
  },
];
