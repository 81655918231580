import { TableV2 as Table } from "@asayinc/component-library";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { MailingStatsBar } from "src/components/molecules/MailingStatsBar";
import { ROW_OPTIONS, CORPORATE_ACTION_OPTIONS } from "src/constants/options";
import { useTablePagination } from "src/hooks/useTablePagination";
import {
  useCorporateActionEventsAggregateQuery,
  useCorporateActionEventsQuery,
} from "src/store/corporateAction";
import { getColumnData, getColumns, getRowData } from "./utils";
import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import { formatDate } from "src/utils/formatDate";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import dayjs from "dayjs";

export function CorporateAction() {
  const navigate = useNavigate();
  const methods = useForm();
  const { type } = useTableSearchParams();

  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const thirtyDaysBefore = dayjs().subtract(30, "days");
  const { data, isFetching, isLoading } = useCorporateActionEventsQuery({
    offset,
    limit,
    ordering: ordering ? ordering : "-approved_at",
    type,
    view: "approved",
    approvedAfter: formatDate(thirtyDaysBefore),
  });
  const { data: aggregateData } = useCorporateActionEventsAggregateQuery({
    view: "approved",
    approvedAfter: formatDate(thirtyDaysBefore),
  });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const columns = useMemo(() => getColumns(), []);
  const columnData = useMemo(() => {
    const handleRowClick = (rowData: unknown) => {
      const { id } = rowData as { id: string; idx: number };
      navigate(`${id}/offer-info`);
    };
    return getColumnData({
      getSort,
      onOrderingChange,
      onRowClick: handleRowClick,
    });
  }, [getSort, onOrderingChange, navigate]);
  const rowData = getRowData(data?.results || []);

  const filterList = (
    <FormProvider {...methods}>
      <TableMultiSelectFilter
        fieldName={TABLE_SEARCH_PARAMS.TYPE}
        label="Types"
        options={CORPORATE_ACTION_OPTIONS}
        queryParameter={TABLE_SEARCH_PARAMS.TYPE}
      />
    </FormProvider>
  );

  const failureCount =
    (aggregateData?.initialBouncedCount || 0) +
    (aggregateData?.initialErrorCount || 0);
  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <MailingStatsBar
        communicationCount={aggregateData?.initialCount || 0}
        customerCount={aggregateData?.uniqueCustomerCount || 0}
        eventCount={aggregateData?.corporateActionEventCount || 0}
        failureCount={failureCount}
        sx={{ mb: 8 }}
      />
      <Table
        count={data?.count || 0}
        columnData={columnData}
        columns={columns}
        isFetching={isFetching}
        isLoading={isLoading}
        noResultsData={{
          title: "No corporate actions found",
          subtitle:
            "Sorry, there are no mailings that match your applied filters.",
        }}
        paginateProps={paginateProps}
        rows={rowData}
        tableMinWidth={1100}
        title="Approved mailings from the last 30 days"
        titleVariant="subtitle2"
        toolbarInside
        toolbarCollapse={{
          Icon: FilterListIcon,
          collapseContent: filterList,
          isFlat: true,
        }}
      />
    </ScrollContainer>
  );
}
