import { LoadingSkeleton } from "@asayinc/component-library";
import { Box, SxProps } from "@mui/material";
import {
  AccountBalanceOutlined,
  AccountCircleOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { SearchResult, SearchSecurity } from "src/store/globalSearch/types";
import { SearchRow } from "../SearchRow";
import { getHighlightedText } from "src/utils/getHighlightedText";

interface ISearchResultsProps {
  isLoading: boolean;
  options: SearchResult[] | undefined;
  leftHeader: string;
  leftValueKey: string;
  rightHeader: string;
  rightValueKey: string;
  // This is temp hacky way for navigation for now
  // Since we have the ID we want to either navigate to customer detail page or
  // issuer detail page. Will figure out a better way to do this when I get back
  toCustomer?: boolean;
  onClose?: () => void;
  searchTerm: string;
  sx?: SxProps;
}

export function SearchResults({
  isLoading,
  options,
  leftHeader,
  leftValueKey,
  rightHeader,
  rightValueKey,
  toCustomer = true,
  searchTerm,
  sx,
  onClose,
}: ISearchResultsProps) {
  const navigate = useNavigate();

  if (!isLoading && options?.length === 0) {
    return null;
  }

  return (
    <Box sx={sx}>
      <SearchRow
        leftContent={leftHeader}
        rightContent={rightHeader}
        isRightBold={false}
      />
      {isLoading && (
        <SearchRow
          leftContent={<LoadingSkeleton />}
          rightContent={
            <LoadingSkeleton sx={{ width: "50%", float: "right" }} />
          }
        />
      )}
      {!isLoading &&
        options &&
        options.map((option, key) => {
          const handleOnClick = () => {
            const { id } = option;
            if (toCustomer) {
              navigate(`/customers/${id}/mailings`);
            } else {
              const { issuerId } = option as SearchSecurity;
              navigate(`/issuers/${issuerId}/proxy`);
            }
            if (onClose) {
              onClose();
            }
          };

          return (
            <SearchRow
              key={key}
              onClick={handleOnClick}
              leftContent={getHighlightedText(option[leftValueKey], searchTerm)}
              rightContent={getHighlightedText(
                option[rightValueKey],
                searchTerm
              )}
              rightIcon={
                toCustomer ? (
                  <AccountCircleOutlined sx={{ ml: 2 }} />
                ) : (
                  <AccountBalanceOutlined sx={{ ml: 2 }} />
                )
              }
            />
          );
        })}
    </Box>
  );
}
