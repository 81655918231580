import { IconButton, Text, Tooltip } from "@asayinc/component-library";
import { InfoOutlined } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";

interface ILabelFieldProps {
  label: string;
  value: string | undefined;
  testId?: string;
  isLabelBold?: boolean;
  isValueBold?: boolean;
  tooltip?: string;
  valueTooltip?: string;
  sx?: SxProps;
}

export function LabelField({
  isLabelBold = false,
  isValueBold = true,
  label,
  value,
  testId,
  tooltip,
  valueTooltip,
  sx,
}: ILabelFieldProps) {
  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        alignItems: "center",
      }}
      data-testid={testId || `${label}-label-field`}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          mr: "48px",
          alignItems: "center",
        }}
      >
        <Text variant={isLabelBold ? "subtitle2" : "body2"}>{label}</Text>
        {tooltip && (
          <Tooltip
            placement="bottom"
            sx={{ display: "flex", alignItems: "center", ml: 1 }}
            title={tooltip}
          >
            <IconButton size="small">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          textAlign: "end",
          overflow: "hidden",
        }}
      >
        <Tooltip sx={{ marginLeft: "auto" }} title={valueTooltip}>
          <Text
            variant={isValueBold ? "subtitle2" : "body2"}
            loadingProps={{ sx: { width: "50%" } }}
          >
            {value}
          </Text>
        </Tooltip>
      </Box>
    </Box>
  );
}
