import { AutoCompleteOption } from "@asayinc/component-library";

export const NOTIFICATION_STATUS_OPTIONS: AutoCompleteOption[] = [
  { name: "Delivered", id: "sent,email_preview,unsubscribed" },
  {
    name: "Error",
    id: "error,will_not_send,no_subject_found,no_template_found,no_webhook_url_found",
  },
  { name: "Hard bounce", id: "invalid_email,hard_bounce,blocked" },
  {
    name: "Soft bounce",
    id: "unknown,soft_bounce,transient,dns_error,spam_notification,spam_complaint",
  },
  { name: "Processing", id: "none,queued,processing" },
];
