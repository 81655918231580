import { IconButton, Text } from "@asayinc/component-library";
import { Box, SxProps } from "@mui/material";
import {
  AccountBalanceOutlined,
  AccountCircleOutlined,
  MailOutline,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface ICommunicationHeaderProps {
  backLink?: string;
  customerId?: string;
  email: string;
  issuerId?: string;
  issuerName: string;
  isProxy: boolean;
  sx?: SxProps;
  type: string;
}

export function CommunicationHeader({
  backLink,
  customerId,
  email,
  issuerId,
  issuerName,
  isProxy,
  sx,
  type,
}: ICommunicationHeaderProps) {
  const navigate = useNavigate();
  const isPiiFreeBroker = useIsPiiFreeBroker();

  const handleMailingClick = () => {
    if (isProxy) {
      navigate("/mailings/proxy?view=approved");
    } else {
      navigate("/mailings/corporate-action?view=approved");
    }
  };

  const handleIssuerClick = () => {
    if (issuerId) {
      navigate(
        `/issuers/${issuerId}/${isProxy ? "proxy" : "corporate-action"}`,
        {
          state: { backLink },
        }
      );
    }
  };

  const handleProfileClick = () => {
    if (customerId) {
      navigate(
        `/customers/${customerId}/mailings?view=${
          isProxy ? "proxy" : "corporate-action"
        }`,
        {
          state: { backLink },
        }
      );
    }
  };

  return (
    <Box sx={sx} data-testid="communication-detail-header">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Text variant="subtitle1" onClick={handleMailingClick}>
          {type}
        </Text>
        <IconButton sx={{ ml: 0.5 }} onClick={handleMailingClick}>
          <MailOutline />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Text sx={{ mr: 2 }}>for</Text>
        <Text
          data-testid="communication-header-issuer-name"
          onClick={handleIssuerClick}
          sx={{
            cursor: issuerId ? "pointer" : "auto",
          }}
          variant="subtitle1"
        >
          {issuerName}
        </Text>
        <IconButton sx={{ ml: 0.5 }} onClick={handleIssuerClick}>
          <AccountBalanceOutlined />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Text sx={{ mr: 2 }}>sent to</Text>
        <Text
          data-testid="communication-header-email"
          onClick={handleProfileClick}
          sx={{
            cursor: customerId ? "pointer" : "auto",
          }}
          variant="subtitle1"
        >
          {isPiiFreeBroker ? customerId : email}
        </Text>
        <IconButton sx={{ ml: 0.5 }} onClick={handleProfileClick}>
          <AccountCircleOutlined />
        </IconButton>
      </Box>
    </Box>
  );
}
