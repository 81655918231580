import { TableV2 as Table } from "@asayinc/component-library";
import { FilterList } from "@mui/icons-material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import { ROW_OPTIONS, ELECTION_STATUS_OPTIONS } from "src/constants/options";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useSearchFns } from "src/hooks/useSearchFns";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { useCorporateActionEventCommunicationsQuery } from "src/store/corporateAction";
import { ListCorporateActionCommunication } from "src/store/corporateAction/types";
import { getColumnData, getColumns, getRowData } from "./utils";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface ICorporateActionCommunicationsTable {
  corporateActionId: string;
  onRowClick: (communication: ListCorporateActionCommunication) => void;
  selectedCommunication?: ListCorporateActionCommunication | null;
  showVotedColumn?: boolean;
  title?: string;
}

export function CorporateActionCommunicationsTable({
  corporateActionId,
  onRowClick,
  selectedCommunication,
  showVotedColumn = true,
  title = "Communications",
}: ICorporateActionCommunicationsTable) {
  const methods = useForm();
  const { electionStatus, search } = useTableSearchParams();
  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const searchFns = useSearchFns();
  const { data, isFetching, isLoading } =
    useCorporateActionEventCommunicationsQuery({
      id: corporateActionId,
      limit,
      ordering,
      offset,
      electionStatus,
      search,
    });
  const isPiiFreeBroker = useIsPiiFreeBroker();

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const columns = useMemo(
    () => getColumns(isPiiFreeBroker, showVotedColumn),
    [isPiiFreeBroker, showVotedColumn]
  );
  const columnData = useMemo(
    () =>
      getColumnData(isPiiFreeBroker, showVotedColumn, {
        getSort,
        onOrderingChange,
      }),
    [isPiiFreeBroker, getSort, onOrderingChange, showVotedColumn]
  );
  const rowData = getRowData(
    isPiiFreeBroker,
    showVotedColumn,
    data?.results || [],
    onRowClick,
    selectedCommunication
  );

  const filterList = (
    <FormProvider {...methods}>
      {showVotedColumn && (
        <TableMultiSelectFilter
          fieldName={TABLE_SEARCH_PARAMS.ELECTION_STATUS}
          label="Election"
          options={ELECTION_STATUS_OPTIONS}
          queryParameter={TABLE_SEARCH_PARAMS.ELECTION_STATUS}
        />
      )}
    </FormProvider>
  );

  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search by name, email",
  };

  return (
    <Table
      testid="corporate-action-communications-table"
      count={data?.count || 0}
      columnData={columnData}
      columns={columns}
      isFetching={isFetching}
      isLoading={isLoading}
      noResultsData={{
        title: "No communications found",
        subtitle:
          "Sorry, there are no communications that match your applied filters.",
      }}
      paginateProps={paginateProps}
      rows={rowData}
      searchBarProps={searchBarProps}
      tableMinWidth={1100}
      titleVariant="subtitle2"
      title={title}
      toolbarInside
      toolbarCollapse={{
        Icon: FilterList,
        collapseContent: filterList,
        isFlat: true,
      }}
    />
  );
}
