import {
  AutoCompleteOption,
  FilterSelectionFlow,
  FilterType,
} from "@asayinc/component-library";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

interface ITableMultiSelectFilterProps {
  fieldName: string;
  label: string;
  options: AutoCompleteOption[];
  queryParameter: string;
  view?: string;
}

// Note: This component assumes all filters will also exist as searchParams in the url and
// uses the searchParam for the filter state/value.
export function TableMultiSelectFilter({
  fieldName,
  label,
  options,
  queryParameter,
  view,
}: ITableMultiSelectFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const formValues = watch(fieldName);

  // We want to reset this filter each time the view is updated
  // approved -> upcoming or upcoming -> approved we want to reset filters
  useEffect(() => {
    methods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleOnClear = () => {
    searchParams.delete(queryParameter);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  /**
    Initialize filters
  */
  useEffect(() => {
    const tagIdsString: string = searchParams.get(queryParameter) || "";
    if (tagIdsString.length > 0 && options.length > 0) {
      const tagIdsList = tagIdsString.split(",");
      setValue(fieldName, { values: tagIdsList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
    Updating URL search params
  */
  useEffect(() => {
    if (formValues) {
      const { values = [] } = formValues;
      if (values.length) {
        const tagParamValue = values.join(",");
        searchParams.set(queryParameter, tagParamValue);
      } else {
        searchParams.delete(queryParameter);
      }
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <FilterSelectionFlow
      clearable
      skipMessaging
      chipLabel={label}
      filterTypeOptions={[
        {
          type: FilterType.Values,
          label,
          options: {
            groupLabel: label,
            options,
          },
        },
      ]}
      onClear={handleOnClear}
      withinForm={{ fieldName }}
    />
  );
}
