import { Stack, SxProps } from "@mui/material";
import { MutableRefObject } from "react";

interface IScrollContainerProps {
  children: React.ReactNode;
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
  sx?: SxProps;
}

export function ScrollContainer({
  children,
  scrollContainerRef,
  sx,
}: IScrollContainerProps) {
  return (
    <Stack
      direction="column"
      flexGrow={1}
      ref={scrollContainerRef}
      sx={{
        overflow: "auto",
        scrollbarGutter: "stable",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
