import { CommunicationNotification } from "src/store/types";

export function getTotalResend(
  notifications: CommunicationNotification[],
  resendTypes: string[]
) {
  return notifications.filter((notification) =>
    resendTypes.includes(notification.type)
  ).length;
}
