import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseQueryWithCamelize } from "../apiTools";
import {
  ILoginParams,
  IResetPasswordParams,
  ISubmitEmailParams,
  ILoginResponse,
} from "./types";

const baseQuery = getBaseQueryWithCamelize(
  fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SSO_API}v1/`,
  })
);

export const ssoApi = createApi({
  reducerPath: "ssoApi",
  baseQuery,
  endpoints: (builder) => ({
    resetPassword: builder.mutation<unknown, IResetPasswordParams>({
      query: (data) => ({
        url: "auth/password/reset/confirm/",
        method: "POST",
        body: data,
      }),
    }),
    attemptLogin: builder.mutation<ILoginResponse, ILoginParams>({
      query: (data) => ({
        url: "auth/login/",
        method: "POST",
        body: data,
      }),
    }),
    submitEmail: builder.mutation<unknown, ISubmitEmailParams>({
      query: (data) => ({
        url: "auth/password/reset/",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useAttemptLoginMutation,
  useSubmitEmailMutation,
} = ssoApi;
