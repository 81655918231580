import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import { Card } from "src/components/atoms/Card";
import { ErrorState } from "src/components/atoms/ErrorState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { LabelField } from "src/components/molecules/LabelField";
import { LoadingCard } from "src/components/molecules/Loading/LoadingCard";
import { OfferCard } from "src/components/molecules/OfferCard";
import { useCorporateActionEventQuery } from "src/store/corporateAction";
import { formatDate } from "src/utils/formatDate";

export function OfferInfoTab() {
  const { id } = useParams() as { id: string };
  const {
    data: corporateActionEvent,
    isLoading,
    error,
  } = useCorporateActionEventQuery({ id });

  if (error || (!isLoading && !corporateActionEvent)) {
    return <ErrorState />;
  }

  if (isLoading) {
    return (
      <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
        <Stack direction="row">
          <LoadingCard sx={{ mr: 12, flexGrow: 1, flexBasis: 0 }} />
          <LoadingCard sx={{ flexGrow: 1, flexBasis: 0 }} />
        </Stack>
      </ScrollContainer>
    );
  }

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Stack direction="row">
        <Box sx={{ flexGrow: 1, minWidth: "50%" }}>
          <Card
            title="Details"
            testId="corporate-action-offer-info-card"
            sx={{ mr: 12 }}
          >
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="DTC expiration"
              value={formatDate(
                corporateActionEvent?.dtcExpiration,
                true,
                true
              )}
            />
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="Election submission cut-off"
              value={formatDate(
                corporateActionEvent?.electionCutoff,
                true,
                true
              )}
            />
          </Card>
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: "50%" }}>
          <OfferCard offer={corporateActionEvent.offer} />
        </Box>
      </Stack>
    </ScrollContainer>
  );
}
