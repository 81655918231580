import { LoadingSkeleton } from "@asayinc/component-library";
import { SxProps } from "@mui/material";
import { Card } from "src/components/atoms/Card";

interface ILoadingCardProps {
  sx?: SxProps;
}

export function LoadingCard({ sx }: ILoadingCardProps) {
  return (
    <Card title={undefined} sx={sx} testId="loading-card">
      <LoadingSkeleton sx={{ mt: 6, mb: 4 }} />
      <LoadingSkeleton sx={{ mb: 4 }} />
      <LoadingSkeleton sx={{ mb: 4 }} />
      <LoadingSkeleton />
    </Card>
  );
}
