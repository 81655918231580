type ErrorResponse = { data: { detail: string }; status: number };

// Referencing https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
// to resolve type checking issues with error handling
export function isErrorWithMessage(error: unknown): error is ErrorResponse {
  return (
    typeof error === "object" &&
    error != null &&
    "data" in error &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error as any).data.detail === "string"
  );
}
