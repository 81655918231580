import {
  useCorporateActionEventNotificationPreviewQuery,
  useCorporateActionEventNotificationQuery,
} from "src/store/corporateAction";
import {
  useProxyEventNotificationPreviewQuery,
  useProxyEventNotificationQuery,
} from "src/store/proxy";
import { Communication, CommunicationNotification } from "src/store/types";

// We need this query to get the notification events
// for the timeline
export function useNotification(
  communication: Communication,
  notification: CommunicationNotification,
  isOpen: boolean
) {
  const { proxyEvent, corporateActionEvent } = communication;

  const {
    data: proxyNotificationWithEvents,
    isLoading: isProxyNotificationLoading,
  } = useProxyEventNotificationQuery(
    {
      proxyId: communication.proxyEvent?.id || "",
      notificationId: notification.id,
    },
    { skip: !isOpen || !proxyEvent }
  );

  const { data: proxyPreview, isLoading: isProxyPreviewLoading } =
    useProxyEventNotificationPreviewQuery(
      {
        proxyId: communication.proxyEvent?.id || "",
        notificationId: notification.id,
      },
      { skip: !isOpen || !proxyEvent }
    );

  const {
    data: corporateActionNotificationWithEvents,
    isLoading: isCorporateActionNotificationLoading,
  } = useCorporateActionEventNotificationQuery(
    {
      corporateActionId: communication.corporateActionEvent?.id || "",
      notificationId: notification.id,
    },
    { skip: !isOpen || !corporateActionEvent }
  );

  const {
    data: corporateActionPreview,
    isLoading: isCorporateActionPreviewLoading,
  } = useCorporateActionEventNotificationPreviewQuery(
    {
      corporateActionId: communication.corporateActionEvent?.id || "",
      notificationId: notification.id,
    },
    { skip: !isOpen || !corporateActionEvent }
  );

  return {
    isLoading:
      isCorporateActionPreviewLoading ||
      isProxyNotificationLoading ||
      isCorporateActionNotificationLoading ||
      isProxyPreviewLoading,
    preview: proxyEvent ? proxyPreview : corporateActionPreview,
    notificationWithEvents: proxyEvent
      ? proxyNotificationWithEvents
      : corporateActionNotificationWithEvents,
  };
}
