import { COLORS, Text } from "@asayinc/component-library";
import { AccountBalanceOutlined, Email } from "@mui/icons-material";
import { Box, IconButton, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getEndDateContent, getStartDateContent } from "./utils";

interface IDetailPageHeader {
  backLink: string;
  issuerName: string;
  issuerId: string;
  isApproved?: boolean;
  isProxy?: boolean;
  type: string;
  startDate: string | null;
  endDate: string | null;
  sx?: SxProps;
}

export function DetailPageHeader({
  backLink,
  issuerName,
  issuerId,
  isApproved = true,
  isProxy = true,
  type,
  startDate,
  endDate,
  sx,
}: IDetailPageHeader) {
  const navigate = useNavigate();

  const handleIssuerClick = () => {
    navigate(`/issuers/${issuerId}/${isProxy ? "proxy" : "corporate-action"}`, {
      state: { backLink },
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Box
        sx={{
          pt: "14px",
          pb: "8px",
          px: "14px",
          backgroundColor: "#3D3D3D",
          borderRadius: 2,
          mr: 4,
        }}
      >
        <Email sx={{ borderRadius: 2, color: "white" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Text
            sx={{ mr: 2 }}
            variant="subtitle1"
            data-testid="page-header-type"
          >
            {type}
          </Text>
          for
          <Text
            sx={{ mx: 2, cursor: issuerId ? "pointer" : "auto" }}
            variant="subtitle1"
            data-testid="page-header-issuer"
            onClick={handleIssuerClick}
          >
            {issuerName}
          </Text>
          <IconButton onClick={handleIssuerClick} size="small">
            <AccountBalanceOutlined style={{ color: "black" }} />
          </IconButton>
        </Box>
        {isApproved ? (
          (startDate || endDate) && (
            <Box
              sx={{
                color: COLORS.P_MED_EMPHASIS,
                display: "flex",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Text
                emphasis="medium"
                data-testid="page-header-start-date"
                sx={{ mr: 2 }}
                variant="body2"
              >
                {startDate ? getStartDateContent(startDate) : ""}
              </Text>
              {startDate && endDate ? "·" : ""}
              <Text
                emphasis="medium"
                data-testid="page-header-end-date"
                sx={{ ml: 2 }}
                variant="body2"
              >
                {endDate ? getEndDateContent(endDate) : ""}
              </Text>
            </Box>
          )
        ) : (
          <Text
            color={COLORS.P_MED_EMPHASIS}
            data-testid="page-header-start-date"
            sx={{ mr: 2, color: COLORS.P_MED_EMPHASIS, mt: 1 }}
            variant="body2"
          >
            To be approved
          </Text>
        )}
      </Box>
    </Box>
  );
}
