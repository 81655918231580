import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";

export interface OrderingData {
  id: string;
  sort: "asc" | "desc";
}

export function useTablePagination() {
  // Assumes all table pagination props will use the same search parameters
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get(TABLE_SEARCH_PARAMS.LIMIT) || "10";
  const ordering = searchParams.get(TABLE_SEARCH_PARAMS.ORDERING) || "";
  const page = searchParams.get(TABLE_SEARCH_PARAMS.PAGE) || "1";

  const onChangePage = (page: number) => {
    searchParams.set(TABLE_SEARCH_PARAMS.PAGE, String(page));
    setSearchParams(searchParams);
  };

  const onChangeRows = (rows: number) => {
    searchParams.set(TABLE_SEARCH_PARAMS.LIMIT, String(rows));
    searchParams.set(TABLE_SEARCH_PARAMS.PAGE, String(1));
    setSearchParams(searchParams);
  };

  const onOrderingChange = (orderingData: unknown, sortKey: string) => {
    const { sort } = orderingData as OrderingData;
    if (sort === "asc") {
      searchParams.set(TABLE_SEARCH_PARAMS.ORDERING, `-${sortKey}`);
      setSearchParams(searchParams);
    } else {
      searchParams.set(TABLE_SEARCH_PARAMS.ORDERING, sortKey);
      setSearchParams(searchParams);
    }
  };

  const getSort = useCallback(
    (sortKey: string) => {
      const isFieldSorting = ordering.indexOf(sortKey) !== -1;
      if (isFieldSorting) {
        return ordering.indexOf("-") !== -1 ? "desc" : "asc";
      }
      return undefined;
    },
    [ordering]
  );

  return {
    getSort,
    limit: parseFloat(limit),
    offset: parseFloat(limit) * (parseFloat(page) - 1),
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
    page: parseFloat(page),
  };
}
