import {
  COLORS,
  SayIcon,
  SecondaryTabs,
  Text,
} from "@asayinc/component-library";
import { Box, Drawer, Stack } from "@mui/material";
import debounce from "lodash.debounce";
import { useState } from "react";

import { EmptyState } from "src/components/molecules/EmptyState";
import { SearchInputField } from "src/components/molecules/SearchInputField";
import { SearchResults } from "src/components/molecules/SearchResults";
import {
  useSearchCustomersQuery,
  useSearchSecuritiesQuery,
} from "src/store/globalSearch";

interface ISearchPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

type SearchTabs = "all" | "issuers" | "customers";

export function SearchPanel({ isOpen, onClose }: ISearchPanelProps) {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<SearchTabs>("all");

  const debouncedSetSearchTerm = debounce(
    (term: string) => setSearchTerm(term),
    300
  );
  const { data: customersSearch, isFetching: isCustomerFetching } =
    useSearchCustomersQuery({ search: searchTerm }, { skip: !searchTerm });
  const { data: securitiesSearch, isFetching: isSecuritiesFetching } =
    useSearchSecuritiesQuery({ search: searchTerm }, { skip: !searchTerm });

  const handleOnSearchChange = (value: string) => {
    debouncedSetSearchTerm(value);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as SearchTabs);
  };

  const handleOnClose = () => {
    setSearchTerm(null);
    onClose();
  };

  const matchedSecurities = securitiesSearch?.tickers.concat(
    securitiesSearch?.issuerNames
  );
  const filteredSecurities = matchedSecurities?.filter(
    (security, index, array) =>
      array.findIndex((compare) => compare.id === security.id) === index
  );

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleOnClose}
      ModalProps={{
        disablePortal: true,
        sx: {
          position: "absolute",
          display: "flex",
        },
      }}
      PaperProps={{
        sx: {
          m: 4,
          width: "640px",
          maxWidth: "640px",
          borderRadius: 2,
          flexGrow: 1,
          p: 8,
          position: "relative",
          height: "auto",
        },
      }}
    >
      <SearchInputField onChange={handleOnSearchChange} sx={{ mb: 6 }} />
      <Stack m={"0 auto"}>
        <SecondaryTabs
          sx={{ mb: 6 }}
          tabs={[
            {
              label: "All",
              value: "all",
              size: "small",
            },
            {
              label: "Issuers",
              value: "issuers",
              icon:
                activeTab === "issuers" ? (
                  <Box sx={{ display: "flex", mr: 3 }}>
                    <SayIcon name="IssuerFill" color="#1968FF" />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", mr: 3 }}>
                    <SayIcon name="IssuerOutline" />
                  </Box>
                ),
              size: "small",
            },
            {
              label: "Customers",
              value: "customers",
              icon:
                activeTab === "customers" ? (
                  <Box sx={{ display: "flex", mr: 3 }}>
                    <SayIcon name="ProfileFill" color="#1968FF" />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", mr: 3 }}>
                    <SayIcon name="ProfileOutline" />
                  </Box>
                ),
              size: "small",
            },
          ]}
          activeTab={activeTab}
          clickHandler={handleTabChange}
        />
      </Stack>
      {!searchTerm && <EmptyState isGray={true} />}
      {searchTerm &&
        (customersSearch?.emails.length === 0 &&
        customersSearch?.customerIdentifiers.length === 0 &&
        securitiesSearch?.tickers.length === 0 &&
        securitiesSearch?.issuerNames.length === 0 ? (
          <EmptyState
            showNoResults={true}
            title="No results found"
            subtitle="Try adjusting your search or filter to find what you're looking for"
          />
        ) : (
          <Box sx={{ mx: -8, px: 8, overflowY: "auto" }}>
            {(activeTab === "all" || activeTab === "issuers") && (
              <SearchResults
                isLoading={isSecuritiesFetching}
                onClose={handleOnClose}
                options={filteredSecurities}
                leftHeader="Ticker"
                leftValueKey="ticker"
                rightHeader="Issuer"
                rightValueKey="issuerName"
                searchTerm={searchTerm}
                toCustomer={false}
              />
            )}
            {(activeTab === "all" || activeTab === "customers") && (
              <>
                <SearchResults
                  sx={{
                    mt:
                      activeTab === "all" &&
                      filteredSecurities &&
                      filteredSecurities?.length > 0
                        ? 9
                        : 0,
                  }}
                  isLoading={isCustomerFetching}
                  onClose={handleOnClose}
                  options={customersSearch?.emails}
                  leftHeader="Email"
                  leftValueKey="matchingValue"
                  rightHeader="Customer"
                  rightValueKey="firstName"
                  searchTerm={searchTerm}
                />
                <SearchResults
                  sx={{ mt: 9 }}
                  isLoading={isCustomerFetching}
                  onClose={handleOnClose}
                  options={customersSearch?.customerIdentifiers}
                  leftHeader="ID"
                  leftValueKey="matchingValue"
                  rightHeader="Customer"
                  rightValueKey="firstName"
                  searchTerm={searchTerm}
                />
              </>
            )}
            {activeTab === "all" && (
              <Box sx={{ mt: 9, textAlign: "center" }}>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  Can&apos;t find what you&apos;re looking for?
                </Text>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  Try typing the full email address, customer ID or company
                  names.
                </Text>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  You can also adjust the filters on the top to narrow down your
                  search.
                </Text>
              </Box>
            )}
            {activeTab === "issuers" && (
              <Box sx={{ mt: 9, textAlign: "center" }}>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  If you don&apos;t see what you&apos;re looking for,
                </Text>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  try typing the full company name or CUSIP.
                </Text>
              </Box>
            )}
            {activeTab === "customers" && (
              <Box sx={{ mt: 9, textAlign: "center" }}>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  If you don&apos;t see what you&apos;re looking for,
                </Text>
                <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS }}>
                  try typing the full email address or customer ID.
                </Text>
              </Box>
            )}
          </Box>
        ))}
    </Drawer>
  );
}
