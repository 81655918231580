import { BaseRowType, ColumnType, Row } from "@asayinc/component-library";

import { VOTE_STATUS_LABEL } from "src/constants/labels";
import { ListCorporateActionCommunication } from "src/store/corporateAction/types";

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
};

export type ColumnData = {
  broker: ColumnType;
  customerName?: ColumnType;
  customerEmail?: ColumnType;
  customerId: ColumnType;
  election?: ColumnType;
};

export function getColumns(
  isPiiFreeBroker: boolean,
  showElectionColumn: boolean
) {
  const columns = isPiiFreeBroker
    ? ["broker", "customerId"]
    : ["broker", "customerName", "customerEmail", "customerId"];

  if (showElectionColumn) {
    columns.push("election");
  }

  return columns;
}

export function getColumnData(
  isPiiFreeBroker: boolean,
  showElectionColumn: boolean,
  { getSort, onOrderingChange }: ColumnDataHelpers
): ColumnData {
  const columnData: ColumnData = {
    broker: {
      sort: getSort("broker__name"),
      sortFn: (data: unknown) => onOrderingChange(data, "broker__name"),
      style: "text",
      title: "Broker",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
      width: "120px",
    },
    customerId: {
      sort: getSort("customer__customerIdentifier"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "customer__customerIdentifier"),
      style: "text",
      title: "Customer Id",
    },
  };

  if (!isPiiFreeBroker) {
    columnData.customerEmail = {
      sort: getSort("customer__email"),
      sortFn: (data: unknown) => onOrderingChange(data, "customer__email"),
      style: "text",
      title: "Email",
    };
    columnData.customerName = {
      style: "text",
      title: "Customer Name",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
    };
  }

  if (showElectionColumn) {
    columnData.election = {
      sort: getSort("election_status"),
      sortFn: (data: unknown) => onOrderingChange(data, "election_status"),
      style: "text",
      title: "Election",
      width: "120px",
    };
  }

  return columnData;
}

export function getRowData(
  isPiiFreeBroker: boolean,
  showElectionColumn: boolean,
  communications: ListCorporateActionCommunication[],
  onRowClick: (communication: ListCorporateActionCommunication) => void,
  selectedRow: ListCorporateActionCommunication | null | undefined
): Row[] {
  return communications.map((communication, idx) => {
    const handleRowClick = (data: unknown) => {
      const { communication } = data as {
        communication: ListCorporateActionCommunication;
        idx: string;
      };
      onRowClick(communication);
    };

    const {
      id,
      broker: { logo },
      customer: { email, customerIdentifier, firstName, lastName },
      electionStatus,
    } = communication;

    const rowData: {
      [key: string]: BaseRowType;
    } = {
      broker: {
        style: "image",
        content: logo || "-",
        clickHandler: handleRowClick,
      },
      customerId: {
        content: customerIdentifier || "-",
        clickHandler: handleRowClick,
      },
    };

    if (!isPiiFreeBroker) {
      rowData.customerName = {
        content: !firstName && !lastName ? "-" : `${firstName} ${lastName}`,
        clickHandler: handleRowClick,
      };

      rowData.customerEmail = {
        content: email || "-",
        clickHandler: handleRowClick,
      };
    }

    if (showElectionColumn) {
      rowData.election = {
        content: VOTE_STATUS_LABEL[electionStatus],
        clickHandler: handleRowClick,
      };
    }

    return {
      id: id,
      data: { communication, idx },
      rowData,
      highlighted: selectedRow?.id === communication.id,
    };
  });
}
