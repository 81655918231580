import { TableV2 as Table } from "@asayinc/component-library";
import { FilterList } from "@mui/icons-material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import { ROW_OPTIONS, CORPORATE_ACTION_OPTIONS } from "src/constants/options";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { MailingView } from "src/pages/Mailings";
import { useCorporateActionEventsQuery } from "src/store/corporateAction";
import { getColumnData, getColumns, getRowData } from "./utils";
import { EmptyState } from "src/components/molecules/EmptyState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";

export function CorporateAction() {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const methods = useForm();
  const { type } = useTableSearchParams();
  const view = searchParams.get(TABLE_SEARCH_PARAMS.VIEW) as MailingView;

  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();

  const { data, isFetching, isLoading } = useCorporateActionEventsQuery({
    offset,
    limit,
    ordering,
    type,
    view,
    issuerId: id,
  });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const columns = getColumns();
  const columnData = useMemo(() => {
    const handleRowClick = (rowData: unknown) => {
      const { id } = rowData as { id: string; idx: number };
      navigate(`/mailings/corporate-action/${id}/offer-info`);
    };
    return getColumnData({
      getSort,
      onOrderingChange,
      onRowClick: handleRowClick,
    });
  }, [getSort, onOrderingChange, navigate]);
  const rowData = getRowData(data?.results || []);

  const filterList = (
    <FormProvider {...methods}>
      <TableMultiSelectFilter
        fieldName={TABLE_SEARCH_PARAMS.TYPE}
        label="Types"
        options={CORPORATE_ACTION_OPTIONS}
        queryParameter={TABLE_SEARCH_PARAMS.TYPE}
      />
    </FormProvider>
  );

  if (data?.count === 0 && !type) {
    return (
      <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
        <EmptyState
          title="No mailings to display yet."
          subtitle="When this issuer has voluntary corporate actions mailings, they will appear here."
        />
      </ScrollContainer>
    );
  }

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Table
        count={data?.count || 0}
        columnData={columnData}
        columns={columns}
        isFetching={isFetching}
        isLoading={isLoading}
        noResultsData={{
          title: "No corporate actions found",
          subtitle:
            "Sorry, there are no mailings that match your applied filters.",
        }}
        paginateProps={paginateProps}
        rows={rowData}
        tableMinWidth={1100}
        title={`${
          data?.count || 0
        } voluntary corporate action mailings from the past year`}
        titleVariant="subtitle2"
        toolbarInside
        toolbarCollapse={{
          Icon: FilterList,
          collapseContent: filterList,
          isFlat: true,
        }}
      />
    </ScrollContainer>
  );
}
