import { Text } from "@asayinc/component-library";
import { Box, Stack, SxProps } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

import { Customer } from "src/store/customer/types";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface ICustomerHeaderProps {
  customer?: Customer;
  sx?: SxProps;
}

export function CustomerHeader({ customer, sx }: ICustomerHeaderProps) {
  const isPiiFreeBroker = useIsPiiFreeBroker();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={sx}
    >
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <img
          src={customer?.broker?.logo || undefined}
          alt="Broker logo"
          height="24px"
        />
      </Box>
      <AccountCircle sx={{ mt: 4 }} fontSize="large" />
      <Text
        sx={{ mt: 3 }}
        variant="h5"
        loadingProps={{ sx: { width: "300px" } }}
      >
        {customer ? `${customer.firstName} ${customer.lastName}` : undefined}
      </Text>
      {customer ? (
        <Text sx={{ mt: 3 }} variant="body1">
          {!isPiiFreeBroker && (
            <>
              Email: <b>{customer.email}</b> ·{" "}
            </>
          )}
          Customer ID: <b>{customer.customerIdentifier}</b>
        </Text>
      ) : (
        <Text
          sx={{ mt: 3 }}
          variant="body1"
          loadingProps={{ sx: { width: "700px" } }}
        >
          {undefined}
        </Text>
      )}
    </Stack>
  );
}
