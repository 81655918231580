import { getAuthToken } from "@asayinc/component-library";
import { Navigate } from "react-router-dom";

export function HomePage() {
  const authToken = getAuthToken();

  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  return <Navigate replace to="/mailings/proxy" state={{ view: "approved" }} />;
}
