import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithCamelize } from "../apiTools";

export const rootApi = createApi({
  baseQuery: baseQueryWithCamelize,
  reducerPath: "rootApi",
  tagTypes: [
    "corporateActionEvents",
    "corporateActionEventsAggregate",
    "corporateActionEventCommunication",
    "corporateActionEventCommunications",
    "corporateActionEventNotification",
    "corporateActionEventNotifications",
    "corporateActionNotificationPreview",
    "customers",
    "customerCorporateActionCommunications",
    "customerCorporateActionNotifications",
    "customerProxyCommunications",
    "customerProxyNotifications",
    "globalSearchCustomers",
    "globalSearchSecurities",
    "issuer",
    "postSaleProspectusEventsAggregate",
    "postSaleProspectusEvents",
    "proxyEvents",
    "proxyEventsAggregate",
    "proxyEventCustomers",
    "proxyEventCommunications",
    "proxyEventCommunication",
    "proxyEventNotifications",
    "proxyEventNotification",
    "proxyEventNotificationPreview",
    "user",
  ],
  endpoints: () => ({}),
});
