import debounce from "lodash.debounce";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchFns = (queryParam = "search") => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const didMount = useRef<boolean>(false);

  const triggerSearch = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (searchTerm) {
          searchParams.set(queryParam, searchTerm);
          searchParams.set("page", "1");
          setSearchParams(searchParams);
        }
      }, 150),
    [queryParam, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (didMount.current) {
      triggerSearch(searchTerm);
    } else {
      didMount.current = true;
    }
  }, [searchTerm, triggerSearch]);

  const clearSearch = () => {
    searchParams.delete(queryParam);
    searchParams.set("page", "1");
    setSearchTerm("");
    setSearchParams(searchParams);
  };

  const updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return {
    updateSearch,
    clearSearch,
    searchTerm,
  };
};
