import { SxProps } from "@mui/material";
import { Card } from "src/components/atoms/Card";
import { CORPORATE_ACTION_LABEL } from "src/constants/labels";
import { Offer as OfferType } from "src/store/corporateAction/types";
import { Ballot } from "../Ballot";

interface IOfferCardProps {
  offer: OfferType;
  sx?: SxProps;
}

export function OfferCard({ offer, sx }: IOfferCardProps) {
  const { choices, detail, title, type } = offer;
  return (
    <Card title="Offer" testId="corporate-action-offer-card" sx={sx}>
      <Ballot
        choices={choices}
        details={detail}
        title={title}
        type={CORPORATE_ACTION_LABEL[type]}
      />
    </Card>
  );
}
