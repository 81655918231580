import { useSearchParams } from "react-router-dom";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";

export function useTableSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const approvedAt = searchParams.get(TABLE_SEARCH_PARAMS.APPROVED_AT);
  const electionStatus = searchParams.get(TABLE_SEARCH_PARAMS.ELECTION_STATUS);
  const search = searchParams.get(TABLE_SEARCH_PARAMS.SEARCH);
  const status = searchParams.get(TABLE_SEARCH_PARAMS.STATUS);
  const type = searchParams.get(TABLE_SEARCH_PARAMS.TYPE);
  const view = searchParams.get(TABLE_SEARCH_PARAMS.VIEW);
  const voteStatus = searchParams.get(TABLE_SEARCH_PARAMS.VOTE_STATUS);

  const handleClearTableSearchParams = () => {
    searchParams.delete(TABLE_SEARCH_PARAMS.APPROVED_AT);
    searchParams.delete(TABLE_SEARCH_PARAMS.ELECTION_STATUS);
    searchParams.delete(TABLE_SEARCH_PARAMS.SEARCH);
    searchParams.delete(TABLE_SEARCH_PARAMS.STATUS);
    searchParams.delete(TABLE_SEARCH_PARAMS.TYPE);
    searchParams.delete(TABLE_SEARCH_PARAMS.VIEW);
    searchParams.delete(TABLE_SEARCH_PARAMS.VOTE_STATUS);
    setSearchParams(searchParams);
  };

  return {
    approvedAt,
    electionStatus,
    search,
    status,
    type,
    view,
    voteStatus,
    clearTableSearchParams: handleClearTableSearchParams,
  };
}
