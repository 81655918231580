export const ELECTION_STATUS_LABEL: { [key in ElectionStatus]: string } = {
  not_viewed: "Not Viewed",
  viewed: "Viewed",
  withdrawn: "Withdrawn",
  online: "Online",
  legal_proxy: "Legal Proxy",
};

export type ElectionStatus =
  | "not_viewed"
  | "viewed"
  | "withdrawn"
  | "online"
  | "legal_proxy";
