export const TABLE_SEARCH_PARAMS = {
  APPROVED_AT: "approvedAt",
  ELECTION_STATUS: "electionStatus",
  LIMIT: "limit",
  ORDERING: "ordering",
  PAGE: "page",
  SEARCH: "search",
  SENT_AT: "sent_at",
  STATUS: "status",
  TYPE: "type",
  VIEW: "view", // "approved" | "upcoming" || "proxy" | "corporate-action"
  VOTE_STATUS: "voteStatus",
};
