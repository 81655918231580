import { IPaginationResponse, Preview } from "../types";
import {
  IAggregateResponse,
  ICorporateActionEventsAggregateParams,
  CorporateAction,
  ICorporateActionEventsParams,
  CorporateActionCommunication,
  CorporateActionNotification,
  ICorporateActionsParams,
} from "./types";
import { rootApi } from "../rootApi";

export const corporateActionApis = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    corporateActionEvents: builder.query<
      IPaginationResponse<CorporateAction>,
      ICorporateActionEventsParams
    >({
      query: ({
        limit,
        ordering,
        offset,
        type,
        view,
        issuerId,
        approvedAfter,
      }) => ({
        url: "corporate-action-events/",
        params: {
          limit,
          ordering,
          offset,
          operations_status: view || undefined,
          type: type || undefined,
          issuer_id: issuerId || undefined,
          approved_at__gte: approvedAfter || undefined,
        },
      }),
      providesTags: ["corporateActionEvents"],
    }),
    corporateActionEvent: builder.query<CorporateAction, { id: string }>({
      query: ({ id }) => ({
        url: `corporate-action-events/${id}/`,
      }),
      providesTags: (_result, _, args) => [
        { type: "corporateActionEvents", id: args.id },
      ],
    }),
    corporateActionEventsAggregate: builder.query<
      IAggregateResponse,
      ICorporateActionEventsAggregateParams
    >({
      query: ({ type, view, approvedAfter }) => ({
        url: "corporate-action-events/aggregate/",
        params: {
          operations_status: view || undefined,
          type: type || undefined,
          approved_at__gte: approvedAfter || undefined,
        },
      }),
      providesTags: ["corporateActionEventsAggregate"],
    }),
    corporateActionEventCommunications: builder.query<
      IPaginationResponse<CorporateActionCommunication>,
      ICorporateActionsParams
    >({
      query: ({ id, limit, ordering, offset, electionStatus, search }) => ({
        url: `corporate-action-events/${id}/communications/`,
        params: {
          limit,
          ordering,
          offset,
          election_status: electionStatus || undefined,
          search: search || undefined,
        },
      }),
      providesTags: (_result, _, arg) => [
        { type: "corporateActionEventCommunications", id: arg.id },
      ],
    }),
    corporateActionEventCommunication: builder.query<
      CorporateActionCommunication,
      ICorporateActionsParams
    >({
      query: ({ corporateActionId, communicationId }) => ({
        url: `corporate-action-events/${corporateActionId}/communications/${communicationId}/`,
        params: {},
      }),
      providesTags: (_result, _, arg) => [
        { type: "corporateActionEventCommunication", id: arg.communicationId },
      ],
    }),
    corporateActionEventNotifications: builder.query<
      IPaginationResponse<CorporateActionNotification>,
      ICorporateActionsParams
    >({
      query: ({
        id,
        limit,
        ordering,
        offset,
        type,
        status,
        search,
        sentAt,
        sentBefore,
        sentAfter,
      }) => ({
        url: `corporate-action-events/${id}/notifications/`,
        params: {
          limit,
          ordering,
          offset,
          type: type || undefined,
          status: status || undefined,
          search: search || undefined,
          sent_at: sentAt || undefined,
          sent_at__lte: sentBefore || undefined,
          sent_at__gte: sentAfter || undefined,
        },
      }),
      providesTags: (_result, _, arg) => [
        { type: "corporateActionEventNotifications", id: arg.id },
      ],
    }),
    corporateActionEventNotification: builder.query<
      CorporateActionNotification,
      ICorporateActionsParams
    >({
      query: ({ corporateActionId, notificationId }) => ({
        url: `corporate-action-events/${corporateActionId}/notifications/${notificationId}/`,
      }),
      providesTags: (_result, _, arg) => [
        { type: "corporateActionEventNotification", id: arg.notificationId },
      ],
    }),
    corporateActionEventNotificationPreview: builder.query<
      Preview,
      ICorporateActionsParams
    >({
      query: ({ corporateActionId, notificationId }) => ({
        url: `corporate-action-events/${corporateActionId}/notifications/${notificationId}/render/`,
      }),
      providesTags: (_result, _, arg) => [
        { type: "corporateActionNotificationPreview", id: arg.notificationId },
      ],
    }),
    resendCorporateActionNotification: builder.mutation<
      CorporateActionNotification,
      ICorporateActionsParams
    >({
      query: ({ corporateActionId, notificationId }) => ({
        url: `corporate-action-events/${corporateActionId}/notifications/${notificationId}/resend/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _, arg) => [
        {
          type: "corporateActionEventNotifications",
          id: arg.corporateActionId,
        },
        "corporateActionEventCommunication",
      ],
    }),
  }),
});

export const {
  useCorporateActionEventQuery,
  useCorporateActionEventsQuery,
  useCorporateActionEventsAggregateQuery,
  useCorporateActionEventCommunicationQuery,
  useCorporateActionEventCommunicationsQuery,
  useCorporateActionEventNotificationsQuery,
  useCorporateActionEventNotificationQuery,
  useCorporateActionEventNotificationPreviewQuery,
  useResendCorporateActionNotificationMutation,
} = corporateActionApis;
