import { Text } from "@asayinc/component-library";
import { Box, Stack, SxProps } from "@mui/material";

interface IEmptyStateProps {
  isGray?: boolean;
  subtitle?: string;
  showNoResults?: boolean;
  sx?: SxProps;
  title?: string;
}

export function EmptyState({
  isGray = false,
  subtitle,
  showNoResults,
  sx,
  title,
}: IEmptyStateProps) {
  const imagePath = showNoResults
    ? "empty_no_results"
    : isGray
    ? "empty_general_gray"
    : "empty_general";
  return (
    <Stack sx={sx} data-testid="empty-state" flexGrow={1}>
      <Stack
        direction="column"
        sx={{
          my: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          width={400}
          src={`${process.env.REACT_APP_ASSETS}images/broker-portal/${imagePath}.svg`}
        />
        {(title || subtitle) && (
          <Box sx={{ mt: 6, textAlign: "center" }}>
            {title && (
              <Text data-testid="empty-state-title" variant="subtitle1">
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                sx={{ mt: title ? 3 : "none" }}
                data-testid="empty-state-subtitle"
                variant="body1"
              >
                {subtitle}
              </Text>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
