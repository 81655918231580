import { ColumnType, Row } from "@asayinc/component-library";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

import { CorporateAction } from "src/store/corporateAction/types";
import { CORPORATE_ACTION_LABEL } from "src/constants/labels";
import { maxDate, minDate } from "src/utils/compareDates";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
  onRowClick: (data: unknown) => void;
};

export type ColumnData = {
  type: ColumnType;
  scheduledStart: ColumnType;
  scheduledFinish: ColumnType;
  customers: ColumnType;
  elected: ColumnType;
  fails: ColumnType;
};

export function getColumns() {
  return [
    "type",
    "scheduledStart",
    "scheduledFinish",
    "customers",
    "elected",
    "fails",
  ];
}

export function getColumnData({
  getSort,
  onOrderingChange,
  onRowClick,
}: ColumnDataHelpers): ColumnData {
  return {
    type: {
      clickHandler: onRowClick,
      sort: getSort("type"),
      sortFn: (data: unknown) => onOrderingChange(data, "type"),
      style: "text",
      title: "Type",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
      width: "28.5%",
    },
    scheduledStart: {
      clickHandler: onRowClick,
      sort: getSort("initial_start_scheduled_send_at"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "initial_start_scheduled_send_at"),
      style: "text",
      title: "Scheduled start",
    },
    scheduledFinish: {
      clickHandler: onRowClick,
      sort: getSort("initial_end_scheduled_send_at"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "initial_end_scheduled_send_at"),
      style: "text",
      title: "Scheduled finish",
    },
    customers: {
      clickHandler: onRowClick,
      sort: getSort("unique_customer_count"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "unique_customer_count"),
      style: "text",
      title: "Customers",
    },
    elected: {
      clickHandler: onRowClick,
      sort: getSort("vote_count"),
      sortFn: (data: unknown) => onOrderingChange(data, "vote_count"),
      style: "text",
      title: "Elected",
    },
    fails: {
      clickHandler: onRowClick,
      sort: getSort("efailure_count"),
      sortFn: (data: unknown) => onOrderingChange(data, "efailure_count"),
      style: "text",
      title: "Fails",
    },
  };
}

export function getRowData(mailings: CorporateAction[]): Row[] {
  return mailings.map((mailing, idx) => {
    const {
      id,
      stats: {
        initialStartScheduledSendAt,
        initialWebhookStartScheduledSendAt,
        initialEndScheduledSendAt,
        initialWebhookEndScheduledSendAt,
        electionCount,
        initialErrorCount,
        uniqueCustomerCount,
      },
      type,
    } = mailing;

    const scheduledStart = minDate(
      initialStartScheduledSendAt,
      initialWebhookStartScheduledSendAt
    );

    const scheduledEnd = maxDate(
      initialEndScheduledSendAt,
      initialWebhookEndScheduledSendAt
    );

    return {
      id,
      data: { id, idx },
      rowData: {
        type: { content: CORPORATE_ACTION_LABEL[type] },
        scheduledStart: {
          content: scheduledStart
            ? dayjs.utc(scheduledStart).local().fromNow()
            : "-",
        },
        scheduledFinish: {
          content: scheduledEnd
            ? dayjs.utc(scheduledEnd).format("YYYY-MM-DD")
            : "-",
        },
        customers: { content: uniqueCustomerCount?.toLocaleString() || 0 },
        elected: { content: electionCount?.toLocaleString() || 0 },
        fails: { content: initialErrorCount?.toLocaleString() || 0 },
      },
    };
  });
}
