import { Card as SayCard, COLORS, Text } from "@asayinc/component-library";
import { SxProps } from "@mui/material";

interface ICardProps {
  children?: React.ReactNode;
  sx?: SxProps;
  testId?: string;
  title: string | undefined;
}

export function Card({ children, sx, testId, title }: ICardProps) {
  return (
    <SayCard
      data-testid={testId}
      sx={{ p: 8, overflow: "initial", ...sx }}
      hasHoverBg={false}
      style={{ backgroundColor: COLORS.WHITE }}
      elevation={5}
    >
      <Text
        sx={{ mb: 4 }}
        variant="subtitle2"
        loadingProps={{ sx: { width: "50%" } }}
      >
        {title}
      </Text>
      {children}
    </SayCard>
  );
}
