import { ProxyType } from "src/store/proxy/types";

export const PROXY_LABEL: {
  [key in ProxyType]: string;
} = {
  consent_solicitation: "Consent Solicitation",
  information_statement: "Information Statement",
  contested_annual_meeting: "Contested Annual Meeting",
  annual_meeting: "Annual Meeting",
  contested_special_meeting: "Contested Special Meeting",
  special_meeting: "Special Meeting",
  summary_prospectus: "Summary Prospectus",
  "19_a_notice": "19 A Notice",
  annual_report: "Annual Report",
  semi_annual_report: "Semi Annual Report",
};
