import { BaseRowType, ColumnType, Row } from "@asayinc/component-library";

import {
  CORPORATE_ACTION_NOTIFICATION_LABEL,
  PROXY_NOTIFICATION_LABEL,
} from "src/constants/labels";
import { ListNotification } from "src/store/types";
import { formatDate } from "src/utils/formatDate";
import { getNotificationStatusTagData } from "src/utils/getNotificationStatusTagData";

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
};

export type ColumnData = {
  type: ColumnType;
  status: ColumnType;
  email?: ColumnType;
  customerId?: ColumnType;
  sent: ColumnType;
};

export function getColumns(isPiiFreeBroker: boolean) {
  if (isPiiFreeBroker) {
    return ["type", "status", "customerId", "sent"];
  }

  return ["type", "status", "email", "sent"];
}

export function getColumnData(
  isPiiFreeBroker: boolean,
  { getSort, onOrderingChange }: ColumnDataHelpers
): ColumnData {
  const columnData: ColumnData = {
    type: {
      sort: getSort("type"),
      sortFn: (data: unknown) => onOrderingChange(data, "type"),
      style: "text",
      title: "Type",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
    },
    status: {
      sort: getSort("status"),
      sortFn: (data: unknown) => onOrderingChange(data, "status"),
      title: "Status",
      width: "188px",
    },
    sent: {
      sort: getSort("sent_at"),
      sortFn: (data: unknown) => onOrderingChange(data, "sent_at"),
      style: "text",
      title: "Sent",
    },
  };

  if (isPiiFreeBroker) {
    columnData.customerId = {
      sort: getSort("customer__customerIdentifier"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "customer__customerIdentifier"),
      style: "text",
      title: "Customer Id",
    };
  } else {
    columnData.email = {
      sort: getSort("customer__email"),
      sortFn: (data: unknown) => onOrderingChange(data, "customer__email"),
      style: "text",
      title: "Email",
    };
  }

  return columnData;
}

export function getRowData(
  isPiiFreeBroker: boolean,
  notifications: ListNotification[],
  onRowClick: (notification: ListNotification) => void,
  selectedNotification: ListNotification | null | undefined
): Row[] {
  return notifications.map((notification, idx) => {
    const {
      id,
      customer: { email, customerIdentifier },
      proxyEvent,
      status,
      sentAt,
      type,
    } = notification;
    const handleRowClick = () => {
      onRowClick(notification);
    };
    const rowData: {
      [key: string]: BaseRowType;
    } = {
      type: {
        content: proxyEvent
          ? PROXY_NOTIFICATION_LABEL[type]
          : CORPORATE_ACTION_NOTIFICATION_LABEL[type] || "-",
        clickHandler: handleRowClick,
      },
      status: {
        style: "tag",
        tagCellData: getNotificationStatusTagData(status),
        clickHandler: handleRowClick,
      },
      sent: {
        content: sentAt ? formatDate(sentAt, true, false) : "-",
        clickHandler: handleRowClick,
      },
    };

    if (isPiiFreeBroker) {
      rowData.customerId = {
        content: customerIdentifier || "-",
        clickHandler: handleRowClick,
      };
    } else {
      rowData.email = {
        content: email || "-",
        clickHandler: handleRowClick,
      };
    }

    return {
      id: id,
      data: { notification, idx },
      rowData,
      highlighted: selectedNotification?.id === notification.id,
    };
  });
}
