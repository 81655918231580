export function getPercentage(
  numerator: number,
  denominator: number,
  percision = 2
): string {
  if (denominator <= 0) {
    return "0";
  }

  return ((numerator / denominator) * 100).toFixed(percision);
}
