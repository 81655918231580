import { TableV2 as Table } from "@asayinc/component-library";
import { useMemo } from "react";

import { ROW_OPTIONS } from "src/constants/options";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useProxyEventCustomersQuery } from "src/store/proxy";
import { IProxyCustomer } from "src/store/proxy/types";
import { getColumnData, getColumns, getRowData } from "./utils";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface IProxyCustomersTable {
  onRowClick: (customer: IProxyCustomer) => void;
  proxyId: string;
  selectedCustomer?: IProxyCustomer | null;
}

export function ProxyCustomersTable({
  onRowClick,
  proxyId,
  selectedCustomer,
}: IProxyCustomersTable) {
  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const { data, isFetching, isLoading } = useProxyEventCustomersQuery({
    id: proxyId,
    limit,
    ordering,
    offset,
  });
  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };
  const isPiiFreeBroker = useIsPiiFreeBroker();
  const columns = useMemo(() => getColumns(isPiiFreeBroker), [isPiiFreeBroker]);
  const columnData = useMemo(
    () =>
      getColumnData({
        getSort,
        onOrderingChange,
      }),
    [getSort, onOrderingChange]
  );
  const rowData = getRowData(data?.results || [], onRowClick, selectedCustomer);

  return (
    <Table
      testid="proxy-customers-table"
      count={data?.count || 0}
      columnData={columnData}
      columns={columns}
      isFetching={isFetching}
      isLoading={isLoading}
      noResultsData={{
        title: "No customers found",
        subtitle:
          "Sorry, there are no customers that match your applied filters.",
      }}
      paginateProps={paginateProps}
      rows={rowData}
      tableMinWidth={1200}
      title="Customers"
      titleVariant="subtitle2"
      toolbarInside
    />
  );
}
