import { TableV2 as Table } from "@asayinc/component-library";
import { FilterList } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { EmptyState } from "src/components/molecules/EmptyState";
import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import {
  ROW_OPTIONS,
  CORPORATE_ACTION_OPTIONS,
  ELECTION_STATUS_OPTIONS,
} from "src/constants/options";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useSearchFns } from "src/hooks/useSearchFns";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { useCustomerCorporateActionCommunicationsQuery } from "src/store/customer";
import { ListCommunication } from "src/store/types";
import { getColumnData, getColumns, getRowData } from "./utils";

interface ICustomerCorporateActionCommunicationsTableProps {
  customerId: string;
  onRowClick: (communication: ListCommunication) => void;
  selectedCommunication?: ListCommunication | null;
}

export function CustomerCorporateActionCommunicationsTable({
  customerId,
  onRowClick,
  selectedCommunication,
}: ICustomerCorporateActionCommunicationsTableProps) {
  const methods = useForm();
  const { electionStatus, search, type } = useTableSearchParams();

  const {
    getSort,
    ordering,
    offset,
    limit,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const searchFns = useSearchFns();

  const { data, isFetching, isLoading } =
    useCustomerCorporateActionCommunicationsQuery({
      id: customerId,
      limit,
      ordering,
      offset,
      type,
      electionStatus,
      search,
    });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const columns = useMemo(() => getColumns(), []);
  const columnData = useMemo(
    () => getColumnData({ getSort, onOrderingChange }),
    [getSort, onOrderingChange]
  );
  const rowData = getRowData(
    data?.results || [],
    onRowClick,
    selectedCommunication
  );

  const filterList = (
    <Stack direction="row-reverse" gap={4} mt={2} pb={4} width="100%">
      <FormProvider {...methods}>
        <TableMultiSelectFilter
          fieldName={TABLE_SEARCH_PARAMS.TYPE}
          label="Type"
          options={CORPORATE_ACTION_OPTIONS}
          queryParameter={TABLE_SEARCH_PARAMS.TYPE}
        />
        <TableMultiSelectFilter
          fieldName={TABLE_SEARCH_PARAMS.ELECTION_STATUS}
          label="Election"
          options={ELECTION_STATUS_OPTIONS}
          queryParameter={TABLE_SEARCH_PARAMS.ELECTION_STATUS}
        />
      </FormProvider>
    </Stack>
  );

  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search by ticker, issuer",
  };

  if (data?.count === 0 && !type && !electionStatus && !search) {
    return (
      <EmptyState
        title="This user has not been eligible to participate in any voluntary corporate actions."
        subtitle="When this user is eligible to participate in a voluntary corporate action, the event will appear here."
      />
    );
  }

  return (
    <Table
      testid="customer-corporate-action-communications-table"
      count={data?.count || 0}
      columnData={columnData}
      columns={columns}
      isFetching={isFetching}
      isLoading={isLoading}
      noResultsData={{
        title: "No communications found",
        subtitle:
          "Sorry, there are no communications that match your applied filters.",
      }}
      paginateProps={paginateProps}
      rows={rowData}
      searchBarProps={searchBarProps}
      tableMinWidth={800}
      title={`${data?.count} Voluntary corporate actions`}
      titleVariant="subtitle2"
      toolbarInside
      toolbarCollapse={{
        Icon: FilterList,
        collapseContent: filterList,
      }}
    />
  );
}
