import { TableV2 as Table } from "@asayinc/component-library";
import { FilterList } from "@mui/icons-material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import { ROW_OPTIONS } from "src/constants/options";
import { VOTE_STATUS_OPTIONS } from "src/constants/options/voteStatusOptions";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useSearchFns } from "src/hooks/useSearchFns";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { useProxyEventCommunicationsQuery } from "src/store/proxy";
import { ListProxyCommunication } from "src/store/proxy/types";
import { getColumnData, getColumns, getRowData } from "./utils";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface IProxyCommunicationsTable {
  onRowClick: (communication: ListProxyCommunication) => void;
  proxyId: string;
  selectedCommunication?: ListProxyCommunication | null;
  showVotedColumn?: boolean;
  title?: string;
}

export function ProxyCommunicationsTable({
  onRowClick,
  proxyId,
  selectedCommunication,
  showVotedColumn = true,
  title = "Communications",
}: IProxyCommunicationsTable) {
  const methods = useForm();
  const { search, voteStatus } = useTableSearchParams();
  const isPiiFreeBroker = useIsPiiFreeBroker();

  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const searchFns = useSearchFns();
  const { data, isFetching, isLoading } = useProxyEventCommunicationsQuery({
    id: proxyId,
    limit,
    ordering,
    offset,
    voteStatus,
    search,
  });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const columns = useMemo(
    () => getColumns(showVotedColumn, isPiiFreeBroker),
    [showVotedColumn, isPiiFreeBroker]
  );
  const columnData = useMemo(
    () =>
      getColumnData(showVotedColumn, isPiiFreeBroker, {
        getSort,
        onOrderingChange,
      }),
    [getSort, onOrderingChange, showVotedColumn, isPiiFreeBroker]
  );
  const rowData = getRowData(
    showVotedColumn,
    isPiiFreeBroker,
    data?.results || [],
    onRowClick,
    selectedCommunication
  );

  const filterList = (
    <FormProvider {...methods}>
      {showVotedColumn && (
        <TableMultiSelectFilter
          fieldName={TABLE_SEARCH_PARAMS.VOTE_STATUS}
          label="Voted"
          options={VOTE_STATUS_OPTIONS}
          queryParameter={TABLE_SEARCH_PARAMS.VOTE_STATUS}
        />
      )}
    </FormProvider>
  );

  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search by name, email",
  };

  return (
    <Table
      testid="proxy-communications-table"
      count={data?.count || 0}
      columnData={columnData}
      columns={columns}
      isFetching={isFetching}
      isLoading={isLoading}
      noResultsData={{
        title: "No communications found",
        subtitle:
          "Sorry, there are no communications that match your applied filters.",
      }}
      paginateProps={paginateProps}
      rows={rowData}
      searchBarProps={searchBarProps}
      tableMinWidth={1300}
      title={title}
      titleVariant="subtitle2"
      toolbarInside
      toolbarCollapse={{
        Icon: FilterList,
        collapseContent: filterList,
        isFlat: true,
      }}
    />
  );
}
