export function minDate(date1: string | null, date2: string | null) {
  if (!date1) return date2;
  if (!date2) return date1;

  return new Date(date1) < new Date(date2) ? date1 : date2;
}

export function maxDate(date1: string | null, date2: string | null) {
  if (!date1) return date2;
  if (!date2) return date1;

  return new Date(date1) > new Date(date2) ? date1 : date2;
}
