import { CommunicationNotification } from "src/store/types";

export function getResendsCountMap(
  notifications: CommunicationNotification[],
  resendTypes: string[],
  totalResends: number
) {
  return notifications.reduce((acc, notification) => {
    if (resendTypes.includes(notification.type)) {
      acc[notification.id] = totalResends - Object.keys(acc).length;
    }
    return acc;
  }, {});
}
