import { COLORS, Text, nFormatter } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import { Card } from "src/components/atoms/Card";
import { ErrorState } from "src/components/atoms/ErrorState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { LabelField } from "src/components/molecules/LabelField";
import {
  LoadingCard,
  LoadingStatsHeaderCard,
} from "src/components/molecules/Loading";
import { useProxyEventQuery } from "src/store/proxy";
import { getPercentage } from "src/utils/getPercentage";

export function InsightsTab() {
  const { id } = useParams() as { id: string };
  const { data: proxyEvent, isLoading, error } = useProxyEventQuery({ id });

  if (error || (!isLoading && !proxyEvent)) {
    return <ErrorState />;
  }

  if (isLoading) {
    return (
      <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
        <LoadingStatsHeaderCard />
        <Box sx={{ display: "flex", mt: 8 }}>
          <Box sx={{ flexGrow: 1, flexBasis: 0, mr: 20 }}>
            <LoadingCard />
          </Box>
          <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
            <LoadingCard />
            <LoadingCard sx={{ mt: 8 }} />
          </Box>
        </Box>
      </ScrollContainer>
    );
  }

  const { stats } = proxyEvent;
  const {
    totalShares,
    communicationCount,
    fullShareCommunicationCount,
    fullShareVoteCount,
    giftedCommunicationCount,
    giftedVoteCount,
    initialClickedCount,
    initialOpenedCount,
    initialSuppressedCount,
    initialSentCount,
    initialBouncedCount,
    initialErrorCount,
    legalProxyRequestCount,
    nonFullShareCommunicationCount,
    nonFullShareVoteCount,
    voteCount,
  } = stats;
  const totalSentCustomers =
    initialSuppressedCount +
    initialSentCount +
    initialBouncedCount +
    initialErrorCount;
  const failed =
    initialSuppressedCount + initialBouncedCount + initialErrorCount;

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      {isLoading ? (
        <LoadingStatsHeaderCard />
      ) : (
        <Card title="Initial email engagement">
          <Stack sx={{ mt: 6, px: 6 }} direction="row">
            <Box sx={{ width: "25%" }}>
              <Text variant="subtitle1" sx={{ mb: 3 }}>
                Total sent customers
              </Text>
              <Text variant="h5">{totalSentCustomers.toLocaleString()}</Text>
            </Box>
            <Box sx={{ width: "25%" }}>
              <Text variant="subtitle1" sx={{ mb: 3 }}>
                Delivered
              </Text>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Text variant="h5">{initialSentCount.toLocaleString()}</Text>
                <Text
                  sx={{ ml: 2, color: COLORS.SATURATED_GREEN }}
                  variant="body2"
                >
                  {getPercentage(initialSentCount, totalSentCustomers)}%
                </Text>
              </Box>
            </Box>
            <Box sx={{ width: "25%" }}>
              <Text variant="subtitle1" sx={{ mb: 3 }}>
                Voted
              </Text>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Text variant="h5">{voteCount.toLocaleString()}</Text>
                <Text
                  sx={{ ml: 2, color: COLORS.SATURATED_GREEN }}
                  variant="body2"
                >
                  {getPercentage(voteCount, totalSentCustomers)}%
                </Text>
              </Box>
            </Box>
            <Box sx={{ width: "25%" }}>
              <Text variant="subtitle1" sx={{ mb: 3 }}>
                Failed
              </Text>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Text variant="h5">{failed.toLocaleString()}</Text>
                <Text
                  sx={{ ml: 2, color: COLORS.SATURATED_RED }}
                  variant="body2"
                >
                  {getPercentage(failed, totalSentCustomers)}%
                </Text>
              </Box>
            </Box>
          </Stack>
        </Card>
      )}
      {isLoading ? (
        <Box sx={{ display: "flex", mt: 8 }}>
          <Box sx={{ flexGrow: 1, flexBasis: 0, mr: 12 }}>
            <LoadingCard />
          </Box>
          <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
            <LoadingCard />
            <LoadingCard sx={{ mt: 8 }} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", mt: 8 }}>
          <Box sx={{ flexGrow: 1, flexBasis: 0, mr: 12 }}>
            <Card title="Position Breakdown">
              <LabelField
                sx={{
                  py: 5,
                  px: 4,
                  borderBottom: "1px solid #e0e0e0",
                  fontWeight: 600,
                }}
                isLabelBold={true}
                label="Total shares owned across all customers"
                value={`${nFormatter(totalShares, true)} shares`}
                valueTooltip={`${totalShares.toLocaleString()} shares`}
              />
              <LabelField
                sx={{
                  py: 5,
                  px: 4,
                  borderBottom: "1px solid #e0e0e0",
                }}
                label="Total number of customers"
                isLabelBold={true}
                value={`${communicationCount.toLocaleString()} customers`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Number of customers that have full share positions"
                tooltip="The number of customers that owned equal to or greater than one full share of the security as of the record date."
                value={`${fullShareCommunicationCount.toLocaleString()} (${getPercentage(
                  fullShareCommunicationCount,
                  communicationCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Number of customers that have fractional positions"
                tooltip="The number of customers that owned less than one full share of the security as of the record date."
                value={`${nonFullShareCommunicationCount.toLocaleString()} (${getPercentage(
                  nonFullShareCommunicationCount,
                  communicationCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Number of customers that have gifted positions"
                tooltip="The number of customers whose positions have been fully gifted to their account as a result of a brokerage promotion or reward."
                value={`${giftedCommunicationCount.toLocaleString()} (${getPercentage(
                  giftedCommunicationCount,
                  communicationCount
                )}%)`}
              />
            </Card>
          </Box>
          <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
            <Card title="Vote breakdown">
              <LabelField
                sx={{
                  py: 5,
                  px: 4,
                  borderBottom: "1px solid #e0e0e0",
                  fontWeight: 600,
                }}
                isLabelBold={true}
                label="Total voted customers"
                value={`${voteCount.toLocaleString()} customers`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Full share votes"
                tooltip="The number votes submitted by customers who own greater than or equal to one share."
                value={`${fullShareVoteCount.toLocaleString()} (${getPercentage(
                  fullShareVoteCount,
                  voteCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Fractional votes"
                tooltip="The number of votes submitted by customers who own less than one full share."
                value={`${nonFullShareVoteCount.toLocaleString()} (${getPercentage(
                  nonFullShareVoteCount,
                  voteCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                tooltip="The number of votes submitted by customers whose positions were gifted to their account from a broker promotion or reward."
                label="Gifted votes"
                value={`${giftedVoteCount.toLocaleString()} (${getPercentage(
                  giftedVoteCount,
                  voteCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  px: 4,
                  fontWeight: 600,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isLabelBold={true}
                label="Total legal proxy requests"
                value={`${legalProxyRequestCount} requests`}
              />
            </Card>
            <Card sx={{ mt: 8 }} title="Delivery breakdown">
              <LabelField
                sx={{
                  py: 5,
                  px: 4,
                  borderBottom: "1px solid #e0e0e0",
                  fontWeight: 600,
                }}
                isLabelBold={true}
                label="Total delivered"
                value={`${initialSentCount.toLocaleString()} emails`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Opened"
                tooltip="The number of customers that opened this initial email in their inbox once it was delivered."
                value={`${initialOpenedCount.toLocaleString()} (${getPercentage(
                  initialOpenedCount,
                  initialSentCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 9,
                  borderBottom: "1px solid #e0e0e0",
                }}
                isValueBold={false}
                label="Clicked"
                tooltip="The number of customers that clicked on something inside the email after it was delivered and opened."
                value={`${initialClickedCount.toLocaleString()} (${getPercentage(
                  initialClickedCount,
                  initialSentCount
                )}%)`}
              />
              <LabelField
                sx={{
                  py: 5,
                  px: 4,
                  borderBottom: "1px solid #e0e0e0",
                  fontWeight: 600,
                }}
                isLabelBold={true}
                label="Total failed"
                value={`${failed.toLocaleString()} emails`}
              />
            </Card>
          </Box>
        </Box>
      )}
    </ScrollContainer>
  );
}
