import { Stack, SxProps } from "@mui/material";
import { ReactNode } from "react";

interface IPageContainer {
  children?: ReactNode;
  sx?: SxProps;
}

export function PageContainer({ children, sx }: IPageContainer) {
  return (
    <Stack sx={{ flexGrow: 1, overflow: "hidden", height: "100vh", ...sx }}>
      {children}
    </Stack>
  );
}
