import {
  ButtonV2,
  Dialog,
  IconButton,
  Tabs,
  Text,
} from "@asayinc/component-library";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";

import { ErrorState } from "src/components/atoms/ErrorState";
import { MeetingDetailsCard } from "src/components/molecules/MeetingDetailsCard";
import { ProposalsCard } from "src/components/molecules/ProposalsCard";
import {
  PROXY_COMMUNICATION_DETAILS_TABS,
  CommunicationDetailsTabs,
} from "src/constants/tabs";
import {
  useProxyEventCommunicationQuery,
  useProxyEventQuery,
  useResetMeetingAttendanceMutation,
} from "src/store/proxy";
import { CommunicationHeader } from "src/components/molecules/CommunicationHeader";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { PositionCard } from "src/components/molecules/PositionCard";
import { LabelField } from "src/components/molecules/LabelField";
import { PROXY_LABEL, VOTE_STATUS_LABEL } from "src/constants/labels";
import { EmptyState } from "src/components/molecules/EmptyState";
import { Card } from "src/components/atoms/Card";
import { LoadingCard, LoadingHeader } from "src/components/molecules/Loading";
import { formatDate } from "src/utils/formatDate";
import { getTotalResend } from "src/utils/getTotalResends";
import { getResendsCountMap } from "src/utils/getResendsCountMap";
import { Notification } from "src/store/types";
import { NotificationAccordion } from "src/components/molecules/NotificationAccordion";
import { useUserQuery } from "src/store/user";

interface IProxyCommunicationDetails {
  activeTab?: CommunicationDetailsTabs;
  communicationId: string;
  // This prop is used to automatically scroll to and open the appropriate notification accordion
  // when user is coming from a notification table
  notificationId?: string;
  onClose: () => void;
  proxyId: string;
}

export function ProxyCommunicationDetails({
  activeTab = "meeting",
  communicationId,
  notificationId,
  onClose,
  proxyId,
}: IProxyCommunicationDetails) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [tab, setTab] = useState<CommunicationDetailsTabs>(activeTab);
  const { data: user } = useUserQuery();
  const {
    data: proxyEvent,
    isLoading: isProxyEventLoading,
    error: proxyEventError,
  } = useProxyEventQuery({ id: proxyId });
  const {
    data: communication,
    isLoading,
    error,
  } = useProxyEventCommunicationQuery({
    proxyId,
    communicationId,
  });
  const [resetMeetingAttendance, { isLoading: isResettingMeetingAttendance }] =
    useResetMeetingAttendanceMutation();

  const [targetNotificationId, setTargetNotificationId] =
    useState(notificationId);
  const [resetBallotDialogOpen, setResetBallotDialogOpen] = useState(false);

  useEffect(() => {
    if (!activeTab) {
      setTab("meeting");
    }
  }, [activeTab, communication]);

  const handleTabChange = (value: string) => {
    setTab(value as CommunicationDetailsTabs);
  };

  if (
    error ||
    proxyEventError ||
    (!communication && !isLoading) ||
    (!proxyEvent && !isProxyEventLoading)
  ) {
    return <ErrorState />;
  }

  if (isLoading || isProxyEventLoading) {
    return (
      <>
        <LoadingHeader
          sx={{ mb: 10, pt: 10, px: 10 }}
          showIcon={false}
          numberOfRows={3}
        />
        <Box sx={{ px: 10 }}>
          <Tabs
            activeTab={tab}
            clickHandler={handleTabChange}
            tabs={PROXY_COMMUNICATION_DETAILS_TABS}
          />
        </Box>
        <LoadingCard sx={{ mt: 8, mx: 10 }} />
      </>
    );
  }

  const { issuer, meetingDate, meetingFormats, proposals, type, voteCutoff } =
    proxyEvent;

  const {
    customer,
    modified,
    notifications,
    positions,
    security,
    voteStatus,
    meetingAttendance,
  } = communication;

  const handleResend = (notification: Notification) => {
    setTargetNotificationId(notification.id);
  };

  // We want to get the total number of resends for this communication
  // Assuming for each communication only one type of notification will be resendable.
  // This logic will break if one communication can have multiple resendable notifications.
  const resendTypes = [
    "resent_initial_proxy_vote",
    "resent_initial_contested_proxy_vote",
    "resent_initial_proxy_informational",
    "resent_initial_proxy_non_votable",
    "resent_initial_consent_solicitation",
  ];
  const totalResends = getTotalResend(notifications, resendTypes);

  // Since we are not getting the count of resends from the backend, we are calculating it here.
  // Same assumption as above.
  const resendCountMap = getResendsCountMap(
    notifications,
    resendTypes,
    totalResends
  );

  return (
    <>
      <Dialog
        open={resetBallotDialogOpen}
        handleClose={() => setResetBallotDialogOpen(false)}
        title="Reset ballot"
        content={
          <Text>{`This will remove ${communication.customer.email}’s legal proxy request and re-open their online ballot for vote submission.`}</Text>
        }
        maxWidth="xs"
        buttonActions={[
          {
            onClick: () =>
              resetMeetingAttendance({
                proxyId,
                communicationId,
              }).then(() => {
                setResetBallotDialogOpen(false);
              }),
            label: "Reset now",
            loading: isResettingMeetingAttendance,
          },
        ]}
      />
      <Box sx={{ display: "flex", mb: 2, pt: 10, px: 10 }}>
        <CommunicationHeader
          backLink={`/mailings/proxy/${proxyId}/customers`}
          customerId={customer?.id}
          email={customer.email}
          isProxy={true}
          issuerId={issuer.id}
          issuerName={security.issuerName}
          sx={{ flexGrow: 1 }}
          type={PROXY_LABEL[type]}
        />
        <Box>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ px: 10 }}>
        <Tabs
          activeTab={tab}
          clickHandler={handleTabChange}
          tabs={PROXY_COMMUNICATION_DETAILS_TABS}
        />
      </Box>
      {tab === "meeting" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          <MeetingDetailsCard
            sx={{ mt: 8 }}
            meetingFormats={meetingFormats}
            date={meetingDate}
            numOfProposals={String(proposals.length)}
            cutOffTime={voteCutoff}
          />
          {proposals.length > 0 && (
            <ProposalsCard proposals={proposals} sx={{ mt: 8 }} />
          )}
        </ScrollContainer>
      )}
      {tab === "customer" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          {positions.map((position, index) => {
            return (
              <PositionCard
                sx={{ mt: 8 }}
                key={index}
                position={position}
                showProxyEligibleShares={true}
                cardTitle={
                  positions.length === 1 ? "Position" : `Position ${index + 1}`
                }
              />
            );
          })}
          <Card sx={{ mt: 8 }} title="Voted" testId="engagement-card">
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="Method"
              value={VOTE_STATUS_LABEL[voteStatus]}
            />
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="Date"
              value={voteStatus !== "not_viewed" ? formatDate(modified) : "-"}
            />
            {meetingAttendance?.legalProxy &&
              user?.permissions.includes(
                "can_reset_meeting_attendance_in_broker_portal"
              ) && (
                <Box pt={5} display="flex" justifyContent="flex-end">
                  <ButtonV2
                    variant="secondary"
                    disabled={new Date(voteCutoff) < new Date()}
                    onClick={() => {
                      setResetBallotDialogOpen(true);
                    }}
                    loading={isResettingMeetingAttendance}
                  >
                    Reset Ballot
                  </ButtonV2>
                </Box>
              )}
          </Card>
        </ScrollContainer>
      )}
      {tab === "notification" && (
        <ScrollContainer
          scrollContainerRef={scrollContainerRef}
          sx={{ pl: 10, pr: 6.25, pb: 10 }}
        >
          {notifications.length === 0 && (
            <EmptyState
              subtitle="When communications are sent, they will appear here."
              title="No communications have been sent yet to this customer for this mailing."
            />
          )}
          {notifications.length > 0 && (
            <Box sx={{ mt: 8 }}>
              {notifications.map((notification) => (
                <NotificationAccordion
                  key={notification.id}
                  communication={communication}
                  isExpanded={targetNotificationId === notification.id}
                  notification={notification}
                  onResend={handleResend}
                  resendCountMap={resendCountMap}
                  scrollContainerRef={scrollContainerRef}
                  sx={{
                    mb: 4,
                    "&:last-child": {
                      mb: 0,
                    },
                  }}
                  targetNotificationId={targetNotificationId}
                />
              ))}
            </Box>
          )}
        </ScrollContainer>
      )}
    </>
  );
}
