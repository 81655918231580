import { ColumnType, Row } from "@asayinc/component-library";

import { PROXY_LABEL, PROXY_NOTIFICATION_LABEL } from "src/constants/labels";
import { ListProxyNotification } from "src/store/proxy/types";
import { ListNotification } from "src/store/types";
import { formatDate } from "src/utils/formatDate";
import { getNotificationStatusTagData } from "src/utils/getNotificationStatusTagData";

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
};

type ColumnData = {
  mailing: ColumnType;
  notification: ColumnType;
  ticker: ColumnType;
  issuer: ColumnType;
  status: ColumnType;
  sentDate: ColumnType;
};

export function getColumns() {
  return ["mailing", "notification", "ticker", "issuer", "status", "sentDate"];
}

export function getColumnData({
  getSort,
  onOrderingChange,
}: ColumnDataHelpers): ColumnData {
  return {
    mailing: {
      style: "text",
      title: "Mailing",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
    },
    notification: {
      sort: getSort("type"),
      sortFn: (data: unknown) => onOrderingChange(data, "type"),
      style: "text",
      title: "Notification",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
    },
    ticker: {
      style: "text",
      title: "Ticker",
    },
    issuer: {
      style: "text",
      title: "Issuer",
    },
    status: {
      style: "text",
      title: "Status",
      width: "188px",
    },
    sentDate: {
      style: "text",
      title: "Sent date",
    },
  };
}

export function getRowData(
  notifications: ListProxyNotification[],
  onRowClick: (notification: ListNotification) => void,
  selectedNotification: ListNotification | null | undefined
): Row[] {
  return notifications.map((notification, idx) => {
    const handleRowClick = (data: unknown) => {
      const { notification } = data as {
        notification: ListProxyNotification;
        idx: string;
      };
      onRowClick(notification);
    };

    const {
      id,
      proxyEvent: { type: proxyType },
      status,
      security,
      sentAt,
      type,
    } = notification;

    const rowData = {
      mailing: {
        content: PROXY_LABEL[proxyType] || "-",
        clickHandler: handleRowClick,
      },
      notification: {
        content: PROXY_NOTIFICATION_LABEL[type] || "-",
        clickHandler: handleRowClick,
      },
      ticker: {
        content: security?.ticker || "-",
        clickHandler: handleRowClick,
      },
      issuer: {
        content: security?.issuerName || "-",
        clickHandler: handleRowClick,
      },
      status: {
        style: "tag",
        tagCellData: getNotificationStatusTagData(status),
        clickHandler: handleRowClick,
      },
      sentDate: {
        content: sentAt ? formatDate(sentAt, true, false) : "-",
        clickHandler: handleRowClick,
      },
    };

    return {
      id,
      data: { notification, idx },
      rowData,
      highlighted: selectedNotification?.id === notification.id,
    };
  });
}
