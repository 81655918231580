import { Tabs } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";

import { BackLink } from "src/components/atoms/BackLink";
import { ErrorState } from "src/components/atoms/ErrorState";
import { PageContainer } from "src/components/containers/PageContainer/PageContainer";
import { DetailPageHeader } from "src/components/molecules/DetailPageHeader";
import { LoadingHeader } from "src/components/molecules/Loading/LoadingHeader";
import { PROXY_LABEL } from "src/constants/labels";
import { PROXY_MAILING_DETAIL_TABS } from "src/constants/tabs";
import { useProxyEventQuery } from "src/store/proxy";
import { maxDate, minDate } from "src/utils/compareDates";
import { hasMeetingInfo } from "src/utils/hasMeetingInfo";

export function ProxyDetail() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { data: proxyEvent, isLoading, error } = useProxyEventQuery({ id });
  const match = useMatch(`/mailings/proxy/${id}/:activeTab`) as {
    params: { activeTab: string };
  };

  const handleTabChange = (value: string) => {
    navigate(`/mailings/proxy/${id}/${value}`);
  };

  if (error || (!isLoading && !proxyEvent)) {
    return <ErrorState />;
  }

  const tabs =
    hasMeetingInfo(proxyEvent?.type) &&
    proxyEvent?.operationsStatus === "approved"
      ? PROXY_MAILING_DETAIL_TABS
      : PROXY_MAILING_DETAIL_TABS.filter((tab) => tab.value !== "meeting-info");

  return (
    <PageContainer>
      <BackLink
        sx={{ mb: 3, ml: -2, pt: 10, px: 10 }}
        label="Mailings"
        to={`/mailings/proxy?view=${
          proxyEvent?.operationsStatus === "approved" ? "approved" : "upcoming"
        }`}
      />
      {isLoading ? (
        <LoadingHeader sx={{ px: 10 }} numberOfRows={1} />
      ) : (
        <DetailPageHeader
          backLink={`/mailings/proxy/${id}/${
            match?.params?.activeTab || "customers"
          }`}
          endDate={maxDate(
            proxyEvent.stats.initialEndScheduledSendAt,
            proxyEvent.stats.initialWebhookEndScheduledSendAt
          )}
          issuerName={proxyEvent.securities[0].issuerName || "-"}
          issuerId={proxyEvent.issuer?.id}
          isApproved={proxyEvent.operationsStatus === "approved"}
          startDate={minDate(
            proxyEvent.stats.initialStartScheduledSendAt,
            proxyEvent.stats.initialWebhookStartScheduledSendAt
          )}
          type={PROXY_LABEL[proxyEvent.type]}
          sx={{ px: 10 }}
        />
      )}
      <Box sx={{ mx: 10, mt: 3 }}>
        <Tabs
          activeTab={match?.params?.activeTab || "customers"}
          clickHandler={handleTabChange}
          tabs={tabs}
        />
      </Box>
      <Outlet />
    </PageContainer>
  );
}
