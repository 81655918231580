import {
  getAuthToken,
  removeAuthToken,
  setAuthToken,
} from "@asayinc/component-library";
import axios, { AxiosRequestTransformer } from "axios";
import humps from "humps";
import jwt_decode from "jwt-decode";

interface IJWT {
  exp: number;
}

// clear local storage and auth token
const logout = () => {
  removeAuthToken();
  window.localStorage.clear();
  window.location.href = window.location.origin;
};

export const refreshTokenIfExpired = () => {
  const token = getAuthToken();
  const refreshToken = window.localStorage.getItem("refresh-token");

  if (token && refreshToken) {
    const decodedJWT: IJWT = jwt_decode(token);
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const { exp } = decodedJWT;
    return new Promise((resolve, reject) => {
      if (nowInSeconds > exp) {
        ssoApi
          .post(`auth/refresh-token/`, {
            token: refreshToken,
          })
          .then((response) => {
            const { accessToken } = response.data;
            setAuthToken({ value: accessToken });
            resolve(accessToken);
          })
          .catch((error) => {
            // reset to login if refresh fails
            logout();
            reject(error);
          });
      } else {
        resolve(token);
      }
    });
  }
  logout();
  return null;
};

/**
 * converts my_var to myVar and back for requests/responses.
 * Covers any and all responses/requests. no type needed
 */
const sharedAxiosConfiguration = {
  transformRequest: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosRequestTransformer[]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => humps.camelizeKeys(data),
  ],
};

// SSO
const ssoApi = axios.create({
  ...sharedAxiosConfiguration,
  baseURL: `${process.env.REACT_APP_SSO_API}v1/`,
});
