// reference: https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs
export function getHighlightedText(text: string, highlight: string) {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { color: "#1968FF", fontWeight: 600 }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
}
