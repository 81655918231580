import { ColumnType, Row } from "@asayinc/component-library";
import { IProxyCustomer } from "src/store/proxy/types";

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
  onRowClick?: (data: unknown) => void;
};

export type ColumnData = {
  broker: ColumnType;
  customerEmail: ColumnType;
  customerId: ColumnType;
  securityCusip: ColumnType;
};

export function getColumns(isPiiFreeBroker: boolean) {
  if (isPiiFreeBroker) {
    return ["broker", "customerId", "securityCusip"];
  }

  return ["broker", "customerEmail", "customerId", "securityCusip"];
}

export function getColumnData({
  getSort,
  onOrderingChange,
}: ColumnDataHelpers): ColumnData {
  return {
    broker: {
      sort: getSort("broker__name"),
      sortFn: (data: unknown) => onOrderingChange(data, "broker__name"),
      style: "text",
      title: "Broker",
    },
    customerEmail: {
      sort: getSort("email"),
      sortFn: (data: unknown) => onOrderingChange(data, "email"),
      style: "text",
      title: "Customer Email",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
    },
    customerId: {
      // sort: getSort("broker__name"),
      // sortFn: (data: unknown) =>
      //   onOrderingChange(data, "broker__name"),
      style: "text",
      title: "Customer Id",
    },
    securityCusip: {
      // sort: getSort("broker__name"),
      // sortFn: (data: unknown) =>
      //   onOrderingChange(data, "broker__name"),
      style: "text",
      title: "Security CUSIP",
    },
  };
}

export function getRowData(
  customers: IProxyCustomer[],
  onRowClick: (customer: IProxyCustomer) => void,
  selectedCustomer: IProxyCustomer | null | undefined
): Row[] {
  return customers.map((customer, idx) => {
    const {
      customerIdentifier,
      broker: { id, name },
      email,
      securities,
    } = customer;

    const handleOnRowClick = () => {
      onRowClick(customer);
    };

    return {
      id: id,
      data: { id, idx },
      rowData: {
        broker: {
          content: name || "-",
          clickHandler: handleOnRowClick,
        },
        customerEmail: {
          content: email || "-",
          clickHandler: handleOnRowClick,
        },
        customerId: {
          content: customerIdentifier || "-",
          clickHandler: handleOnRowClick,
        },
        securityCusip: {
          content: securities?.length > 0 ? securities[0].cusip : "-",
          clickHandler: handleOnRowClick,
        },
      },
      highlighted:
        selectedCustomer?.customerIdentifier === customer.customerIdentifier,
    };
  });
}
