import { Box, SxProps } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { COLORS, IconButton, Text } from "@asayinc/component-library";
import { useLocation, useNavigate } from "react-router-dom";

interface IBackLinkProps {
  label?: string;
  sx?: SxProps;
  to?: string;
}

/*
  To render this component we require either:
  1) "to" prop
  2) location.state.backLink (This state should be set when we navigate to the page)
  Otherwise, we will not render anythingnow.production.json
*/

export function BackLink({ label = "Back", sx, to }: IBackLinkProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const backLink = location?.state?.backLink;

  if (!to && !backLink) {
    return null;
  }

  const handleOnClick = () => {
    navigate(to || backLink, { state: { backLink: undefined } });
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        color: COLORS.BLACK,
        display: "flex",
        ...sx,
      }}
    >
      <Box
        sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        data-testid="back-link"
        onClick={handleOnClick}
      >
        <IconButton sx={{ mr: 1.5 }}>
          <ArrowBack />
        </IconButton>
        <Text variant="subtitle2">{label}</Text>
      </Box>
    </Box>
  );
}
