import { IconButton, Tabs } from "@asayinc/component-library";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { Card } from "src/components/atoms/Card";
import { ErrorState } from "src/components/atoms/ErrorState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { CommunicationHeader } from "src/components/molecules/CommunicationHeader";
import { EmptyState } from "src/components/molecules/EmptyState";
import { LabelField } from "src/components/molecules/LabelField";
import { LoadingCard, LoadingHeader } from "src/components/molecules/Loading";
import { OfferCard } from "src/components/molecules/OfferCard";
import { PositionCard } from "src/components/molecules/PositionCard";
import {
  CORPORATE_ACTION_LABEL,
  ELECTION_STATUS_LABEL,
} from "src/constants/labels";
import {
  CAE_COMMUNICATION_DETAILS_TABS,
  CommunicationDetailsTabs,
} from "src/constants/tabs";
import {
  useCorporateActionEventCommunicationQuery,
  useCorporateActionEventQuery,
} from "src/store/corporateAction";
import { formatDate } from "src/utils/formatDate";
import { getResendsCountMap } from "src/utils/getResendsCountMap";
import { getTotalResend } from "src/utils/getTotalResends";
import { Notification } from "src/store/types";
import { NotificationAccordion } from "src/components/molecules/NotificationAccordion";

interface ICorporateActionCommunicationDetails {
  activeTab?: CommunicationDetailsTabs;
  corporateActionId: string;
  communicationId: string;
  // This prop is used to automatically scroll to and open the appropriate notification accordion
  // when user is coming from a notification table
  notificationId?: string;
  onClose: () => void;
}

export function CorporateActionCommunicationDetails({
  activeTab = "meeting",
  corporateActionId,
  communicationId,
  notificationId,
  onClose,
}: ICorporateActionCommunicationDetails) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [tab, setTab] = useState<CommunicationDetailsTabs>(activeTab);
  const {
    data: corporateActionEvent,
    isLoading: isCorporateActionLoading,
    error: corporateActionError,
  } = useCorporateActionEventQuery({ id: corporateActionId });
  const {
    data: communication,
    isLoading,
    error,
  } = useCorporateActionEventCommunicationQuery({
    corporateActionId,
    communicationId,
  });
  const [targetNotificationId, setTargetNotificationId] =
    useState(notificationId);

  useEffect(() => {
    if (!activeTab) {
      setTab("meeting");
    }
  }, [activeTab, communication]);

  const handleTabChange = (value: string) => {
    setTab(value as CommunicationDetailsTabs);
  };

  if (
    error ||
    corporateActionError ||
    (!communication && !isLoading) ||
    (!corporateActionEvent && !isCorporateActionLoading)
  ) {
    return <ErrorState />;
  }

  if (isLoading || isCorporateActionLoading) {
    return (
      <>
        <LoadingHeader
          sx={{ mb: 10, pt: 10, px: 10 }}
          showIcon={false}
          numberOfRows={3}
        />
        <Box sx={{ px: 10 }}>
          <Tabs
            activeTab={tab}
            clickHandler={handleTabChange}
            tabs={CAE_COMMUNICATION_DETAILS_TABS}
          />
        </Box>
        <LoadingCard sx={{ mt: 8, mx: 10 }} />
      </>
    );
  }

  const { dtcExpiration, electionCutoff, issuer, offer, type } =
    corporateActionEvent;
  const {
    customer,
    electionStatus,
    modified,
    notifications,
    positions,
    security,
  } = communication;

  const handleResend = (notification: Notification) => {
    setTargetNotificationId(notification.id);
  };

  // We want to get the total number of resends for this communication
  // Assuming for each communication only one type of notification will be resendable.
  // This logic will break if one communication can have multiple resendable notifications.
  const resendTypes = [
    "resent_initial_corporate_action_election",
    "resent_initial_corporate_action_informational",
  ];
  const totalResends = getTotalResend(notifications, resendTypes);

  // Since we are not getting the count of resends from the backend, we are calculating it here.
  // Same assumption as above.
  const resendCountMap = getResendsCountMap(
    notifications,
    resendTypes,
    totalResends
  );

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, pt: 10, px: 10 }}>
        <CommunicationHeader
          backLink={`/mailings/corporate-action/${corporateActionId}/customers`}
          customerId={customer?.id}
          email={customer.email}
          isProxy={false}
          issuerId={issuer.id}
          issuerName={security.issuerName}
          sx={{ flexGrow: 1 }}
          type={CORPORATE_ACTION_LABEL[type]}
        />
        <Box>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ px: 10 }}>
        <Tabs
          activeTab={tab}
          clickHandler={handleTabChange}
          tabs={CAE_COMMUNICATION_DETAILS_TABS}
        />
      </Box>
      {tab === "meeting" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          <Card
            sx={{ mt: 8 }}
            title="Details"
            testId="corporate-action-offer-info-card"
          >
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="DTC expiration"
              value={formatDate(dtcExpiration, true, true)}
            />
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="Election submission cut-off"
              value={formatDate(electionCutoff, true, true)}
            />
          </Card>
          <OfferCard offer={offer} sx={{ mt: 8 }} />
        </ScrollContainer>
      )}
      {tab === "customer" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          {positions.map((position, index) => {
            return (
              <PositionCard
                sx={{ mt: 8 }}
                key={index}
                position={position}
                cardTitle={
                  positions.length === 1 ? "Position" : `Position ${index + 1}`
                }
              />
            );
          })}
          <Card sx={{ mt: 8 }} title="Elected" testId="engagement-card">
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="Method"
              value={ELECTION_STATUS_LABEL[electionStatus]}
            />
            <LabelField
              sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
              label="Date"
              value={
                electionStatus !== "not_viewed" ? formatDate(modified) : "-"
              }
            />
          </Card>
        </ScrollContainer>
      )}
      {tab === "notification" && (
        <ScrollContainer
          scrollContainerRef={scrollContainerRef}
          sx={{ pl: 10, pr: 6.25, pb: 10 }}
        >
          {notifications.length === 0 && (
            <EmptyState
              subtitle="When communications are sent, they will appear here."
              title="No communications have been sent yet 
            to this customer for this mailing."
            />
          )}
          {notifications.length > 0 && (
            <Box sx={{ mt: 8 }}>
              {notifications?.map((notification) => (
                <NotificationAccordion
                  key={notification.id}
                  communication={communication}
                  isExpanded={targetNotificationId === notification.id}
                  notification={notification}
                  onResend={handleResend}
                  resendCountMap={resendCountMap}
                  scrollContainerRef={scrollContainerRef}
                  sx={{
                    mb: 4,
                    "&:last-child": {
                      mb: 0,
                    },
                  }}
                  targetNotificationId={targetNotificationId}
                />
              ))}
            </Box>
          )}
        </ScrollContainer>
      )}
    </>
  );
}
