import { Issuer } from "./types";
import { rootApi } from "../rootApi";

export const issuerApis = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    issuer: builder.query<Issuer, { id: string }>({
      query: ({ id }) => ({
        url: `issuers/${id}/`,
      }),
      providesTags: (_result, _, arg) => [{ type: "issuer", id: arg.id }],
    }),
  }),
});

export const { useIssuerQuery } = issuerApis;
