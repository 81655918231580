import { ButtonV2, Tag, Text, Tooltip } from "@asayinc/component-library";
import { ExpandMore, InfoOutlined } from "@mui/icons-material";
import { AccordionSummary, Box, Stack } from "@mui/material";
import { useState } from "react";

import {
  CORPORATE_ACTION_NOTIFICATION_LABEL,
  PROXY_NOTIFICATION_LABEL,
} from "src/constants/labels";
import {
  Communication,
  CommunicationNotification,
  Notification,
} from "src/store/types";
import { formatDate } from "src/utils/formatDate";
import { getNotificationStatusTagData } from "src/utils/getNotificationStatusTagData";
import { NotificationResendDialog } from "../../NotificationResendDialog";
import { getOrdinalNumber } from "src/utils/getOrdinalNumber";
import { useUserQuery } from "src/store/user";
import { shouldShowResendButton } from "./utils";
import {
  CORPORATE_ACTION_NOTIFICATION_TOOLTIP,
  PROXY_NOTIFICATION_TOOLTIPS,
} from "src/constants/tooltips";

interface INotificationAccordionSummaryProps {
  communication: Communication;
  notification: CommunicationNotification;
  resendCountMap: { [notificationId: string]: number };
  onResend: (notification: Notification) => void;
}

export function NotificationAccordionSummary({
  communication,
  notification,
  resendCountMap,
  onResend,
}: INotificationAccordionSummaryProps) {
  const { data: user } = useUserQuery();
  const [isResendDialogOpen, setIsResendDialogOpen] = useState(false);

  const { isResendable, sentAt, status, type } = notification;

  const label =
    PROXY_NOTIFICATION_LABEL[type] || CORPORATE_ACTION_NOTIFICATION_LABEL[type];
  const tooltip =
    PROXY_NOTIFICATION_TOOLTIPS[type] ||
    CORPORATE_ACTION_NOTIFICATION_TOOLTIP[type];

  const showResendButton = shouldShowResendButton(notification, user);

  const handleResendClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsResendDialogOpen(true);
  };

  const handleResendDialogClose = () => {
    setIsResendDialogOpen(false);
  };

  const resendCount = resendCountMap[notification.id];
  const resendValues = Object.values(resendCountMap);
  const nextCount = resendValues.length > 0 ? Math.max(...resendValues) + 1 : 1;
  const resendLabel = resendCount
    ? `${label} - ${getOrdinalNumber(resendCount)} re-send`
    : "";

  return (
    <>
      <AccordionSummary sx={{ p: 8 }} expandIcon={<ExpandMore />}>
        <Stack flexGrow={1} direction="row">
          <Stack flexGrow={1}>
            <Box sx={{ display: "flex" }}>
              <Tag {...getNotificationStatusTagData(status)} />
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
              <Text
                variant="subtitle1"
                data-testid="notification-accordion-summary-title"
              >
                {resendCount ? resendLabel : label}
              </Text>
              <Tooltip title={tooltip} sx={{ display: "flex", ml: 0.5 }}>
                <InfoOutlined sx={{ height: "16px" }} />
              </Tooltip>
            </Box>
            {sentAt && (
              <Text
                variant="body2"
                data-testid="notification-accordion-summary-sent-on"
              >
                Sent on {formatDate(sentAt, true, false)}
              </Text>
            )}
          </Stack>
          {showResendButton && (
            <Box sx={{ my: "auto" }}>
              <ButtonV2
                disabled={!isResendable}
                sx={{ mr: 3 }}
                onClick={handleResendClick}
                variant="secondary"
              >
                Re-send
              </ButtonV2>
            </Box>
          )}
        </Stack>
      </AccordionSummary>
      {showResendButton && (
        <NotificationResendDialog
          communication={communication}
          nextCount={nextCount}
          notificationId={notification.id}
          isOpen={isResendDialogOpen}
          onClose={handleResendDialogClose}
          onResend={onResend}
        />
      )}
    </>
  );
}
