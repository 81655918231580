import { IPaginationResponse, Preview } from "../types";
import {
  IAggregateResponse,
  ProxyCommunication,
  IProxyCommunicationParams,
  IProxyCommunicationsParams,
  IProxyCustomer,
  IProxyDetailParams,
  IProxyEvent,
  IProxyEventParams,
  IProxyEventsAggregateParams,
  IProxyEventsParams,
  IProxyMailing,
  ProxyNotification,
  IProxyNotificationParams,
  ListProxyCommunication,
} from "./types";
import { rootApi } from "../rootApi";

export const proxyApis = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    proxyEvents: builder.query<
      IPaginationResponse<IProxyMailing>,
      IProxyEventsParams
    >({
      query: ({
        limit,
        ordering,
        offset,
        type,
        view,
        issuerId,
        approvedAfter,
      }) => ({
        url: `proxy-events/`,
        params: {
          limit,
          ordering,
          offset,
          operations_status: view || undefined,
          type: type || undefined,
          issuer_id: issuerId || undefined,
          approved_at__gte: approvedAfter || undefined,
        },
      }),
      providesTags: ["proxyEvents"],
    }),
    proxyEvent: builder.query<IProxyEvent, IProxyEventParams>({
      query: ({ id }) => ({
        url: `proxy-events/${id}/`,
      }),
      providesTags: (_result, _, arg) => [{ type: "proxyEvents", id: arg.id }],
    }),
    proxyEventsAggregate: builder.query<
      IAggregateResponse,
      IProxyEventsAggregateParams
    >({
      query: ({ type, view, approvedAfter }) => ({
        url: "proxy-events/aggregate/",
        params: {
          operations_status: view || undefined,
          type: type || undefined,
          approved_at__gte: approvedAfter || undefined,
        },
      }),
      providesTags: ["proxyEventsAggregate"],
    }),
    proxyEventCustomers: builder.query<
      IPaginationResponse<IProxyCustomer>,
      IProxyDetailParams
    >({
      query: ({ id, limit, offset, ordering }) => ({
        url: `proxy-events/${id}/customers/`,
        params: {
          limit,
          ordering,
          offset,
        },
      }),
      providesTags: (_result, _, arg) => [
        { type: "proxyEventCustomers", id: arg.id },
        "proxyEventCustomers",
      ],
    }),
    proxyEventCommunications: builder.query<
      IPaginationResponse<ListProxyCommunication>,
      IProxyCommunicationsParams
    >({
      query: ({ id, limit, offset, ordering, voteStatus, search }) => ({
        url: `proxy-events/${id}/communications/`,
        params: {
          limit,
          ordering,
          offset,
          vote_status: voteStatus || undefined,
          search: search || undefined,
        },
      }),
      providesTags: (_result, _, arg) => [
        { type: "proxyEventCommunications", id: arg.id },
      ],
    }),
    proxyEventCommunication: builder.query<
      ProxyCommunication,
      IProxyCommunicationParams
    >({
      query: ({ proxyId, communicationId }) => ({
        url: `proxy-events/${proxyId}/communications/${communicationId}/`,
      }),
      providesTags: (_result, _, arg) => [
        { type: "proxyEventCommunication", id: arg.communicationId },
      ],
    }),
    proxyEventNotifications: builder.query<
      IPaginationResponse<ProxyNotification>,
      IProxyDetailParams
    >({
      query: ({
        id,
        limit,
        offset,
        ordering,
        search,
        type,
        status,
        sentAt,
        sentBefore,
        sentAfter,
      }) => ({
        url: `proxy-events/${id}/notifications/`,
        params: {
          limit,
          ordering,
          offset,
          type: type || undefined,
          status: status || undefined,
          search: search || undefined,
          sent_at: sentAt || undefined,
          sent_at__lte: sentBefore || undefined,
          sent_at__gte: sentAfter || undefined,
        },
      }),
      providesTags: (_result, _, arg) => [
        { type: "proxyEventNotifications", id: arg.id },
      ],
    }),
    proxyEventNotification: builder.query<
      ProxyNotification,
      IProxyNotificationParams
    >({
      query: ({ proxyId, notificationId }) => ({
        url: `proxy-events/${proxyId}/notifications/${notificationId}/`,
      }),
      providesTags: (_result, _, arg) => [
        { type: "proxyEventNotification", id: arg.notificationId },
      ],
    }),
    proxyEventNotificationPreview: builder.query<
      Preview,
      IProxyNotificationParams
    >({
      query: ({ proxyId, notificationId }) => ({
        url: `proxy-events/${proxyId}/notifications/${notificationId}/render/`,
      }),
      providesTags: (_result, _, arg) => [
        { type: "proxyEventNotificationPreview", id: arg.notificationId },
      ],
    }),
    resendProxyEventNotification: builder.mutation<
      ProxyNotification,
      IProxyNotificationParams
    >({
      query: ({ proxyId, notificationId }) => ({
        url: `proxy-events/${proxyId}/notifications/${notificationId}/resend/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "proxyEventNotifications", id: arg.proxyId },
        "proxyEventCommunication",
      ],
    }),
    resetMeetingAttendance: builder.mutation<
      ProxyCommunication,
      IProxyCommunicationParams
    >({
      query: ({ proxyId, communicationId }) => ({
        url: `proxy-events/${proxyId}/communications/${communicationId}/reset-meeting-attendance/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "proxyEventCommunication", id: arg.communicationId },
      ],
    }),
  }),
});

export const {
  useProxyEventQuery,
  useProxyEventsQuery,
  useProxyEventsAggregateQuery,
  useProxyEventCustomersQuery,
  useProxyEventCommunicationQuery,
  useProxyEventCommunicationsQuery,
  useProxyEventNotificationQuery,
  useProxyEventNotificationsQuery,
  useProxyEventNotificationPreviewQuery,
  useResendProxyEventNotificationMutation,
  useResetMeetingAttendanceMutation,
} = proxyApis;
