import { IconButton, Tabs } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommunicationHeader } from "src/components/molecules/CommunicationHeader/CommunicationHeader";
import { IProxyCustomer } from "src/store/proxy/types";
import { PositionCard } from "src/components/molecules/PositionCard/PositionCard";
import { useState } from "react";
import { EmptyState } from "src/components/molecules/EmptyState";
import {
  PROXY_COMMUNICATION_DETAILS_TABS,
  CommunicationDetailsTabs,
} from "src/constants/tabs";
import { MeetingDetailsCard } from "src/components/molecules/MeetingDetailsCard";
import { useProxyEventQuery } from "src/store/proxy";
import { ErrorState } from "src/components/atoms/ErrorState";
import { PROXY_LABEL } from "src/constants/labels";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { LoadingCard, LoadingHeader } from "src/components/molecules/Loading";

interface ICustomerDetailsProps {
  customer: IProxyCustomer;
  onClose: () => void;
  proxyId: string;
}

export function CustomerDetails({
  customer,
  onClose,
  proxyId,
}: ICustomerDetailsProps) {
  const {
    data: proxyEvent,
    isLoading: isProxyEventLoading,
    isFetching: isProxyEventFetching,
    error: proxyEventError,
  } = useProxyEventQuery({ id: proxyId });
  const [tab, setTab] = useState<CommunicationDetailsTabs>("customer");

  const handleTabChange = (value: string) => {
    setTab(value as CommunicationDetailsTabs);
  };

  if (isProxyEventLoading || isProxyEventFetching) {
    return (
      <>
        <LoadingHeader
          sx={{ mb: 10, pt: 10, px: 10 }}
          showIcon={false}
          numberOfRows={3}
        />
        <Box sx={{ px: 10 }}>
          <Tabs
            activeTab={tab}
            clickHandler={handleTabChange}
            tabs={PROXY_COMMUNICATION_DETAILS_TABS}
          />
        </Box>
        <LoadingCard sx={{ mt: 8, mx: 10 }} />
      </>
    );
  }

  if (!proxyEvent || proxyEventError) {
    return <ErrorState />;
  }

  const { id, email, positions } = customer;
  const { issuer, meetingDate, meetingFormats, proposals, type, voteCutoff } =
    proxyEvent;

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, pt: 10, px: 10 }}>
        <CommunicationHeader
          customerId={id}
          issuerId={issuer.id}
          issuerName={issuer.name}
          isProxy={true}
          email={email}
          sx={{ flexGrow: 1 }}
          type={PROXY_LABEL[type]}
        />
        <Box>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ px: 10 }}>
        <Tabs
          activeTab={tab}
          clickHandler={handleTabChange}
          tabs={PROXY_COMMUNICATION_DETAILS_TABS}
        />
      </Box>
      {tab === "meeting" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          <MeetingDetailsCard
            sx={{ mt: 8 }}
            meetingFormats={meetingFormats}
            date={meetingDate}
            numOfProposals={String(proposals.length)}
            cutOffTime={voteCutoff}
          />
        </ScrollContainer>
      )}
      {tab === "customer" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          {(!positions || positions?.length === 0) && (
            <EmptyState
              subtitle="When customer positions information is available, they will appear here."
              title="Customer position information is not available."
            />
          )}
          {positions?.map((position, index) => {
            return (
              <PositionCard
                sx={{ mt: 8 }}
                key={index}
                position={position}
                cardTitle={
                  positions.length === 1 ? "Position" : `Position ${index + 1}`
                }
              />
            );
          })}
        </ScrollContainer>
      )}
      {tab === "notification" && (
        <ScrollContainer sx={{ pl: 10, pr: 6.25, pb: 10 }}>
          <EmptyState
            subtitle="When communications are sent, they will appear here."
            title="No communications have been sent yet 
  to this customer for this mailing."
          />
        </ScrollContainer>
      )}
    </>
  );
}
