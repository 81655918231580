import {
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import humps from "humps";

import { refreshTokenIfExpired } from "../utils/refreshTokenIfExpired";

export function getBaseQueryWithCamelize(
  baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {},
    FetchBaseQueryMeta
  >
) {
  const baseQueryWithCamelize: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions = {}) => {
    // decamelize request fields
    if (typeof args !== "string") {
      args.body = humps.decamelizeKeys(args.body);
    }
    const result = await baseQuery(args, api, extraOptions);
    if (result.data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result.data = humps.camelizeKeys(result.data as any);
    }
    return result;
  };
  return baseQueryWithCamelize;
}

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_WARRENG_URL}broker-portal/v1/`,
  prepareHeaders: async (headers) => {
    const token = await refreshTokenIfExpired();
    headers.set("Authorization", `JWT ${token}`);
    return headers;
  },
});

export const baseQueryWithCamelize = getBaseQueryWithCamelize(baseQuery);
