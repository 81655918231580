import {
  IDate,
  LoadingSkeleton,
  Text,
  Timeline,
} from "@asayinc/component-library";
import { AccordionDetails, Box } from "@mui/material";

import { Communication, CommunicationNotification } from "src/store/types";
import { getNotificationTimelineEvents } from "src/utils/getNotificationTimelineEvents";
import { CORPORATE_ACTION_LABEL, PROXY_LABEL } from "src/constants/labels";
import { PreviewRender } from "src/components/organisms/PreviewRender";
import { useNotification } from "./useNotification";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface INotificationAccordionDetailsProps {
  communication: Communication;
  isOpen: boolean;
  notification: CommunicationNotification;
}

export function NotificationAccordionDetails({
  communication,
  isOpen,
  notification,
}: INotificationAccordionDetailsProps) {
  const { proxyEvent, corporateActionEvent, security } = communication;
  const { isLoading, preview, notificationWithEvents } = useNotification(
    communication,
    notification,
    isOpen
  );
  const isPiiFreeBroker = useIsPiiFreeBroker();

  const dates: IDate[] = getNotificationTimelineEvents(
    notification.channelType,
    notificationWithEvents?.notificationEvents || []
  );

  const label = proxyEvent
    ? PROXY_LABEL[proxyEvent.type]
    : corporateActionEvent
    ? CORPORATE_ACTION_LABEL[corporateActionEvent.type]
    : "";

  const previewContent = (() => {
    if (notification.type.endsWith("in_app")) {
      return (
        <Text sx={{ mt: 3, mb: 6 }} variant="body3">
          In-app notifications are not rendered as previews.
        </Text>
      );
    }

    if (notification.type.endsWith("webhook")) {
      return (
        <Text sx={{ mt: 3, mb: 6 }} variant="body3">
          Webhook notifications are not rendered as previews.
        </Text>
      );
    }
    return (
      <>
        <Text
          sx={{ mt: 3, mb: 6 }}
          variant="subtitle1"
          data-testid="preview-title"
        >
          {`${label} from ${security?.issuerName || ""}`}
        </Text>
        {preview && <PreviewRender preview={preview} />}
      </>
    );
  })();

  return (
    <AccordionDetails sx={{ mb: 6, px: 8 }}>
      {isLoading ? (
        <Box data-testid="loading-state">
          <LoadingSkeleton sx={{ mb: 4, width: "40%" }} />
          <LoadingSkeleton sx={{ mb: 4 }} />
          <LoadingSkeleton sx={{ mb: 4 }} />
          <LoadingSkeleton />
        </Box>
      ) : (
        <>
          <Box
            sx={{ borderTop: "1px solid #e0e0e0" }}
            data-testid="notification-accordion-timeline"
          >
            <Timeline dates={dates} singleDateColumn />
          </Box>
          {!isPiiFreeBroker && (
            <>
              <Text sx={{ mt: 6, mb: 3 }} variant="body2">
                Notification Preview
              </Text>
              {previewContent}
            </>
          )}
        </>
      )}
    </AccordionDetails>
  );
}
