import { CorporateActionNotificationType } from "src/store/corporateAction/types";

export const CORPORATE_ACTION_NOTIFICATION_TOOLTIP: {
  [key in CorporateActionNotificationType]: string;
} = {
  initial_corporate_action_informational:
    "Initial email sent to customer notifying them of new materials regarding their investment.",
  initial_corporate_action_election:
    "Initial email sent to customer notifying them of a new voluntary corporate action.",
  confirmation_corporate_action_election:
    "Sent to customer after they submitted a election.",
  resent_initial_corporate_action_election:
    "A re-send of a previous email was sent to the customer upon their request.",
  resent_initial_corporate_action_informational:
    "A re-send of a previous email was sent to the customer upon their request.",
  confirmation_corporate_action_election_webhook:
    "Notification sent to customer after they submitted a election.",
  initial_corporate_action_election_webhook:
    "Initial notice sent to customer informing them of a new voluntary corporate action.",
  initial_corporate_action_informational_webhook:
    "Initial notification sent to customer informing them of new materials regarding their investment.",
  initial_corporate_action_informational_in_app:
    "Initial notification sent to customer informing them of new materials regarding their investment.",
  initial_corporate_action_election_in_app:
    "Initial notice sent to customer informing them of a new voluntary corporate action.",
};
