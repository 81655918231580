import { ColumnType, Row } from "@asayinc/component-library";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

import { MailingView, MAILING_VIEW } from "src/pages/Mailings";
import { IProxyMailing } from "src/store/proxy/types";
import { PROXY_LABEL } from "src/constants/labels";
import { maxDate, minDate } from "src/utils/compareDates";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const APPROVED_COLUMNS = [
  "type",
  "issuer",
  "scheduledStart",
  "scheduledFinish",
  "delivered",
  "vote",
  "fail",
];

export const NOT_YET_APPROVED_COLUMNS = ["type", "issuer", "totalCustomers"];

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
};

export type ApprovedColumnData = {
  scheduledStart: ColumnType;
  scheduledFinish: ColumnType;
  issuer: ColumnType;
  type: ColumnType;
  delivered: ColumnType;
  vote: ColumnType;
  fail: ColumnType;
};

export type NotYetApprovedColumnData = {
  issuer: ColumnType;
  type: ColumnType;
  totalCustomers: ColumnType;
};

export function getColumns(view: MailingView) {
  if (view === MAILING_VIEW.APPROVED) {
    return APPROVED_COLUMNS;
  } else {
    return NOT_YET_APPROVED_COLUMNS;
  }
}

export function getColumnData(
  view: string,
  { getSort, onOrderingChange }: ColumnDataHelpers
): ApprovedColumnData | NotYetApprovedColumnData {
  if (view === MAILING_VIEW.APPROVED) {
    return {
      type: {
        sort: getSort("type"),
        sortFn: (data: unknown) => onOrderingChange(data, "type"),
        style: "text",
        title: "Type",
        tdBodyProps: {
          textProps: {
            weight: 600,
          },
        },
        width: "22%",
      },
      issuer: {
        sort: getSort("issuer__name"),
        sortFn: (data: unknown) => onOrderingChange(data, "issuer__name"),
        style: "text",
        title: "Issuer",
        tdBodyProps: {
          textProps: {
            weight: 600,
          },
        },
        width: "22%",
      },
      scheduledStart: {
        sort: getSort("initial_start_scheduled_send_at"),
        sortFn: (data: unknown) =>
          onOrderingChange(data, "initial_start_scheduled_send_at"),
        style: "text",
        title: "Scheduled start",
        width: "11%",
      },
      scheduledFinish: {
        sort: getSort("initial_end_scheduled_send_at"),
        sortFn: (data: unknown) =>
          onOrderingChange(data, "initial_end_scheduled_send_at"),
        style: "text",
        title: "Scheduled finish",
        width: "11%",
      },
      delivered: {
        sort: getSort("unique_customer_count"),
        sortFn: (data: unknown) =>
          onOrderingChange(data, "unique_customer_count"),
        style: "text",
        title: "Customers",
        width: "11%",
      },
      vote: {
        sort: getSort("vote_count"),
        sortFn: (data: unknown) => onOrderingChange(data, "vote_count"),
        style: "text",
        title: "Vote",
        width: "11%",
      },
      fail: {
        sort: getSort("efailure_count"),
        sortFn: (data: unknown) => onOrderingChange(data, "efailure_count"),
        style: "text",
        title: "Fail",
        width: "11%",
      },
    };
  } else {
    return {
      type: {
        sort: getSort("type"),
        sortFn: (data: unknown) => onOrderingChange(data, "type"),
        style: "text",
        title: "Type",
        tdBodyProps: {
          textProps: {
            weight: 600,
          },
        },
      },
      issuer: {
        sort: getSort("issuer__name"),
        sortFn: (data: unknown) => onOrderingChange(data, "issuer__name"),
        style: "text",
        title: "Issuer",
        tdBodyProps: {
          textProps: {
            weight: 600,
          },
        },
      },
      totalCustomers: {
        sort: getSort("unique_customer_count"),
        sortFn: (data: unknown) =>
          onOrderingChange(data, "unique_customer_count"),
        style: "text",
        title: "Customers",
      },
    };
  }
}

export function getRowData(
  view: string,
  mailings: IProxyMailing[],
  onRowClick: (mailing: IProxyMailing) => void
): Row[] {
  const handleRowClick = (data: unknown) => {
    const { mailing } = data as {
      mailing: IProxyMailing;
      idx: string;
    };
    onRowClick(mailing);
  };

  if (view === MAILING_VIEW.APPROVED) {
    return mailings.map((mailing, idx) => {
      const {
        id,
        securities,
        stats: {
          initialStartScheduledSendAt,
          initialWebhookStartScheduledSendAt,
          initialEndScheduledSendAt,
          initialWebhookEndScheduledSendAt,
          uniqueCustomerCount,
          voteCount,
          efailureCount,
        },
        type,
      } = mailing;

      const scheduledStart = minDate(
        initialStartScheduledSendAt,
        initialWebhookStartScheduledSendAt
      );

      const scheduledEnd = maxDate(
        initialEndScheduledSendAt,
        initialWebhookEndScheduledSendAt
      );

      return {
        id,
        data: { mailing, idx },
        rowData: {
          scheduledStart: {
            content: scheduledStart
              ? dayjs.utc(scheduledStart).local().fromNow()
              : "-",
            clickHandler: handleRowClick,
          },
          scheduledFinish: {
            content: scheduledEnd
              ? dayjs.utc(scheduledEnd).format("YYYY-MM-DD")
              : "-",
            clickHandler: handleRowClick,
          },
          issuer: {
            content: securities[0] ? securities[0].issuerName : "-",
            clickHandler: handleRowClick,
          },
          type: {
            content: PROXY_LABEL[type],
            clickHandler: handleRowClick,
          },
          delivered: {
            content: uniqueCustomerCount?.toLocaleString() || 0,
            clickHandler: handleRowClick,
          },
          vote: {
            content: voteCount?.toLocaleString() || 0,
            clickHandler: handleRowClick,
          },
          fail: {
            content: efailureCount?.toLocaleString() || 0,
            clickHandler: handleRowClick,
          },
        },
      };
    });
  } else {
    return mailings.map((mailing, idx) => {
      const {
        id,
        securities,
        stats: { uniqueCustomerCount },
        type,
      } = mailing;

      return {
        id,
        data: { mailing, idx },
        rowData: {
          issuer: {
            content: securities[0] ? securities[0].issuerName : "-",
            clickHandler: handleRowClick,
          },
          type: {
            content: PROXY_LABEL[type],
            clickHandler: handleRowClick,
          },
          totalCustomers: {
            content: uniqueCustomerCount || 0,
            clickHandler: handleRowClick,
          },
        },
      };
    });
  }
}
