import { Box, Stack } from "@mui/material";
import {
  MiniDrawer,
  removeAuthToken,
  SayIcon,
} from "@asayinc/component-library";
import { Mail, MailOutline, HelpOutlineOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { SearchPanel } from "src/components/organisms/SearchPanel";
import { useUserQuery } from "src/store/user";

// NOTE: zIndex of 1250 is between the zIndex of the SearchPanel(1200px) and ProfileMenu(1300px)
// Reason for this:
// 1. Since the SearchPanel has a lower zIndex than the SideNavBar, this makes it appear like the
// SearchPanel is come out from the right of (or under) the SideNavBar as opposed to sliding in
// from the left of the screen on top of the SideNavBar.
// 2. Since the zIndex of the SideNavBar is lower than the ProfileMenu this allows us
// to click the SideNavBar and close the ProfileMenu.
//
// NOTE: The onClick handler on the Box exists so we can close the SearchPanel when
// we click on the SideNavBar. The SearchPanel didn't close without this because
// we have a left-margin of 200px on the modal backdrop and the zIndex of the SideNavBar
// is higher than the SearchPanel.

export function SideNavigationBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user } = useUserQuery();
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);
  const currentModule = location.pathname.split("/")[1];

  const handleCloseSearchPanel = () => {
    if (isSearchDrawerOpen) {
      setIsSearchDrawerOpen(false);
    }
  };

  const items = [
    {
      value: "search",
      active: isSearchDrawerOpen,
      icon: isSearchDrawerOpen ? (
        <SayIcon name="SearchFill" color="#1968FF" />
      ) : (
        <SayIcon name="SearchOutline" />
      ),
      text: "Search",
    },
    {
      value: "/mailings/proxy?view=approved",
      active: !isSearchDrawerOpen && currentModule.includes("mailings"),
      icon:
        !isSearchDrawerOpen && currentModule.includes("mailings") ? (
          <Mail />
        ) : (
          <MailOutline />
        ),
      text: "Mailings",
    },
  ];

  const footerItems = [
    {
      value: "help",
      active: false,
      icon: <HelpOutlineOutlined />,
      text: "Help",
    },
  ];

  const handleOnNavItemClick = (value: string) => {
    if (value === "search") {
      setIsSearchDrawerOpen(!isSearchDrawerOpen);
    } else if (value === "help") {
      window.open(
        `mailto:support@saytechnologies.com?subject=Broker Portal Support`
      );
    } else {
      setIsSearchDrawerOpen(false);
      navigate(value as string);
    }
  };

  // clear both auth tokens and refresh tokens
  const handleLogout = () => {
    removeAuthToken();
    window.localStorage.clear();
    navigate("/login");
  };

  return (
    <Stack direction="row">
      <Box
        sx={{ display: "flex", flexGrow: 1, zIndex: 1250 }}
        onClick={handleCloseSearchPanel}
      >
        <MiniDrawer
          showSayLogo
          expandedWidth="200px"
          footerItems={footerItems}
          items={items}
          listActiveBg
          profileProps={{
            logoutAction: handleLogout,
            companyLogo: user?.broker?.logo || "",
            companyName: user?.broker?.name || "",
            name: `${user?.firstName || ""} ${user?.lastName || ""}`,
            email: user?.email || "",
            isBrokerPortal: true,
          }}
          onClickItem={handleOnNavItemClick}
        />
      </Box>
      <Box position="relative">
        <SearchPanel
          isOpen={isSearchDrawerOpen}
          onClose={handleCloseSearchPanel}
        />
      </Box>
    </Stack>
  );
}
