import { LoadingSkeleton } from "@asayinc/component-library";
import { Stack, SxProps } from "@mui/material";

import { Card } from "src/components/atoms/Card";

interface ILoadingStatsHeaderCardProps {
  sx?: SxProps;
}

export function LoadingStatsHeaderCard({ sx }: ILoadingStatsHeaderCardProps) {
  return (
    <Card sx={sx} title={undefined} testId="loading-stats-header">
      <Stack sx={{ mt: 6 }} direction="row">
        <LoadingSkeleton sx={{ mr: 4 }} />
        <LoadingSkeleton sx={{ mr: 4 }} />
        <LoadingSkeleton sx={{ mr: 4 }} />
        <LoadingSkeleton />
      </Stack>
    </Card>
  );
}
