import { Tabs } from "@asayinc/component-library";
import { Box } from "@mui/material";

import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";

import { BackLink } from "src/components/atoms/BackLink";
import { PageContainer } from "src/components/containers/PageContainer";
import { IssuerHeader } from "src/components/molecules/IssuerHeader";
import { ISSUER_DETAILS_TABS } from "src/constants/tabs";
import { useIssuerQuery } from "src/store/issuer";

export type IssuerDetailView = "approved" | "upcoming";

export const ISSUER_DETAIL_VIEW: { [key: string]: IssuerDetailView } = {
  APPROVED: "approved",
  TO_BE_APPROVED: "upcoming",
};

export function IssuerDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams() as { id: string };
  const match = useMatch(`/issuers/${id}/:activeTab`) as {
    params: { activeTab: string };
  };
  const { data: issuer } = useIssuerQuery({ id });

  const handleTabChange = (value: string) => {
    navigate(`/issuers/${id}/${value}`, { state: { ...location.state } });
  };

  return (
    <PageContainer>
      <BackLink sx={{ position: "absolute", pl: 8, pt: 10 }} />
      <IssuerHeader
        issuerName={issuer?.name}
        securities={issuer?.securities}
        sx={{ px: 10, pt: 10 }}
      />
      <Box sx={{ mx: 10, mt: 6 }}>
        <Tabs
          activeTab={match?.params?.activeTab || "proxy"}
          centered
          clickHandler={handleTabChange}
          tabs={ISSUER_DETAILS_TABS}
        />
      </Box>
      <Outlet />
    </PageContainer>
  );
}
