import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function getStartDateContent(date: string) {
  const today = dayjs();
  const startDate = dayjs(date);
  const relativeTime = startDate.fromNow();

  if (startDate.isBefore(today)) {
    return `Started ${relativeTime}`;
  } else {
    return `Starts ${relativeTime}`;
  }
}

export function getEndDateContent(date: string) {
  const today = dayjs();
  const endDate = dayjs(date);
  const relativeTime = endDate.fromNow();

  if (endDate.isBefore(today)) {
    return `Finished ${relativeTime}`;
  } else {
    return `Finishes ${relativeTime}`;
  }
}
