import { NotificationStatus } from "src/store/types";

export const NOTIFICATION_STATUS_LABEL: {
  [key in NotificationStatus]: NotificationStatusLabel;
} = {
  sent: "Delivered",
  email_preview: "Delivered",
  unsubscribed: "Delivered",
  error: "Error",
  will_not_send: "Error",
  no_subject_found: "Error",
  no_template_found: "Error",
  no_webhook_url_found: "Error",
  invalid_email: "Hard bounce",
  hard_bounce: "Hard bounce",
  blocked: "Hard bounce",
  unknown: "Soft bounce",
  soft_bounce: "Soft bounce",
  transient: "Soft bounce",
  dns_error: "Soft bounce",
  spam_notification: "Soft bounce",
  spam_complaint: "Soft bounce",
  none: "Processing",
  queued: "Processing",
  processing: "Processing",
};

export type NotificationStatusLabel =
  | "Hard bounce"
  | "Soft bounce"
  | "Processing"
  | "Error"
  | "Delivered";
