import { getAuthToken } from "@asayinc/component-library";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AppContainer } from "./components/containers/AppContainer/AppContainer";
import { CorporateActionDetail } from "./pages/CorporateActionDetail";

import { Login } from "./pages/Login";
import { Mailings } from "./pages/Mailings";
import { CorporateAction } from "./pages/Mailings/CorporateAction";
import { Proxy } from "./pages/Mailings/Proxy";
import { CustomersTab as ProxyCustomersTab } from "./pages/ProxyDetail/CustomersTab";
import { CustomersTab as CorporateActionCustomersTab } from "./pages/CorporateActionDetail/CustomersTab";
import { ProxyDetail } from "./pages/ProxyDetail/ProxyDetail";
import { NotificationsTab as ProxyNotificationsTab } from "./pages/ProxyDetail/NotificationsTab/NotificationsTab";
import { NotificationsTab as CorporateActionNotficationsTab } from "./pages/CorporateActionDetail/NotificationsTab";
import { MeetingInfoTab as ProxyMeetingInfoTab } from "./pages/ProxyDetail/MeetingInfoTab";
import { OfferInfoTab as CorporateActionOfferInfoTab } from "./pages/CorporateActionDetail/OfferInfoTab";
import { InsightsTab as ProxyInsightsTab } from "./pages/ProxyDetail/InsightsTab";
import { InsightsTab as CorporateActionInsightsTab } from "./pages/CorporateActionDetail/InsightsTab";
import { Proxy as IssuerProxyTab } from "./pages/IssuerDetail/Proxy";
import { CorporateAction as IssuerCorporateActionTab } from "./pages/IssuerDetail/CorporateAction";
import { CustomerDetail } from "./pages/CustomerDetail";
import { MailingsTab as CustomerMailingsTab } from "./pages/CustomerDetail/MailingsTab";
import { NotificationsTab as CustomerNotificationsTab } from "./pages/CustomerDetail/NotificationsTab";
import { IssuerDetail } from "./pages/IssuerDetail";
import { ForgotPassword } from "./pages/ForgotPassword";
import { HomePage } from "./pages/HomePage";

export function App() {
  const isNotLoggedIn = !getAuthToken();
  const { pathname } = useLocation();

  // Navigate user to login page if they dont have an auth token
  if (
    isNotLoggedIn &&
    pathname !== "/login" &&
    pathname !== "/forgot-password" &&
    pathname !== "/reset-password"
  ) {
    return <Navigate replace to={{ pathname: "/login" }} />;
  }

  return (
    <Routes>
      <Route path="/" element={<AppContainer />}>
        <Route index element={<HomePage />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="mailings" element={<Mailings />}>
          <Route path="proxy" element={<Proxy />} />
          <Route path="corporate-action" element={<CorporateAction />} />
          <Route
            index
            path="*"
            element={
              <Navigate
                replace
                to="/mailings/proxy"
                state={{ view: "approved" }}
              />
            }
          />
        </Route>
        <Route path="mailings/proxy/:id" element={<ProxyDetail />}>
          <Route path="customers" element={<ProxyCustomersTab />} />
          <Route path="notifications" element={<ProxyNotificationsTab />} />
          <Route path="insights" element={<ProxyInsightsTab />} />
          <Route path="meeting-info" element={<ProxyMeetingInfoTab />} />
          <Route
            index
            path="*"
            element={<Navigate replace to="/mailings/proxy/:id/customers" />}
          />
        </Route>
        <Route
          path="mailings/corporate-action/:id"
          element={<CorporateActionDetail />}
        >
          <Route path="customers" element={<CorporateActionCustomersTab />} />
          <Route
            path="notifications"
            element={<CorporateActionNotficationsTab />}
          />
          <Route path="insights" element={<CorporateActionInsightsTab />} />
          <Route path="offer-info" element={<CorporateActionOfferInfoTab />} />
          <Route
            index
            path="*"
            element={
              <Navigate replace to="/mailings/corporate-action/:id/customers" />
            }
          />
        </Route>
        <Route path="customers/:id" element={<CustomerDetail />}>
          <Route path="mailings" element={<CustomerMailingsTab />} />
          <Route path="notifications" element={<CustomerNotificationsTab />} />
        </Route>
        <Route path="issuers/:id" element={<IssuerDetail />}>
          <Route path="proxy" element={<IssuerProxyTab />} />
          <Route
            path="corporate-action"
            element={<IssuerCorporateActionTab />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
