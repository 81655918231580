import { Text } from "@asayinc/component-library";
import { Box, Stack, SxProps } from "@mui/material";
import { Card } from "src/components/atoms/Card";

interface IMailingStatsBarProps {
  communicationCount: number;
  customerCount: number;
  eventCount: number;
  failureCount: number;
  isUpcoming?: boolean;
  sx?: SxProps;
}

export function MailingStatsBar({
  communicationCount,
  customerCount,
  eventCount,
  failureCount,
  isUpcoming = false,
  sx,
}: IMailingStatsBarProps) {
  return (
    <Card
      sx={{ ...sx, p: 8 }}
      title={isUpcoming ? "Upcoming (estimated)" : "From last 30 days"}
    >
      <Stack sx={{ px: 6 }} direction="row">
        <Box sx={{ flexGrow: 1, flexBasis: 1 }}>
          <Text variant="subtitle1" sx={{ mb: 3 }}>
            Mailings
          </Text>
          <Text variant="h5" data-testid="mailing-stat">
            {eventCount.toLocaleString() || 0}
          </Text>
        </Box>
        <Box sx={{ flexGrow: 1, flexBasis: 1 }}>
          <Text variant="subtitle1" sx={{ mb: 3 }}>
            Customers
          </Text>
          <Text variant="h5" data-testid="customer-stat">
            {customerCount.toLocaleString() || 0}
          </Text>
        </Box>
        <Box sx={{ flexGrow: 1, flexBasis: 1 }}>
          <Text variant="subtitle1" sx={{ mb: 3 }}>
            {isUpcoming ? "Estimated failure rate" : "Average failure rate"}
          </Text>
          <Text variant="h5" data-testid="failure-stat">
            {failureCount && communicationCount
              ? ((failureCount / communicationCount) * 100).toFixed(2)
              : 0}
            %
          </Text>
        </Box>
      </Stack>
    </Card>
  );
}
