import { SecondaryTabs, TableV2 as Table } from "@asayinc/component-library";
import {
  FilterList,
  Verified,
  VerifiedOutlined,
  WatchLater,
  WatchLaterOutlined,
} from "@mui/icons-material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import { ROW_OPTIONS, PROXY_OPTIONS } from "src/constants/options";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { useProxyEventsQuery } from "src/store/proxy";
import { IProxyMailing } from "src/store/proxy/types";
import { hasMeetingInfo } from "src/utils/hasMeetingInfo";
import { ISSUER_DETAIL_VIEW, IssuerDetailView } from "../IssuerDetail";
import { getColumnData, getColumns, getRowData } from "./utils";
import { EmptyState } from "src/components/molecules/EmptyState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { Stack } from "@mui/material";

export function Proxy() {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm();
  const { type } = useTableSearchParams();
  const view =
    (searchParams.get(TABLE_SEARCH_PARAMS.VIEW) as IssuerDetailView) ||
    "approved";

  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();

  const { data, isFetching, isLoading } = useProxyEventsQuery({
    offset,
    limit,
    ordering: ordering ? ordering : "-approved_at",
    view,
    type,
    issuerId: id,
  });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const handleRowClick = (mailing: IProxyMailing) => {
    const { id, type } = mailing;
    if (hasMeetingInfo(type)) {
      navigate(`/mailings/proxy/${id}/meeting-info`);
    } else {
      navigate(`/mailings/proxy/${id}/customers`);
    }
  };

  const handleSecondaryTabChange = (tab: string) => {
    const newParams = new URLSearchParams({
      view: tab,
    });
    setSearchParams(newParams);
  };

  const columns = useMemo(() => getColumns(view), [view]);
  const columnData = useMemo(() => {
    return getColumnData(view, {
      getSort,
      onOrderingChange,
    });
  }, [view, getSort, onOrderingChange]);
  const rowData = getRowData(view, data?.results || [], handleRowClick);

  const filterList = (
    <FormProvider {...methods}>
      <TableMultiSelectFilter
        fieldName={TABLE_SEARCH_PARAMS.TYPE}
        label="Types"
        options={PROXY_OPTIONS}
        queryParameter={TABLE_SEARCH_PARAMS.TYPE}
        view={view}
      />
    </FormProvider>
  );

  if (data?.count === 0 && !type) {
    return (
      <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
        <EmptyState
          title="No mailings to display yet."
          subtitle={
            view === "approved"
              ? "When this issuer has approved proxy mailings, they will appear here."
              : "When this issuer has to-be-approved proxy mailings, they will appear here."
          }
        />
      </ScrollContainer>
    );
  }

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Stack sx={{ pb: 8 }}>
        <SecondaryTabs
          sx={{ mx: "auto" }}
          tabs={[
            {
              label: "Approved",
              value: ISSUER_DETAIL_VIEW.APPROVED,
              icon:
                view === ISSUER_DETAIL_VIEW.APPROVED ? (
                  <Verified style={{ color: "#1968FF" }} />
                ) : (
                  <VerifiedOutlined />
                ),
            },
            {
              label: "To be approved",
              value: ISSUER_DETAIL_VIEW.TO_BE_APPROVED,
              icon:
                view === ISSUER_DETAIL_VIEW.TO_BE_APPROVED ? (
                  <WatchLater style={{ color: "#1968FF" }} />
                ) : (
                  <WatchLaterOutlined />
                ),
            },
          ]}
          activeTab={view}
          clickHandler={handleSecondaryTabChange}
        />
      </Stack>
      <Table
        count={data?.count || 0}
        columnData={columnData}
        columns={columns}
        isFetching={isFetching}
        isLoading={isLoading}
        noResultsData={{
          title: "No proxy mailings found",
          subtitle:
            "Sorry, there are no mailings that match your applied filters.",
        }}
        paginateProps={paginateProps}
        rows={rowData}
        tableMinWidth={1100}
        title={`${data?.count || 0} proxy mailings`}
        titleVariant="subtitle2"
        toolbarInside
        toolbarCollapse={{
          Icon: FilterList,
          collapseContent: filterList,
          isFlat: true,
        }}
      />
    </ScrollContainer>
  );
}
