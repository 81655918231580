import { AutoCompleteOption } from "@asayinc/component-library";

export const ELECTION_STATUS_OPTIONS: AutoCompleteOption[] = [
  {
    name: "Not Viewed",
    id: "not_viewed",
  },
  {
    name: "Viewed",
    id: "viewed",
  },
  {
    name: "Online",
    id: "online",
  },
];
