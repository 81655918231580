import {
  ForgotPassword as ForgotPasswordComponent,
  ForgotPasswordConfirmation,
  MessageContainer,
} from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useSubmitEmailMutation } from "src/store/sso";

export function ForgotPassword() {
  document.title = "Forgot Password | Say Issuer Portal";
  const [submitEmail, response] = useSubmitEmailMutation();

  /**
   * submit form data to forgot password api
   */
  const submitForm = (data: { email: string }) => {
    submitEmail(data);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MessageContainer>
        {response.isSuccess ? (
          <ForgotPasswordConfirmation />
        ) : (
          <ForgotPasswordComponent submitForm={submitForm} />
        )}
      </MessageContainer>
    </Box>
  );
}
