import { Tag, COLORS, RadioButton, Text } from "@asayinc/component-library";
import { Box, SxProps } from "@mui/material";

interface IBallotProps {
  choices: string[];
  details: string;
  sx?: SxProps;
  testId?: string;
  title: string;
  type: string;
}

export function Ballot({
  choices,
  details,
  sx,
  testId,
  title,
  type,
}: IBallotProps) {
  return (
    <Box
      data-testid={testId}
      sx={{ borderRadius: 2, border: "1px solid #e0e0e0", p: 6, ...sx }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Text
          variant="subtitle2"
          sx={{ flexGrow: 1 }}
          data-testid="ballot-title"
        >
          {title}
        </Text>
        <Box data-testid="ballot-tag">
          <Tag
            bgcolor={COLORS.SHADED_GREY}
            fontCategory="onsurface"
            label={type}
          />
        </Box>
      </Box>
      <Text sx={{ mt: 3 }} variant="body2" data-testid="ballot-details">
        {details}
      </Text>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          mt: 6,
        }}
      >
        {choices.map((choice, index) => {
          return (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexGrow: 1,
                mr: choices.length - 1 !== index ? 4 : undefined,
              }}
              key={index}
              data-testid={`ballot-choice-${index}`}
            >
              <RadioButton sx={{ mr: 2, p: 0 }} disabled size="small" />{" "}
              <Text variant="body2" textTransform="capitalize">
                {choice.replaceAll("_", " ")}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
