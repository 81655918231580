import { nFormatter } from "@asayinc/component-library";
import { SxProps } from "@mui/material";

import { Card } from "src/components/atoms/Card";
import { Position } from "src/store/types";
import { LabelField } from "../LabelField";

interface IPositionCardProps {
  cardTitle?: string;
  position: Position;
  showProxyEligibleShares?: boolean;
  sx?: SxProps;
}

export function PositionCard({
  cardTitle = "Position",
  position,
  showProxyEligibleShares = false,
  sx,
}: IPositionCardProps) {
  const {
    id,
    recordDate,
    security: { cusip, issuerName },
    accountIdentifier,
    proxyEligibleShares,
    sharesOwned,
  } = position;
  const shares = showProxyEligibleShares ? proxyEligibleShares : sharesOwned;

  return (
    <Card sx={sx} title={cardTitle} testId={`position-card-${id}`}>
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Record date"
        value={recordDate || "-"}
      />
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Security"
        value={issuerName || "-"}
      />
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Security CUSIP"
        value={cusip || "-"}
      />
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Account"
        value={accountIdentifier || "-"}
      />
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Own"
        value={`${nFormatter(Number(shares), true)} shares`}
      />
    </Card>
  );
}
