import { Text } from "@asayinc/component-library";
import { Box, Stack, SxProps } from "@mui/material";

interface IErrorStateProps {
  sx?: SxProps;
}

export function ErrorState({ sx }: IErrorStateProps) {
  return (
    <Stack
      sx={sx}
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-testid="error-state"
      flexGrow={1}
    >
      <img
        width={400}
        src={`${process.env.REACT_APP_ASSETS}images/broker-portal/error_general.svg`}
      />
      <Box sx={{ mt: 20, textAlign: "center" }}>
        <Text data-testid="error-state-title" variant="subtitle1">
          Uh oh!
        </Text>
        <Text sx={{ mt: 3 }} data-testid="error-state-subtitle" variant="body1">
          Something went wrong. Come back and let&apos;s try again later!
        </Text>
      </Box>
    </Stack>
  );
}
