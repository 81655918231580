import { ProxyNotificationType } from "src/store/proxy/types";

export const PROXY_NOTIFICATION_TOOLTIPS: {
  [key in ProxyNotificationType]: string;
} = {
  additional_mailing_informational:
    "An additional mailing was sent to this customer regarding a mailing they've already received.",
  additional_mailing_vote_reminder:
    "An additional mailing was sent to this customer to remind them to vote.",
  confirmation_proxy_vote: "Sent to the customer after they submitted a vote.",
  efailure_consent_solicitation:
    "Postal mail was sent to this customer since we couldn't reach them via email.",
  efailure_proxy_informational:
    "Postal mail was sent to this customer since we couldn't reach them via email.",
  efailure_proxy_vote:
    "Postal mail was sent to this customer since we couldn't reach them via email.",
  initial_consent_solicitation:
    "Initial email sent to customer notifying them of a consent solicitation.",
  initial_proxy_informational:
    "Initial email sent to customer notifying them of new materials regarding their investment.",
  initial_proxy_vote:
    "Initial email sent to customer inviting them to vote in a meeting.",
  initial_proxy_non_votable: "",
  resent_initial_consent_solicitation:
    "A re-send of a previous email was sent to the customer upon their request.",
  resent_initial_proxy_informational:
    "A re-send of a previous email was sent to the customer upon their request.",
  resent_initial_proxy_vote:
    "A re-send of a previous email was sent to the customer upon their request.",
  resent_initial_proxy_non_votable:
    "A re-send of a previous email was sent to the customer upon their request.",
  additional_mailing_informational_webhook:
    "Supplemental information sent to this customer regarding a mailing they've already received.",
  additional_mailing_vote_reminder_webhook:
    "An additional notification sent to this customer to remind them to vote.",
  confirmation_proxy_vote_webhook:
    "Sent to the customer after they submitted a vote.",
  efailure_proxy_non_votable:
    "Postal mail was sent to this customer since we couldn't reach them via email.",
  initial_consent_solicitation_webhook:
    "Initial notice sent to customer informing them of a consent solicitation.",
  initial_proxy_informational_webhook:
    "Initial notice sent to customer informing them of new materials regarding their investment.",
  initial_proxy_vote_webhook:
    "Initial notification sent to customer inviting them to vote in a meeting.",
  initial_proxy_non_votable_webhook:
    "Initial notification sent to customer regarding an upcoming meeting.",
  initial_contested_proxy_vote: "",
  initial_contested_proxy_vote_webhook: "",
  efailure_contested_proxy_vote: "",
  additional_mailing_contesting_party_announcement: "",
  additional_mailing_contesting_party_announcement_webhook: "",
  additional_mailing_contesting_party_vote_reminder: "",
  additional_mailing_contesting_party_vote_reminder_webhook: "",
  additional_mailing_contesting_party_informational: "",
  additional_mailing_contesting_party_informational_webhook: "",
  resent_initial_contested_proxy_vote: "",
  initial_proxy_vote_in_app:
    "Initial notification sent to customer inviting them to vote in a meeting.",
  initial_contested_proxy_vote_in_app: "",
  initial_consent_solicitation_in_app:
    "Initial notification sent to customer informing them of a consent solicitation.",
  additional_mailing_contesting_party_announcement_in_app: "",
  additional_mailing_contesting_party_reminder_in_app: "",
  additional_mailing_contesting_party_informational_in_app: "",
  additional_mailing_vote_reminder_in_app: "",
  additional_mailing_informational_in_app: "",
  initial_proxy_informational_in_app:
    "Initial notification sent to customer notifying them of new materials regarding their investment.",
};
