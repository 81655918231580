import { IProxyEvent } from "src/store/proxy/types";

export function isVotableEvent(proxyEvent: IProxyEvent) {
  if (proxyEvent) {
    const { type } = proxyEvent;
    return (
      type === "consent_solicitation" ||
      type === "contested_annual_meeting" ||
      type === "annual_meeting" ||
      type === "contested_special_meeting" ||
      type === "special_meeting"
    );
  }
  return false;
}
