import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import { ErrorState } from "src/components/atoms/ErrorState";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import { LoadingCard } from "src/components/molecules/Loading/LoadingCard";
import { MeetingDetailsCard } from "src/components/molecules/MeetingDetailsCard";
import { ProposalsCard } from "src/components/molecules/ProposalsCard";
import { useProxyEventQuery } from "src/store/proxy";

export function MeetingInfoTab() {
  const { id } = useParams() as { id: string };
  const { data: proxyEvent, isLoading, error } = useProxyEventQuery({ id });

  if (error || (!isLoading && !proxyEvent)) {
    return <ErrorState />;
  }

  if (isLoading) {
    return (
      <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
        <Stack direction="row">
          <LoadingCard sx={{ mr: 12, flexGrow: 1, flexBasis: 0 }} />
          <LoadingCard sx={{ flexGrow: 1, flexBasis: 0 }} />
        </Stack>
      </ScrollContainer>
    );
  }

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Stack direction="row">
        <Box sx={{ flexGrow: 1, minWidth: "50%" }}>
          <MeetingDetailsCard
            sx={{ mr: 12 }}
            cutOffTime={proxyEvent.voteCutoff}
            date={proxyEvent.meetingDate}
            meetingFormats={proxyEvent.meetingFormats}
            numOfProposals={String(proxyEvent.proposals.length)}
          />
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: "50%" }}>
          <ProposalsCard
            sx={{ flexGrow: 1, flexBasis: 0 }}
            proposals={proxyEvent.proposals}
          />
        </Box>
      </Stack>
    </ScrollContainer>
  );
}
