import { ProxyNotificationType } from "src/store/proxy/types";

export const PROXY_NOTIFICATION_LABEL: {
  [key in ProxyNotificationType]: string;
} = {
  additional_mailing_informational: "Additional mailing",
  additional_mailing_vote_reminder: "Vote reminder",
  confirmation_proxy_vote: "Vote confirmation",
  efailure_consent_solicitation: "Solicitation notice e-fail",
  efailure_proxy_informational: "Document delivery e-fail",
  efailure_proxy_vote: "Ballot delivery e-fail",
  initial_consent_solicitation: "Solicitation notice",
  initial_proxy_informational: "Document delivery",
  initial_proxy_vote: "Ballot delivery",
  initial_proxy_non_votable: "Meeting notice",
  resent_initial_consent_solicitation: "Solicitation re-send",
  resent_initial_proxy_informational: "Document re-send",
  resent_initial_proxy_vote: "Ballot re-send",
  resent_initial_proxy_non_votable: "Meeting notice re-send",
  confirmation_proxy_vote_webhook: "Vote confirmation webhook",
  additional_mailing_informational_webhook:
    "Additional mailing informational webhook",
  additional_mailing_vote_reminder_webhook: "Follow-up vote reminder webhook",
  initial_consent_solicitation_webhook: "Solicitation notice webhook",
  initial_proxy_informational_webhook: "Document delivery webhook",
  initial_proxy_vote_webhook: "Ballot delivery webhook",
  efailure_proxy_non_votable: "Meeting notice e-fail",
  initial_proxy_non_votable_webhook: "Meeting notice webhook",
  initial_contested_proxy_vote: "Ballot delivery",
  initial_contested_proxy_vote_webhook: "Ballot delivery webhook",
  efailure_contested_proxy_vote: "Ballot delivery e-fail",
  additional_mailing_contesting_party_announcement: "Dissident mailing",
  additional_mailing_contesting_party_announcement_webhook:
    "Dissident mailing webhook",
  additional_mailing_contesting_party_vote_reminder: "Vote reminder",
  additional_mailing_contesting_party_vote_reminder_webhook:
    "Vote reminder webhook",
  additional_mailing_contesting_party_informational: "Additional mailing",
  additional_mailing_contesting_party_informational_webhook:
    "Additional mailing webhook",
  resent_initial_contested_proxy_vote: "Ballot delivery re-send",
  initial_proxy_vote_in_app: "Ballot delivery",
  initial_contested_proxy_vote_in_app: "Ballot delivery",
  initial_consent_solicitation_in_app: "Solicitation notice",
  additional_mailing_contesting_party_announcement_in_app: "Dissident mailing",
  additional_mailing_contesting_party_reminder_in_app: "Vote reminder",
  additional_mailing_contesting_party_informational_in_app:
    "Additional mailing",
  additional_mailing_vote_reminder_in_app: "Vote reminder",
  additional_mailing_informational_in_app: "Additional mailing",
  initial_proxy_informational_in_app: "Document delivery",
};
