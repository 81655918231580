import { SxProps } from "@mui/material";
import { Card } from "src/components/atoms/Card";
import { PROPOSAL_LABEL } from "src/constants/labels";
import { Proposal as ProposalType } from "src/store/proxy/types";
import { Ballot } from "../Ballot";

interface IProposalsCardProps {
  proposals: ProposalType[];
  sx?: SxProps;
}

export function ProposalsCard({ proposals, sx }: IProposalsCardProps) {
  return (
    <Card title="Proposals" testId="proporsals-card" sx={sx}>
      {proposals.map((proposal, index) => {
        const { title, choices, details, type, groupNumber, proposalNumber } =
          proposal;
        return (
          <Ballot
            key={index}
            title={title}
            choices={choices}
            details={details}
            sx={{ mt: 4 }}
            testId={`proposal-${groupNumber}-${proposalNumber}`}
            type={PROPOSAL_LABEL[type]}
          />
        );
      })}
    </Card>
  );
}
