import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export function formatDate(
  date: string | dayjs.Dayjs,
  includeTime = false,
  includeTimezone = false
) {
  if (includeTime && includeTimezone) {
    return dayjs(date).format("YYYY-M-D H:mm z");
  }

  if (includeTimezone) {
    return dayjs(date).format("YYYY-M-D z");
  }

  if (includeTime) {
    return dayjs(date).format("YYYY-M-D H:mm");
  }

  return dayjs(date).format("YYYY-M-D");
}
