import { ColumnType, Row } from "@asayinc/component-library";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

import { IProxyMailing } from "src/store/proxy/types";
import { IssuerDetailView, ISSUER_DETAIL_VIEW } from "../IssuerDetail";
import { PROXY_LABEL } from "src/constants/labels";
import { maxDate, minDate } from "src/utils/compareDates";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export type ColumnDataHelpers = {
  getSort: (sortKey: string) => "asc" | "desc" | undefined;
  onOrderingChange: (data: unknown, sortKey: string) => void;
};

type UpcomingColumnData = {
  type: ColumnType;
  totalCustomers: ColumnType;
};

type ApprovedColumnData = {
  type: ColumnType;
  approvedAt: ColumnType;
  scheduledStart: ColumnType;
  scheduledFinish: ColumnType;
  customers: ColumnType;
  vote: ColumnType;
  fails: ColumnType;
};

export function getColumns(view: IssuerDetailView) {
  if (view === ISSUER_DETAIL_VIEW.TO_BE_APPROVED) {
    return ["type", "totalCustomers"];
  }

  return [
    "type",
    "approvedAt",
    "scheduledStart",
    "scheduledFinish",
    "customers",
    "vote",
    "fails",
  ];
}

export function getColumnData(
  view: IssuerDetailView,
  { getSort, onOrderingChange }: ColumnDataHelpers
): UpcomingColumnData | ApprovedColumnData {
  if (view === ISSUER_DETAIL_VIEW.TO_BE_APPROVED) {
    return {
      type: {
        sort: getSort("type"),
        sortFn: (data: unknown) => onOrderingChange(data, "type"),
        style: "text",
        title: "Type",
        tdBodyProps: {
          textProps: {
            weight: 600,
          },
        },
      },
      totalCustomers: {
        sort: getSort("unique_customer_count"),
        sortFn: (data: unknown) =>
          onOrderingChange(data, "unique_customer_count"),
        style: "text",
        title: "Customers",
      },
    };
  }

  return {
    type: {
      sort: getSort("type"),
      sortFn: (data: unknown) => onOrderingChange(data, "type"),
      style: "text",
      title: "Type",
      tdBodyProps: {
        textProps: {
          weight: 600,
        },
      },
      width: "25%",
    },
    approvedAt: {
      sort: getSort("approved_at"),
      sortFn: (data: unknown) => onOrderingChange(data, "approved_at"),
      style: "text",
      title: "Approved at",
    },
    scheduledStart: {
      sort: getSort("initial_start_scheduled_send_at"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "initial_start_scheduled_send_at"),
      style: "text",
      title: "Scheduled start",
    },
    scheduledFinish: {
      sort: getSort("initial_end_scheduled_send_at"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "initial_end_scheduled_send_at"),
      style: "text",
      title: "Scheduled finish",
    },
    customers: {
      sort: getSort("unique_customer_count"),
      sortFn: (data: unknown) =>
        onOrderingChange(data, "unique_customer_count"),
      style: "text",
      title: "Customers",
    },
    vote: {
      sort: getSort("vote_count"),
      sortFn: (data: unknown) => onOrderingChange(data, "vote_count"),
      style: "text",
      title: "Vote",
    },
    fails: {
      sort: getSort("efailure_count"),
      sortFn: (data: unknown) => onOrderingChange(data, "efailure_count"),
      style: "text",
      title: "Fails",
    },
  };
}

export function getRowData(
  view: IssuerDetailView,
  mailings: IProxyMailing[],
  onRowClick: (mailing: IProxyMailing) => void
): Row[] {
  return mailings.map((mailing, idx) => {
    const handleRowClick = (data: unknown) => {
      const { mailing } = data as {
        mailing: IProxyMailing;
        idx: string;
      };
      onRowClick(mailing);
    };

    const {
      id,
      approvedAt,
      type,
      stats: {
        efailureCount,
        initialEndScheduledSendAt,
        initialWebhookEndScheduledSendAt,
        initialStartScheduledSendAt,
        initialWebhookStartScheduledSendAt,
        uniqueCustomerCount,
        voteCount,
      },
    } = mailing;

    const scheduledStart = minDate(
      initialStartScheduledSendAt,
      initialWebhookStartScheduledSendAt
    );

    const scheduledEnd = maxDate(
      initialEndScheduledSendAt,
      initialWebhookEndScheduledSendAt
    );

    let rowData = {};

    if (view === ISSUER_DETAIL_VIEW.TO_BE_APPROVED) {
      rowData = {
        type: {
          content: PROXY_LABEL[type] || "-",
          clickHandler: handleRowClick,
        },
        totalCustomers: {
          content: uniqueCustomerCount || 0,
          clickHandler: handleRowClick,
        },
      };
    } else {
      rowData = {
        type: {
          content: PROXY_LABEL[type] || "-",
          clickHandler: handleRowClick,
        },
        approvedAt: {
          content: approvedAt
            ? dayjs.utc(approvedAt).format("YYYY-MM-DD")
            : "-",
          clickHandler: handleRowClick,
        },
        scheduledStart: {
          content: scheduledStart
            ? dayjs.utc(scheduledStart).local().fromNow()
            : "-",
          clickHandler: handleRowClick,
        },
        scheduledFinish: {
          content: scheduledEnd
            ? dayjs.utc(scheduledEnd).format("YYYY-MM-DD")
            : "-",
          clickHandler: handleRowClick,
        },
        customers: {
          content: uniqueCustomerCount?.toLocaleString() || 0,
          clickHandler: handleRowClick,
        },
        vote: {
          content: voteCount?.toLocaleString() || 0,
          clickHandler: handleRowClick,
        },
        fails: {
          content: efailureCount?.toLocaleString() || 0,
          clickHandler: handleRowClick,
        },
      };
    }

    return {
      id,
      data: { mailing, idx },
      rowData,
    };
  });
}
