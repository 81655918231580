import { AutoCompleteOption } from "@asayinc/component-library";

export const PROXY_OPTIONS: AutoCompleteOption[] = [
  {
    name: "Consent Solicitation",
    id: "consent_solicitation",
  },
  {
    name: "Annual Meeting",
    id: "annual_meeting",
  },
  {
    name: "Contested Annual Meeting",
    id: "contested_annual_meeting",
  },
  {
    name: "Contested Special Meeting",
    id: "contested_special_meeting",
  },
  {
    name: "Special Meeting",
    id: "special_meeting",
  },
  {
    name: "Information Statement",
    id: "information_statement",
  },
  {
    name: "Summary Prospectus",
    id: "summary_prospectus",
  },
  {
    name: "19 A Notice",
    id: "19_a_notice",
  },
  {
    name: "Annual Report",
    id: "annual_report",
  },
  {
    name: "Semi Annual Report",
    id: "semi_annual_report",
  },
];
