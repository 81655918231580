import { SxProps } from "@mui/material";

import { Card } from "src/components/atoms/Card";
import { MEETING_FORMAT_LABEL } from "src/constants/labels";
import { MeetingFormat } from "src/store/proxy/types";
import { formatDate } from "src/utils/formatDate";
import { LabelField } from "../LabelField";

interface IMeetingDetailsCardProps {
  meetingFormats: MeetingFormat[];
  date: string;
  numOfProposals: string;
  cutOffTime: string;
  sx?: SxProps;
}

export function MeetingDetailsCard({
  meetingFormats,
  date,
  numOfProposals,
  cutOffTime,
  sx,
}: IMeetingDetailsCardProps) {
  return (
    <Card sx={sx} title="Details" testId="meeting-details-card">
      {meetingFormats?.length > 1 ? (
        <>
          <LabelField
            sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
            label="Location"
            isLabelBold={true}
            value=""
          />
          {meetingFormats.map((location, index) => {
            return (
              <LabelField
                sx={{
                  py: 5,
                  pr: 4,
                  pl: 4,
                  ml: 13,
                  borderBottom: "1px solid #e0e0e0",
                }}
                key={index}
                label={MEETING_FORMAT_LABEL[location.format]}
                value={location.value || ""}
              />
            );
          })}
        </>
      ) : (
        <LabelField
          sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
          label="Location"
          value={
            meetingFormats?.length === 1 ? meetingFormats[0].value || "-" : "-"
          }
        />
      )}
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Date and time"
        value={date ? formatDate(date, true, true) : "-"}
      />
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Total proposals"
        value={numOfProposals || "-"}
      />
      <LabelField
        sx={{ py: 5, px: 4, borderBottom: "1px solid #e0e0e0" }}
        label="Online votes cut off"
        value={cutOffTime ? formatDate(cutOffTime, true, true) : "-"}
      />
    </Card>
  );
}
