import { AutoCompleteOption } from "@asayinc/component-library";

export const CORPORATE_ACTION_OPTIONS: AutoCompleteOption[] = [
  {
    name: "Exchange Offer",
    id: "exchange_offer",
  },
  {
    name: "Odd Lot Offer",
    id: "odd_lot_offer",
  },
  {
    name: "Buyback",
    id: "buyback",
  },
  {
    name: "Buyback Auction",
    id: "buyback_auction",
  },
  {
    name: "Offer to Purchase",
    id: "offer_to_purchase",
  },
  {
    name: "Offer to Purchase Auction",
    id: "offer_to_purchase_auction",
  },
  {
    name: "Rights Offer",
    id: "rights_offer",
  },
  {
    name: "Rights Offer Oversubscription",
    id: "rights_offer_oversubscription",
  },
  {
    name: "Dividend Option",
    id: "dividend_option",
  },
  {
    name: "Dividend Reinvestment Plan",
    id: "dividend_reinvestment_plan",
  },
  {
    name: "Consent Solicitation",
    id: "consent_solicitation",
  },
  {
    name: "Merger With Election",
    id: "merger_consideration_election",
  },
  {
    name: "Informational",
    id: "informational",
  },
];
