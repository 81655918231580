import { VoteStatus } from "src/store/types";

export const VOTE_STATUS_LABEL: { [key in VoteStatus]: string } = {
  not_viewed: "Not Viewed",
  viewed: "Viewed",
  withdrawn: "Withdrawn",
  online: "Online",
  legal_proxy: "Legal Proxy",
  vote_transmission: "Vote Transmission",
};
