import { AutoCompleteOption } from "@asayinc/component-library";
import { CorporateAction } from "src/store/corporateAction/types";

const GROUPED_CORPORATE_ACTION_OPTIONS = {
  initial_corporate_action_informational: {
    id: "initial_corporate_action_informational,initial_corporate_action_informational_webhook,initial_corporate_action_informational_in_app",
    name: "Document delivery",
  },
  resent_initial_corporate_action_informational: {
    id: "resent_initial_corporate_action_informational",
    name: "Document delivery re-send",
  },
  initial_corporate_action_election: {
    id: "initial_corporate_action_election,initial_corporate_action_election_webhook,initial_corporate_action_election_in_app",
    name: "Election offer delivery",
  },
  resent_initial_corporate_action_election: {
    id: "resent_initial_corporate_action_election",
    name: "Election offer re-send",
  },
  confirmation_corporate_action_election: {
    id: "confirmation_corporate_action_election,confirmation_corporate_action_election_webhook",
    name: "Election confirmation",
  },
};

export const CORPORATE_ACTION_NOTIFICATION_OPTIONS: AutoCompleteOption[] =
  Object.values(GROUPED_CORPORATE_ACTION_OPTIONS);

const INITIAL_OPTIONS = [
  "initial_corporate_action_informational",
  "initial_corporate_action_election",
];

const CONFIRMATION_OPTIONS = ["confirmation_corporate_action_election"];

const RESENT_INITIAL_OPTIONS = [
  "resent_initial_corporate_action_election",
  "resent_initial_corporate_action_informational",
];

export function getCorporateActionNotificationOptions(
  corporateAction: CorporateAction
): AutoCompleteOption[] {
  const { stats, type } = corporateAction;
  const {
    initialWebhookCount,
    confirmationWebhookCount,
    initialCount,
    confirmationCount,
    resentInitialCount,
  } = stats;

  // Filtering by corporate action stats to infer which notification types exist
  const options = [];
  if (initialCount > 0 || initialWebhookCount > 0) {
    options.push(...INITIAL_OPTIONS);
  }

  if (confirmationCount > 0 || confirmationWebhookCount > 0) {
    options.push(...CONFIRMATION_OPTIONS);
  }

  if (resentInitialCount > 0) {
    options.push(...RESENT_INITIAL_OPTIONS);
  }

  // Filtering potential notification options even more by corporate action type
  const filteredOptions = options.filter((option) => {
    if (type === "informational") {
      return (
        option === "initial_corporate_action_informational" ||
        option === "resent_initial_corporate_action_informational"
      );
    }
    return (
      option !== "initial_corporate_action_informational" &&
      option !== "resent_initial_corporate_action_informational"
    );
  });

  return filteredOptions.map((key) => GROUPED_CORPORATE_ACTION_OPTIONS[key]);
}
