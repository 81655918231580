import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { FieldValues } from "react-hook-form";
import {
  FilterSelectionFlow,
  IFilter,
  FilterType,
} from "@asayinc/component-library";

interface IProps {
  fieldNamePrefix: string;
  chipLabel: string;
}

/**
 * Uses FilterSelectionFlow from component library to update
 * URL search params according to param props
 */

const formatDate = (date: Date) => date.toISOString().slice(0, 10);

// add "T00:00:00" to reset timezone so date is correct
const createDateObj = (str: string) => new Date(str + "T00:00:00");

/*
  Note: This DateFilter is copied from ir-portal. We might want to move this over to the compnoent lib?
*/
export const DateFilter = ({ fieldNamePrefix, chipLabel }: IProps) => {
  // Instead of exposing beforeParam, afterParam as separate props
  // we generate these two params from the fieldNmaePrefix since that is the
  // base field we are working with. Open to exposing these as props once we
  // have usecase for it.
  const beforeParam = `${fieldNamePrefix}__lte`;
  const afterParam = `${fieldNamePrefix}__gte`;
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState<FieldValues | undefined>(undefined);

  /**
   * set formValues on page load
   */
  useEffect(() => {
    const on = searchParams.get(fieldNamePrefix);
    const before = searchParams.get(beforeParam);
    const after = searchParams.get(afterParam);

    if (on) {
      setValues({
        "date-on": {
          [`${fieldNamePrefix}-on`]: createDateObj(on),
        },
      });
    } else if (before) {
      setValues({
        "date-before": {
          [`${fieldNamePrefix}-before`]: createDateObj(before),
        },
      });
    } else if (after) {
      setValues({
        "date-after": {
          [`${fieldNamePrefix}-after`]: createDateObj(after),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * use date filter values to update URL search params
   */
  useEffect(() => {
    if (values) {
      const dateBefore = values["date-before"];
      const dateAfter = values["date-after"];
      const dateOn = values["date-on"];
      if (dateBefore) {
        searchParams.set(
          beforeParam,
          formatDate(dateBefore[`${fieldNamePrefix}-before`])
        );
      }
      if (dateAfter) {
        searchParams.set(
          afterParam,
          formatDate(dateAfter[`${fieldNamePrefix}-after`])
        );
      }
      if (dateOn) {
        searchParams.set(
          fieldNamePrefix,
          formatDate(dateOn[`${fieldNamePrefix}-on`])
        );
      }
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const options = [
    {
      type: FilterType.Unselected,
      label: "Not applied",
    },
    {
      type: "date-before",
      label: "Before",
      options: {
        fieldName: `${fieldNamePrefix}-before`,
      },
    },
    {
      type: "date-after",
      label: "After",
      options: {
        fieldName: `${fieldNamePrefix}-after`,
      },
    },
    {
      type: "date-on",
      label: "On",
      options: {
        fieldName: `${fieldNamePrefix}-on`,
      },
    },
  ] as IFilter[];

  const onSubmit = (data: FieldValues, filterType?: string) => {
    if (data) {
      setValues({
        [filterType as string]: data,
      });
    }
  };

  const onClear = () => {
    searchParams.delete(beforeParam);
    searchParams.delete(afterParam);
    searchParams.delete(fieldNamePrefix);
    setSearchParams(searchParams);
    setValues(undefined);
  };

  return (
    <FilterSelectionFlow
      skipMessaging
      clearable
      onClear={onClear}
      onSubmit={onSubmit}
      chipLabel={chipLabel}
      filterTypeOptions={options}
      values={values}
    />
  );
};
