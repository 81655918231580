import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";

import { useProxyEventQuery } from "src/store/proxy";
import { ProxyCommunicationsTable } from "src/components/organisms/ProxyCommunicationsTable";
import { ProxyCustomersTable } from "src/components/organisms/ProxyCustomersTable";
import { isVotableEvent } from "src/utils/isVotableEvent";
import { ErrorState } from "src/components/atoms/ErrorState";
import { CustomerDetails } from "src/components/organisms/CustomerDetails";
import { IProxyCustomer, ListProxyCommunication } from "src/store/proxy/types";
import { Drawer } from "src/components/atoms/Drawer";
import { ProxyCommunicationDetails } from "src/components/organisms/ProxyCommunicationDetails";
import { ScrollContainer } from "src/components/containers/ScrollContainer";

export function CustomersTab() {
  const { id } = useParams() as { id: string };
  const { data: proxyEvent, isLoading, error } = useProxyEventQuery({ id });
  const [customer, setCustomer] = useState<IProxyCustomer | null>(null);
  const [communication, setCommunication] =
    useState<ListProxyCommunication | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isVotable = useMemo(
    () => (proxyEvent ? isVotableEvent(proxyEvent) : false),
    [proxyEvent]
  );

  const handleCustomerClick = (customer: IProxyCustomer) => {
    setCustomer(customer);
    setIsDrawerOpen(true);
  };

  const handleCommunicationClick = (communication: ListProxyCommunication) => {
    setCommunication(communication);
    setIsDrawerOpen(true);
  };

  const handleCloseCustomerDrawer = () => {
    setCustomer(null);
    setIsDrawerOpen(false);
  };

  const handleCloseCommunicationDrawer = () => {
    setCommunication(null);
    setIsDrawerOpen(false);
  };

  if (error || (!isLoading && !proxyEvent)) {
    return <ErrorState />;
  }

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      {proxyEvent?.operationsStatus === "approved" ? (
        <ProxyCommunicationsTable
          onRowClick={handleCommunicationClick}
          proxyId={id}
          selectedCommunication={communication}
          showVotedColumn={isVotable}
          title="Customers"
        />
      ) : (
        <ProxyCustomersTable
          onRowClick={handleCustomerClick}
          proxyId={id}
          selectedCustomer={customer}
        />
      )}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        testId="mailing-detail-drawer"
        onClose={handleCloseCommunicationDrawer}
      >
        <>
          {communication && (
            <ProxyCommunicationDetails
              activeTab="customer"
              proxyId={id}
              communicationId={communication.id}
              onClose={handleCloseCommunicationDrawer}
            />
          )}
          {customer && (
            <CustomerDetails
              customer={customer}
              onClose={handleCloseCustomerDrawer}
              proxyId={id}
            />
          )}
        </>
      </Drawer>
    </ScrollContainer>
  );
}
