import { TextField } from "@asayinc/component-library";
import { SearchOutlined, Cancel } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { ChangeEvent, useState } from "react";

interface ISearchInputFieldProps {
  onChange: (value: string) => void;
  sx?: SxProps;
}

/*
    Note: Probably don't need this as a separate component. Would only be used in
    SearchPanel. Potentially move to SearchPanel.
*/
export function SearchInputField({ onChange, sx }: ISearchInputFieldProps) {
  const [value, setValue] = useState<string | null>("");

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setValue(target.value);
    onChange(target.value);
  };

  const handleClear = () => {
    setValue("");
    onChange("");
  };

  return (
    <TextField
      autoFocus
      endAdornmentClick={handleClear}
      endAdornment={value ? <Cancel /> : <SearchOutlined />}
      onChange={handleOnChange}
      placeholder="Search by email, customer ID, ticker or company name"
      sx={sx}
      value={value}
      data-testid="search-input-field"
    />
  );
}
