import {
  ListCorporateActionCommunication,
  ListCorporateActionNotification,
} from "../corporateAction/types";
import { ListProxyCommunication, ListProxyNotification } from "../proxy/types";
import { IPaginationParams, IPaginationResponse } from "../types";
import { Customer, CustomerParams } from "./types";
import { rootApi } from "../rootApi";

export const customerApis = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    customers: builder.query<IPaginationResponse<Customer>, IPaginationParams>({
      query: ({ limit, ordering, offset }) => ({
        url: "customers/",
        params: {
          limit,
          ordering,
          offset,
        },
      }),
      providesTags: ["customers"],
    }),
    customer: builder.query<Customer, { id: string }>({
      query: ({ id }) => ({
        url: `customers/${id}/`,
        params: {},
      }),
      providesTags: (_result, _, arg) => [{ type: "customers", id: arg.id }],
    }),
    customerProxyCommunications: builder.query<
      IPaginationResponse<ListProxyCommunication>,
      CustomerParams
    >({
      query: ({ id, limit, ordering, offset, type, voteStatus, search }) => ({
        url: `customers/${id}/proxy-events/communications/`,
        params: {
          limit,
          ordering,
          offset,
          proxy_event_type: type || undefined,
          vote_status: voteStatus || undefined,
          search: search || undefined,
        },
      }),
      providesTags: (_result, _, arg) => [
        { type: "customerProxyCommunications", id: arg.id },
      ],
    }),
    customerCorporateActionCommunications: builder.query<
      IPaginationResponse<ListCorporateActionCommunication>,
      CustomerParams
    >({
      query: ({
        id,
        limit,
        ordering,
        offset,
        type,
        electionStatus,
        search,
      }) => ({
        url: `customers/${id}/corporate-action-events/communications/`,
        params: {
          limit,
          ordering,
          offset,
          corporate_action_event_type: type || undefined,
          election_status: electionStatus || undefined,
          search: search || undefined,
        },
      }),
      providesTags: (_result, _, args) => [
        { type: "customerCorporateActionCommunications", id: args.id },
      ],
    }),
    customerProxyNotifications: builder.query<
      IPaginationResponse<ListProxyNotification>,
      CustomerParams
    >({
      query: ({
        id,
        limit,
        ordering,
        offset,
        status,
        type,
        search,
        sentAt,
        sentBefore,
        sentAfter,
      }) => ({
        url: `customers/${id}/proxy-events/notifications/`,
        params: {
          limit,
          ordering,
          offset,
          status: status || undefined,
          type: type || undefined,
          search: search || undefined,
          sent_at: sentAt || undefined,
          sent_at__lte: sentBefore || undefined,
          sent_at__gte: sentAfter || undefined,
        },
      }),
      providesTags: (_result, _, args) => [
        { type: "customerProxyNotifications", id: args.id },
      ],
    }),
    customerCorporateActionNotifications: builder.query<
      IPaginationResponse<ListCorporateActionNotification>,
      CustomerParams
    >({
      query: ({
        id,
        limit,
        ordering,
        offset,
        status,
        type,
        search,
        sentAt,
        sentBefore,
        sentAfter,
      }) => ({
        url: `customers/${id}/corporate-action-events/notifications/`,
        params: {
          limit,
          ordering,
          offset,
          status: status || undefined,
          type: type || undefined,
          search: search || undefined,
          sent_at: sentAt || undefined,
          sent_at__lte: sentBefore || undefined,
          sent_at__gte: sentAfter || undefined,
        },
      }),
      providesTags: (_result, _, args) => [
        { type: "customerCorporateActionNotifications", id: args.id },
      ],
    }),
  }),
});

export const {
  useCustomersQuery,
  useCustomerQuery,
  useCustomerProxyCommunicationsQuery,
  useCustomerCorporateActionCommunicationsQuery,
  useCustomerProxyNotificationsQuery,
  useCustomerCorporateActionNotificationsQuery,
} = customerApis;
