import { SayIcon, SecondaryTabs } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Drawer } from "src/components/atoms/Drawer";
import { CorporateActionCommunicationDetails } from "src/components/organisms/CorporateActionCommunicationDetails";
import { ProxyCommunicationDetails } from "src/components/organisms/ProxyCommunicationDetails";
import { CustomerCorporateActionCommunicationsTable } from "src/components/organisms/CustomerCorporateActionCommunicationsTable";
import { CustomerProxyCommunicationsTable } from "src/components/organisms/CustomerProxyCommunicationsTable";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { ListCommunication } from "src/store/types";
import { CUSTOMER_DETAIL_VIEW, CustomerDetailView } from "../CustomerDetail";
import { ScrollContainer } from "src/components/containers/ScrollContainer";

export function MailingsTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams() as { id: string };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [communication, setCommunication] = useState<ListCommunication | null>(
    null
  );
  const view =
    (searchParams.get(TABLE_SEARCH_PARAMS.VIEW) as CustomerDetailView) ||
    CUSTOMER_DETAIL_VIEW.PROXY;

  const handleRowClick = (communication: ListCommunication) => {
    setCommunication(communication);
    setIsDrawerOpen(true);
  };

  const handleCloseCommunicationDrawer = () => {
    setCommunication(null);
    setIsDrawerOpen(false);
  };

  const handleSecondaryTabChange = (tab: string) => {
    const newParams = new URLSearchParams({
      view: tab,
    });
    setSearchParams(newParams);
  };

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Stack sx={{ pb: 8 }}>
        <SecondaryTabs
          sx={{ mx: "auto" }}
          tabs={[
            {
              label: "Proxy",
              value: CUSTOMER_DETAIL_VIEW.PROXY,
              icon:
                view === CUSTOMER_DETAIL_VIEW.PROXY ? (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="ProxyFill" color="#1968FF" />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="ProxyOutline" />
                  </Box>
                ),
            },
            {
              label: "Voluntary corporate actions",
              value: CUSTOMER_DETAIL_VIEW.CORPORATE_ACTION,
              icon:
                view === CUSTOMER_DETAIL_VIEW.CORPORATE_ACTION ? (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="VcaFill" color="#1968FF" />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }} mr={3}>
                    <SayIcon name="VcaOutline" />
                  </Box>
                ),
            },
          ]}
          activeTab={view}
          clickHandler={handleSecondaryTabChange}
        />
      </Stack>
      {!view || view === "proxy" ? (
        <CustomerProxyCommunicationsTable
          customerId={id}
          onRowClick={handleRowClick}
          selectedCommunication={communication}
        />
      ) : (
        <CustomerCorporateActionCommunicationsTable
          customerId={id}
          onRowClick={handleRowClick}
          selectedCommunication={communication}
        />
      )}
      <Drawer
        anchor="right"
        onClose={handleCloseCommunicationDrawer}
        open={isDrawerOpen}
        testId="customer-mailing-drawer"
      >
        {communication && communication.proxyEvent && (
          <ProxyCommunicationDetails
            communicationId={communication?.id}
            proxyId={communication?.proxyEvent?.id}
            onClose={handleCloseCommunicationDrawer}
          />
        )}
        {communication && communication.corporateActionEvent && (
          <CorporateActionCommunicationDetails
            communicationId={communication?.id}
            corporateActionId={communication?.corporateActionEvent?.id}
            onClose={handleCloseCommunicationDrawer}
          />
        )}
      </Drawer>
    </ScrollContainer>
  );
}
