import { COLORS, Text } from "@asayinc/component-library";
import { Box, Stack, SxProps } from "@mui/material";
import { AccountBalance } from "@mui/icons-material";
import { Security } from "src/store/types";

interface IIssuerHeaderProps {
  issuerName: string | undefined;
  sx?: SxProps;
  securities: Security[] | undefined;
}

export function IssuerHeader({
  issuerName,
  sx,
  securities,
}: IIssuerHeaderProps) {
  const securitiesWithTickers = securities?.filter(
    (security) => security.ticker && security.ticker !== ""
  );
  const tickers = securitiesWithTickers
    ?.map((security) => security.ticker)
    .join(", ");
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ ...sx }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          height: "48px",
          width: "48px",
          mb: 3,
          backgroundColor: COLORS.BLACK,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AccountBalance sx={{ color: COLORS.WHITE }} />
      </Box>
      <Text
        data-testid="issuer-name"
        loadingProps={{ sx: { width: "300px" } }}
        variant="h5"
      >
        {issuerName}
      </Text>
      {tickers && tickers.length > 0 && (
        <Box
          sx={{
            display: "flex",
            mt: 3,
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Text
            loadingProps={{ sx: { width: "300px" } }}
            variant="body1"
            sx={{ mr: 1.5 }}
          >
            Ticker:
          </Text>
          <Text
            data-testid="issuer-tickers"
            loadingProps={{ sx: { width: "300px" } }}
            variant="subtitle1"
          >
            {tickers}
          </Text>
        </Box>
      )}
    </Stack>
  );
}
