import { configureStore } from "@reduxjs/toolkit";
import { ssoApi } from "./sso";
import { rootApi } from "./rootApi";

export const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
    [ssoApi.reducerPath]: ssoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware).concat(ssoApi.middleware),
});
