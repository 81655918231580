import { Text } from "@asayinc/component-library";
import { Email } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";

interface ILoadingHeaderProps {
  sx?: SxProps;
  showIcon?: boolean;
  numberOfRows?: number;
}

export function LoadingHeader({
  sx,
  showIcon = true,
  numberOfRows = 2,
}: ILoadingHeaderProps) {
  const rows = [];
  for (let x = 0; x < numberOfRows; x++) {
    rows.push(
      <Text
        sx={{ mr: 2, mt: 2 }}
        variant="subtitle1"
        loadingProps={{ sx: { width: "300px" } }}
        key={"loading-header-row-" + x}
      >
        {undefined}
      </Text>
    );
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", ...sx }}
      data-testid="loading-header"
    >
      {showIcon && (
        <Box
          sx={{
            pt: "14px",
            pb: "8px",
            px: "14px",
            backgroundColor: "#3D3D3D",
            borderRadius: 2,
            mr: 4,
          }}
        >
          <Email sx={{ borderRadius: 2, color: "white" }} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {rows}
      </Box>
    </Box>
  );
}
