import { CommunicationNotification } from "src/store/types";
import { User } from "src/store/user/types";

export function shouldShowResendButton(
  notification: CommunicationNotification,
  user?: User
) {
  const { status, type } = notification;
  // Check user permissions to see if user has permission to resend emails
  const hasPermissionToResend =
    user?.permissions.includes("can_resend_emails_in_broker_portal") || false;

  // Show resend button if notification is in a resendable state and user has permission to resend
  return (
    (type === "initial_proxy_vote" ||
      type === "initial_proxy_informational" ||
      type === "initial_contested_proxy_vote" ||
      type === "initial_proxy_non_votable" ||
      type === "initial_corporate_action_election" ||
      type === "initial_corporate_action_informational" ||
      type === "initial_consent_solicitation") &&
    hasPermissionToResend &&
    status !== "processing" &&
    status !== "none" &&
    status !== "queued"
  );
}
