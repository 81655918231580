import { SecondaryTabs, TableV2 as Table } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import {
  FilterList,
  Verified,
  VerifiedOutlined,
  WatchLater,
  WatchLaterOutlined,
} from "@mui/icons-material";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MailingStatsBar } from "src/components/molecules/MailingStatsBar";
import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import { ROW_OPTIONS, PROXY_OPTIONS } from "src/constants/options";
import { useTablePagination } from "src/hooks/useTablePagination";
import {
  useProxyEventsAggregateQuery,
  useProxyEventsQuery,
} from "src/store/proxy";
import { MailingView, MAILING_VIEW } from "src/pages/Mailings";
import { getColumnData, getColumns, getRowData } from "./utils";
import { IProxyMailing } from "src/store/proxy/types";
import { hasMeetingInfo } from "src/utils/hasMeetingInfo";
import { formatDate } from "src/utils/formatDate";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import { ScrollContainer } from "src/components/containers/ScrollContainer";
import dayjs from "dayjs";

export function Proxy() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm();
  const view = (searchParams.get(TABLE_SEARCH_PARAMS.VIEW) ||
    MAILING_VIEW.APPROVED) as MailingView;
  const { type } = useTableSearchParams();

  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const thirtyDaysBefore = dayjs().subtract(30, "days");
  const { data, isFetching, isLoading } = useProxyEventsQuery({
    offset,
    limit,
    ordering: ordering ? ordering : "-approved_at",
    type,
    view: view || MAILING_VIEW.APPROVED,
    approvedAfter:
      view === MAILING_VIEW.TO_BE_APPROVED
        ? undefined
        : formatDate(thirtyDaysBefore),
  });
  const { data: aggregateData } = useProxyEventsAggregateQuery({
    view: view || MAILING_VIEW.APPROVED,
    approvedAfter:
      view === MAILING_VIEW.TO_BE_APPROVED
        ? undefined
        : formatDate(thirtyDaysBefore),
  });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: data?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  const handleRowClick = (mailing: IProxyMailing) => {
    const { id, type } = mailing;
    if (hasMeetingInfo(type) && view === "approved") {
      navigate(`${id}/meeting-info`);
    } else {
      navigate(`${id}/customers`);
    }
  };

  const handleSecondaryTabChange = (tab: string) => {
    const newParams = new URLSearchParams({
      view: tab,
    });
    setSearchParams(newParams);
  };

  const columns = useMemo(() => getColumns(view), [view]);
  const columnData = useMemo(() => {
    return getColumnData(view, {
      getSort,
      onOrderingChange,
    });
  }, [view, getSort, onOrderingChange]);
  const rowData = getRowData(view, data?.results || [], handleRowClick);

  const filterList = (
    <FormProvider {...methods}>
      <TableMultiSelectFilter
        fieldName={TABLE_SEARCH_PARAMS.TYPE}
        label="Types"
        options={PROXY_OPTIONS}
        queryParameter={TABLE_SEARCH_PARAMS.TYPE}
        view={view}
      />
    </FormProvider>
  );

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <Stack>
        <SecondaryTabs
          sx={{ mb: 8, mr: "auto" }}
          tabs={[
            {
              label: "Approved",
              value: MAILING_VIEW.APPROVED,
              icon:
                view === MAILING_VIEW.APPROVED ? (
                  <Verified style={{ color: "#1968FF" }} />
                ) : (
                  <VerifiedOutlined />
                ),
            },
            {
              label: "To be approved",
              value: MAILING_VIEW.TO_BE_APPROVED,
              icon:
                view === MAILING_VIEW.TO_BE_APPROVED ? (
                  <WatchLater style={{ color: "#1968FF" }} />
                ) : (
                  <WatchLaterOutlined />
                ),
            },
          ]}
          activeTab={view}
          clickHandler={handleSecondaryTabChange}
        />
      </Stack>
      <MailingStatsBar
        communicationCount={aggregateData?.communicationCount || 0}
        customerCount={aggregateData?.uniqueCustomerCount || 0}
        eventCount={aggregateData?.proxyEventCount || 0}
        failureCount={aggregateData?.efailureCount || 0}
        isUpcoming={view === MAILING_VIEW.TO_BE_APPROVED}
        sx={{ mb: 8 }}
      />
      <Table
        count={data?.count || 0}
        columnData={columnData}
        columns={columns}
        isFetching={isFetching}
        isLoading={isLoading}
        noResultsData={{
          title: "No proxy mailings found",
          subtitle:
            "Sorry, there are no mailings that match your applied filters.",
        }}
        paginateProps={paginateProps}
        rows={rowData}
        tableMinWidth={1100}
        title={
          view === MAILING_VIEW.APPROVED
            ? "Approved mailings from the last 30 days"
            : "All upcoming mailings"
        }
        titleVariant="subtitle2"
        toolbarInside
        toolbarCollapse={{
          Icon: FilterList,
          collapseContent: filterList,
          isFlat: true,
        }}
      />
    </ScrollContainer>
  );
}
