import DOMPurify from "dompurify";

import { Box } from "@mui/material";
import { EmailRender, MailRender, Preview } from "src/store/types";

interface IPreviewRenderProps {
  preview: Preview;
}

export function PreviewRender({ preview }: IPreviewRenderProps) {
  const { channelType, error, render } = preview;

  if (error) {
    return (
      <Box
        sx={{ overflow: "auto", borderRadius: 4 }}
        data-testid="error-preview-render"
      >
        {error}
      </Box>
    );
  }

  if (!render) {
    return (
      <Box
        sx={{
          overflow: "auto",
          borderRadius: 4,
          textAlign: "center",
          border: "1px solid #e5e5e5",
          padding: 4,
        }}
        data-testid="render-not-available"
      >
        The render is not available yet
      </Box>
    );
  }

  if (channelType === "email") {
    const { body } = render as EmailRender;
    return (
      <Box
        sx={{ overflow: "auto", borderRadius: 4 }}
        data-testid="email-preview-render"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(body),
        }}
      />
    );
  }

  const { mainRender, secondaryRender } = render as MailRender;
  return (
    <>
      {mainRender && (
        <Box
          id="main-preview-render"
          sx={{
            overflow: "auto",
            borderRadius: 4,
            padding: 4,
            border: "1px solid #e5e5e5",
          }}
          data-testid="main-preview-render"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(mainRender),
          }}
        />
      )}
      {secondaryRender && (
        <Box
          id="secondary-preview-render"
          sx={{
            overflow: "auto",
            borderRadius: 4,
            mt: 4,
            padding: 4,
            border: "1px solid #e5e5e5",
          }}
          data-testid="secondary-preview-render"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(secondaryRender),
          }}
        />
      )}
    </>
  );
}
