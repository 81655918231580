import { Text } from "@asayinc/component-library";
import { Box, SxProps } from "@mui/material";

interface ISearchRowProps {
  isLeftBold?: boolean;
  isRightBold?: boolean;
  leftContent: React.ReactNode;
  leftSx?: SxProps;
  rightContent: React.ReactNode;
  rightIcon?: React.ReactNode;
  rightSx?: SxProps;
  onClick?: () => void;
}

export function SearchRow({
  isLeftBold = false,
  isRightBold = true,
  leftContent,
  leftSx,
  rightContent,
  rightIcon,
  rightSx,
  onClick,
}: ISearchRowProps) {
  return (
    <Box
      sx={{
        display: "flex",
        px: 5,
        py: 5,
        borderBottom: "1px solid #E0E0E0",
        cursor: onClick ? "pointer" : "auto",
        "&:hover": {
          backgroundColor: onClick ? "#F0F0F0" : "none",
          opacity: "80%",
        },
        alignItems: "center",
      }}
      onClick={onClick}
      data-testid="search-row"
    >
      <Box sx={{ flexGrow: 1, ...leftSx }}>
        <Text
          variant={isLeftBold ? "subtitle2" : "body3"}
          data-testid="left-content"
        >
          {leftContent}
        </Text>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          ...rightSx,
        }}
      >
        <Text
          variant={isRightBold ? "subtitle2" : "body3"}
          data-testid="right-content"
        >
          {rightContent}
        </Text>
        {rightIcon && rightIcon}
      </Box>
    </Box>
  );
}
