import { useState } from "react";
import { useParams } from "react-router-dom";
import { Drawer } from "src/components/atoms/Drawer";
import { ProxyCommunicationDetails } from "src/components/organisms/ProxyCommunicationDetails";
import { ProxyNotificationsTable } from "src/components/organisms/NotificationsTable/ProxyNotificationsTable";
import { ListNotification } from "src/store/types";
import { ScrollContainer } from "src/components/containers/ScrollContainer";

export function NotificationsTab() {
  const { id } = useParams() as { id: string };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notification, setNotification] = useState<ListNotification | null>(
    null
  );

  const handleRowClick = (notification: ListNotification) => {
    setNotification(notification);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setNotification(null);
  };

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <ProxyNotificationsTable
        proxyId={id}
        onRowClick={handleRowClick}
        selectedNotification={notification}
      />
      <Drawer
        anchor="right"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
        testId="notification-detail-drawer"
      >
        {notification && (
          <ProxyCommunicationDetails
            activeTab="notification"
            communicationId={notification.communicationIds[0]}
            notificationId={notification.id}
            onClose={handleCloseDrawer}
            proxyId={id}
          />
        )}
      </Drawer>
    </ScrollContainer>
  );
}
