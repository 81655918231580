import { AutoCompleteOption } from "@asayinc/component-library";
import { IProxyEvent } from "src/store/proxy/types";

const GROUPED_PROXY_NOTIFICATIONS_OPTIONS = {
  additional_mailing_informational: {
    id: "additional_mailing_informational,additional_mailing_informational_webhook,additional_mailing_contesting_party_informational_in_app",
    name: "Additional mailing",
  },
  additional_mailing_vote_reminder: {
    id: "additional_mailing_vote_reminder,additional_mailing_vote_reminder_webhook,additional_mailing_contesting_party_reminder_in_app,additional_mailing_vote_reminder_in_app",
    name: "Vote reminder",
  },
  confirmation_proxy_vote: {
    id: "confirmation_proxy_vote,confirmation_proxy_vote_webhook",
    name: "Vote confirmation",
  },
  efailure_consent_solicitation: {
    id: "efailure_consent_solicitation",
    name: "Solicitation notice e-fail",
  },
  efailure_proxy_informational: {
    id: "efailure_proxy_informational",
    name: "Document delivery e-fail",
  },
  efailure_proxy_non_votable: {
    id: "efailure_proxy_non_votable",
    name: "Meeting notice e-fail",
  },
  efailure_proxy_vote: {
    id: "efailure_proxy_vote,efailure_contested_proxy_vote",
    name: "Ballot delivery e-fail",
  },
  initial_consent_solicitation: {
    id: "initial_consent_solicitation,initial_consent_solicitation_webhook,initial_consent_solicitation_in_app",
    name: "Solicitation notice",
  },
  initial_proxy_informational: {
    id: "initial_proxy_informational,initial_proxy_informational_webhook",
    name: "Document delivery",
  },
  initial_proxy_vote: {
    id: "initial_proxy_vote,initial_proxy_vote_webhook,initial_contested_proxy_vote,initial_contested_proxy_vote_webhook,initial_proxy_vote_in_app,initial_contested_proxy_vote_in_app",
    name: "Ballot delivery",
  },
  initial_proxy_non_votable: {
    id: "initial_proxy_non_votable,initial_proxy_non_votable_webhook",
    name: "Meeting notice",
  },
  resent_initial_consent_solicitation: {
    id: "resent_initial_consent_solicitation",
    name: "Solicitation re-send",
  },
  resent_initial_proxy_informational: {
    id: "resent_initial_proxy_informational",
    name: "Document delivery re-send",
  },
  resent_initial_proxy_vote: {
    id: "resent_initial_proxy_vote,resent_initial_contested_proxy_vote",
    name: "Ballot delivery re-send",
  },
  resent_initial_proxy_non_votable: {
    id: "resent_initial_proxy_non_votable",
    name: "Meeting notice re-send",
  },
  additional_mailing_contesting_party_announcement: {
    id: "additional_mailing_contesting_party_announcement,additional_mailing_contesting_party_announcement_webhook,additional_mailing_contesting_party_announcement_in_app",
    name: "Dissident mailing",
  },
};

export const PROXY_NOTIFICATION_OPTIONS: AutoCompleteOption[] = Object.values(
  GROUPED_PROXY_NOTIFICATIONS_OPTIONS
);

const INITIAL_OPTIONS = [
  "initial_proxy_informational",
  "initial_proxy_vote",
  "initial_proxy_non_votable",
  "initial_consent_solicitation",
];

const CONFIRMATION_OPTIONS = ["confirmation_proxy_vote"];

const ADDITIONAL_MAILING_OPTIONS = [
  "additional_mailing_vote_reminder",
  "additional_mailing_informational",
];

const RESENT_INITIAL_OPTIONS = [
  "resent_initial_proxy_informational",
  "resent_initial_proxy_vote",
  "resent_initial_proxy_non_votable",
  "resent_initial_consent_solicitation",
];

const EFAILURE_OPTIONS = [
  "efailure_consent_solicitation",
  "efailure_proxy_vote",
  "efailure_proxy_non_votable",
  "efailure_proxy_informational",
];

const MEETING_TYPES = ["annual_meeting", "special_meeting"];
const VOTABLE_MEETING_OPTIONS = [
  "initial_proxy_vote",
  "resent_initial_proxy_vote",
  "efailure_proxy_vote",
  "confirmation_proxy_vote",
];
const NON_VOTABLE_MEETING_OPTIONS = [
  "initial_proxy_non_votable",
  "resent_initial_proxy_non_votable",
  "efailure_proxy_non_votable",
];

const INFORMATIONAL_TYPES = [
  "annual_report",
  "semi_annual_report",
  "19_a_notice",
  "summary_prospectus",
];
const INFORMATIONAL_OPTIONS = [
  "initial_proxy_informational",
  "resent_initial_proxy_informational",
  "efailure_proxy_informational",
];

const CONSENT_SOLICITATION_TYPES = ["consent_solicitation"];
const CONSENT_SOLICITATION_OPTIONS = [
  "initial_consent_solicitation",
  "resent_initial_consent_solicitation",
  "efailure_consent_solicitation",
];

export function getProxyNotificationOptions(
  proxyEvent: IProxyEvent
): AutoCompleteOption[] {
  const { stats } = proxyEvent;
  const {
    initialWebhookCount,
    confirmationWebhookCount,
    additionalMailingWebhookNotificationCount,
    initialCount,
    confirmationCount,
    resentInitialCount,
    additionalMailingEmailCount,
    efailureCount,
  } = stats;

  // Filtering by proxy event stats to infer which notification types exist
  const options = [];
  if (initialWebhookCount > 0 || initialCount > 0) {
    options.push(...INITIAL_OPTIONS);
  }

  if (confirmationCount > 0 || confirmationWebhookCount > 0) {
    options.push(...CONFIRMATION_OPTIONS);
  }

  if (resentInitialCount > 0) {
    options.push(...RESENT_INITIAL_OPTIONS);
  }

  if (
    additionalMailingEmailCount > 0 ||
    additionalMailingWebhookNotificationCount > 0
  ) {
    options.push(...ADDITIONAL_MAILING_OPTIONS);
  }

  if (efailureCount > 0) {
    options.push(...EFAILURE_OPTIONS);
  }

  // Filtering potential notification options even more by proxy event type
  const filteredOptions = filterOptionsByProxyEventType(proxyEvent, options);

  return filteredOptions.map((key) => GROUPED_PROXY_NOTIFICATIONS_OPTIONS[key]);
}

const filterOptionsByProxyEventType = (
  proxyEvent: IProxyEvent,
  options: string[]
) => {
  const { type } = proxyEvent;
  return options.filter((option) => {
    if (MEETING_TYPES.includes(type)) {
      const { proposals, securities } = proxyEvent;
      const rootSecurityIds = securities.map((s) => s.id);
      const proposalSecurityIds = proposals.reduce((acc, proposal) => {
        return [...acc, ...proposal.securities.map((s) => s.id)];
      }, [] as string[]);

      // If all rootSecurityIds are in proposalSecurityIds then it is a votable event
      // If some rootSecurityIds are in proposalSecurityIds then it is both a votable and non-votable event
      // If none of the rootSecurityIds are in porposalSecurityIds then it is a non-votable event
      const isVotable = rootSecurityIds.every((id) =>
        proposalSecurityIds.includes(id)
      );
      const isBoth = rootSecurityIds.some((id) =>
        proposalSecurityIds.includes(id)
      );

      if (isVotable) {
        return VOTABLE_MEETING_OPTIONS.includes(option);
      }

      if (isBoth) {
        return (
          VOTABLE_MEETING_OPTIONS.includes(option) ||
          NON_VOTABLE_MEETING_OPTIONS.includes(option)
        );
      }

      return NON_VOTABLE_MEETING_OPTIONS.includes(option);
    }

    if (INFORMATIONAL_TYPES.includes(type)) {
      return INFORMATIONAL_OPTIONS.includes(option);
    }

    if (CONSENT_SOLICITATION_TYPES.includes(type)) {
      return CONSENT_SOLICITATION_OPTIONS.includes(option);
    }

    return true;
  });
};
