import { useParams } from "react-router-dom";
import { useState } from "react";

import { ListCorporateActionCommunication } from "src/store/corporateAction/types";
import { Drawer } from "src/components/atoms/Drawer";
import { CorporateActionCommunicationDetails } from "src/components/organisms/CorporateActionCommunicationDetails";
import { CorporateActionCommunicationsTable } from "src/components/organisms/CorporateActionCommunicationsTable/CorporateActionCommunicationsTable";
import { ScrollContainer } from "src/components/containers/ScrollContainer";

export function CustomersTab() {
  const { id } = useParams() as { id: string };
  const [communication, setCommunication] =
    useState<ListCorporateActionCommunication | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCommunicationClick = (
    communication: ListCorporateActionCommunication
  ) => {
    setCommunication(communication);
    setIsDrawerOpen(true);
  };

  const handleCloseCommunicationDrawer = () => {
    setCommunication(null);
    setIsDrawerOpen(false);
  };

  return (
    <ScrollContainer sx={{ pt: 8, pb: 10, pl: 10, pr: 6.25 }}>
      <CorporateActionCommunicationsTable
        corporateActionId={id}
        onRowClick={handleCommunicationClick}
        selectedCommunication={communication}
        showVotedColumn={true}
        title="Customers"
      />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        testId="mailing-detail-drawer"
        onClose={handleCloseCommunicationDrawer}
      >
        {communication && (
          <CorporateActionCommunicationDetails
            activeTab="customer"
            corporateActionId={id}
            communicationId={communication.id}
            onClose={handleCloseCommunicationDrawer}
          />
        )}
      </Drawer>
    </ScrollContainer>
  );
}
