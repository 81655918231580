import { TableV2 as Table } from "@asayinc/component-library";
import { FilterList } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { ErrorState } from "src/components/atoms/ErrorState";
import { DateFilter } from "src/components/molecules/DateFilter";
import { EmptyState } from "src/components/molecules/EmptyState";
import { TableMultiSelectFilter } from "src/components/molecules/TableMultiSelectFilter";
import {
  ROW_OPTIONS,
  NOTIFICATION_STATUS_OPTIONS,
} from "src/constants/options";
import { TABLE_SEARCH_PARAMS } from "src/constants/tableSearchParams";
import { useDateQueryParams } from "src/hooks/useDateQueryParams";
import { useSearchFns } from "src/hooks/useSearchFns";
import { useTablePagination } from "src/hooks/useTablePagination";
import { useTableSearchParams } from "src/hooks/useTableSearchParams";
import {
  useCorporateActionEventNotificationsQuery,
  useCorporateActionEventQuery,
} from "src/store/corporateAction";
import { ListNotification } from "src/store/types";
import { getColumnData, getColumns, getRowData } from "../utils";
import { getCorporateActionNotificationOptions } from "src/constants/options/corporateActionNotificationOptions";
import { useIsPiiFreeBroker } from "src/hooks/useIsPiiFreeBroker";

interface ICorporateActionNotificationsTableProps {
  corporateActionId: string;
  onRowClick: (notification: ListNotification) => void;
  selectedNotification?: ListNotification | null;
}

export function CorporateActionNotificationsTable({
  corporateActionId,
  onRowClick,
  selectedNotification,
}: ICorporateActionNotificationsTableProps) {
  const methods = useForm();
  const { at, before, after } = useDateQueryParams(TABLE_SEARCH_PARAMS.SENT_AT);
  const { search, status, type } = useTableSearchParams();
  const isPiiFreeBroker = useIsPiiFreeBroker();

  const {
    getSort,
    offset,
    limit,
    ordering,
    onChangePage,
    onChangeRows,
    onOrderingChange,
  } = useTablePagination();
  const searchFns = useSearchFns();

  const { data: corporateAction } = useCorporateActionEventQuery({
    id: corporateActionId,
  });
  const {
    data: notifications,
    isLoading,
    isFetching,
    error,
  } = useCorporateActionEventNotificationsQuery({
    id: corporateActionId,
    offset,
    ordering,
    limit,
    search,
    type,
    status,
    sentAt: at,
    sentBefore: before,
    sentAfter: after,
  });

  const paginateProps = {
    onChangePage,
    onChangeRows,
    rowOptions: ROW_OPTIONS,
    count: notifications?.count || 0,
    page: Math.floor(offset / limit) + 1,
    rowsPerPage: limit,
  };

  if ((!isLoading && !notifications) || error) {
    return <ErrorState />;
  }

  const corporateActionNotificationOptions = corporateAction
    ? getCorporateActionNotificationOptions(corporateAction)
    : [];

  const filterList = (
    <Stack direction="row-reverse" gap={4} mt={2} pb={4} width="100%">
      <FormProvider {...methods}>
        <DateFilter
          fieldNamePrefix={TABLE_SEARCH_PARAMS.SENT_AT}
          chipLabel="Sent date"
        />
        <TableMultiSelectFilter
          fieldName={TABLE_SEARCH_PARAMS.TYPE}
          label="Type"
          options={corporateActionNotificationOptions}
          queryParameter={TABLE_SEARCH_PARAMS.TYPE}
        />
        <TableMultiSelectFilter
          fieldName={TABLE_SEARCH_PARAMS.STATUS}
          label="Status"
          options={NOTIFICATION_STATUS_OPTIONS}
          queryParameter={TABLE_SEARCH_PARAMS.STATUS}
        />
      </FormProvider>
    </Stack>
  );

  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search by email",
  };

  const columns = getColumns(isPiiFreeBroker);
  const columnData = getColumnData(isPiiFreeBroker, {
    getSort,
    onOrderingChange,
  });
  const rowData = getRowData(
    isPiiFreeBroker,
    notifications?.results || [],
    onRowClick,
    selectedNotification
  );

  if (
    notifications?.count === 0 &&
    !type &&
    !status &&
    !search &&
    !at &&
    !before &&
    !after
  ) {
    return (
      <EmptyState
        title="No notifications yet"
        subtitle="Notifications will appear here once emails start getting scheduled and sent for this event."
      />
    );
  }

  return (
    <Table
      testid="cae-notifications-table"
      count={notifications?.count || 0}
      columnData={columnData}
      columns={columns}
      isFetching={isFetching}
      isLoading={isLoading}
      noResultsData={{
        title: "No notifications found",
        subtitle:
          "Sorry, there are no notifications that match your applied filters.",
      }}
      paginateProps={paginateProps}
      rows={rowData}
      searchBarProps={searchBarProps}
      tableMinWidth={1000}
      title={`${notifications?.count} Notifications`}
      titleVariant="subtitle2"
      toolbarInside
      toolbarCollapse={{
        Icon: FilterList,
        collapseContent: filterList,
      }}
    />
  );
}
