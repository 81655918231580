import { Tabs } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";

import { BackLink } from "src/components/atoms/BackLink";
import { ErrorState } from "src/components/atoms/ErrorState";
import { PageContainer } from "src/components/containers/PageContainer/PageContainer";
import { DetailPageHeader } from "src/components/molecules/DetailPageHeader";
import { LoadingHeader } from "src/components/molecules/Loading/LoadingHeader";
import { CORPORATE_ACTION_LABEL } from "src/constants/labels";
import { CORPORATE_ACTION_MAILING_DETAIL_TABS } from "src/constants/tabs";
import { useCorporateActionEventQuery } from "src/store/corporateAction";
import { maxDate, minDate } from "src/utils/compareDates";

export function CorporateActionDetail() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const {
    data: corporateActionEvent,
    isLoading,
    error,
  } = useCorporateActionEventQuery({ id });
  const match = useMatch(`/mailings/corporate-action/${id}/:activeTab`) as {
    params: { activeTab: string };
  };

  const handleTabChange = (value: string) => {
    navigate(`/mailings/corporate-action/${id}/${value}`);
  };

  if (error || (!isLoading && !corporateActionEvent)) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <BackLink
        sx={{ mb: 3, ml: -2, pt: 10, px: 10 }}
        label="Mailings"
        to="/mailings/corporate-action?view=approved"
      />
      {isLoading ? (
        <LoadingHeader sx={{ px: 10 }} numberOfRows={1} />
      ) : (
        <DetailPageHeader
          backLink={`/mailings/corporate-action/${id}/${
            match?.params?.activeTab || "customers"
          }`}
          type={CORPORATE_ACTION_LABEL[corporateActionEvent.type]}
          issuerId={corporateActionEvent.issuer.id}
          issuerName={corporateActionEvent.issuer.name || ""}
          isProxy={false}
          startDate={minDate(
            corporateActionEvent.stats.initialStartScheduledSendAt,
            corporateActionEvent.stats.initialWebhookStartScheduledSendAt
          )}
          endDate={maxDate(
            corporateActionEvent.stats.initialEndScheduledSendAt,
            corporateActionEvent.stats.initialWebhookEndScheduledSendAt
          )}
          sx={{ px: 10 }}
        />
      )}
      <Box sx={{ mx: 10, mt: 3 }}>
        <Tabs
          activeTab={match?.params?.activeTab || "customers"}
          clickHandler={handleTabChange}
          tabs={CORPORATE_ACTION_MAILING_DETAIL_TABS}
        />
      </Box>
      <Outlet />
    </PageContainer>
  );
}
