import { User } from "./types";
import { rootApi } from "../rootApi";

export const userApis = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    user: builder.query<User, void>({
      query: () => ({
        url: "user/",
      }),
      providesTags: ["user"],
    }),
  }),
});

export const { useUserQuery } = userApis;
