import { getAuthToken, LogIn, setAuthToken } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAttemptLoginMutation } from "src/store/sso";
import { ILoginParams } from "src/store/sso/types";

/**
 * Maps RTK Query status to string values used in Say Login Component
 */
const loginStatusMap = {
  uninitialized: "idle",
  pending: "loading",
  rejected: "rejected",
};

export function Login() {
  const loggedIn = !!getAuthToken();
  const navigate = useNavigate();
  const [login, loginResult] = useAttemptLoginMutation();
  const loginStatus = loginStatusMap[loginResult.status];

  useEffect(() => {
    document.title = "Sign In | Say Broker Portal";
  }, []);

  useEffect(() => {
    if (loginResult.isSuccess) {
      const { token, refreshToken } = loginResult.data;
      // store refreshToken in localStorage
      window.localStorage.setItem("refresh-token", refreshToken);
      setAuthToken({ value: token });
      // Placeholder for adding analytics here for the user.
      // console.log(user);
    }
  }, [loginResult]);

  const handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/forgot-password");
  };

  const handleLogin = async (data: ILoginParams) => {
    login(data);
  };

  if (loggedIn || loginResult.isSuccess) {
    return <Navigate to="/mailings/proxy" replace />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <LogIn
        bgColor="none"
        goToForgotPassword={handleForgotPassword}
        login={handleLogin}
        loginState={loginStatus}
        title="Welcome to Broker Portal"
      />
    </Box>
  );
}
