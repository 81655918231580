import {
  GlobalSearchRequestParams,
  SearchCustomers,
  SearchSecurities,
} from "./types";
import { rootApi } from "../rootApi";

export const globalSearchApis = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    searchSecurities: builder.query<
      SearchSecurities,
      GlobalSearchRequestParams
    >({
      query: ({ search }) => ({
        url: "global-search/securities/",
        params: {
          search,
        },
      }),
      providesTags: ["globalSearchSecurities"],
    }),
    searchCustomers: builder.query<SearchCustomers, GlobalSearchRequestParams>({
      query: ({ search }) => ({
        url: `global-search/customers/`,
        params: {
          search,
        },
      }),
      providesTags: ["globalSearchCustomers"],
    }),
  }),
});

export const { useSearchSecuritiesQuery, useSearchCustomersQuery } =
  globalSearchApis;
